const Constants = require('./aa-helper.constants');
const _this = {
	getUserAuthStatus: function () {
		return (authHelper.isFullyAuthenticated() ? 'loggedin' : authHelper.isPartiallyAuthenticated() ? 'partially authenticated' : 'guest');
	},
	translateATS: function (val) {
		return Constants.translateATSMap.get(val);
	},
	shippingOptions: function (val) {
		return Constants.shippingOptionsMap.get(val);
	},
	getChannelSc: function (sChannelCode) {
		if (typeof sChannelCode === 'string' && aem_utag_data.aemchannels && aem_utag_data.aemchannels[sChannelCode] && typeof aem_utag_data.aemchannels[sChannelCode].sc !== 'undefined' && aem_utag_data.aemchannels[sChannelCode].sc && aem_utag_data.aemchannels[sChannelCode].sc !== 'undefined' ) {
			return aem_utag_data.aemchannels[sChannelCode].sc;
		}
		return aem_utag_data.defaultChannelSC;
	},
	getTimezone: function () {
		var timezoneData = '';
		if (($('[data-timezone]') && $('[data-timezone]').data('timezone'))) {
			timezoneData = $('[data-timezone]').data('timezone').replace(/s/i, '');
		}
		return (timezoneData || i18n.getLocale()).toUpperCase();
	},
	createChannelSc: function (sChannelName) {
		if (typeof sChannelName === 'string') {
			return $.trim(sChannelName).replace(/^qvc$/i, aem_utag_data.defaultChannelSC).replace(/qvc\s|\s/ig, '').toUpperCase();
		}
		return aem_utag_data.defaultChannelSC;
	},
	setChannelShopCats: function () {
		// This function creates the aem_utag_data property 'aemchannels' that includes information to each channel. 
		// Structure: aem_utag_data.aemchannels = {<channelCode>: {name: <channelName>, sc: <channelShoppingCategory>},...}
		// The function can be removed once aem_utag_data contains the data directly.
		if (typeof aem_utag_data.aemchannels === 'undefined') {
			var sShopCat = '';
			aem_utag_data.aemchannels = {};
			if (typeof oLiveStreams === 'object') {
				$.each(oLiveStreams, function (k) {
					if (oLiveStreams[k].name) {
						sShopCat = _this.createChannelSc(oLiveStreams[k].name);
						aem_utag_data.aemchannels[k] = { name: oLiveStreams[k].name, sc: sShopCat };
						oLiveStreams[k].sc = sShopCat;
					}
				});
			} else {
				$('[data-channelcode], [data-channel-code]').each(function (index, element) {
					if (index === undefined && element === undefined) {
						// trhowing a silly error so both index and element are used in this scope and I don't get the "no unsused variables" error from eslint
						throw new Error('Expected either "index" or "element" to be defined');
					}
					const $element = $(element);
					aem_utag_data.aemchannels[$element.data('channelcode') || $element.data('channel-code')] = { name: $.trim($element.text()), sc: _this.createChannelSc($element.text()) };
				});
			}
		}
	},
	captureEventData: function (ceData) {
		let aaDataLayerObject = {
			eventInfo: '',
			event: '',
			ecommerce: {
				product: [],
				txn: {}
			}
		};
		if (ceData) {
			_this.getEventData(ceData, aaDataLayerObject);
		}
		if (typeof aem_utag_data.eventInfo === 'undefined' && ceData.paramObj.name !== 'modifyCartItem') {
			aaDataLayerObject.utils = _this.getUtilsData();
			window.adobeDataLayer.push(aaDataLayerObject);
		}
	},
	captureCouponData: function (ceData) {
		let event = '',
			linkName = '',
			linkCategory = '',
			linkSection = '';
		if (ceData && ceData.paramObj && ceData.paramObj.name) {
			let eventName = ceData.paramObj.name;
			if (_this.additionalCostsEnabled() && eventName === 'invalid code') {
				eventName = 'invalid_code';
			}
			switch (eventName) {
			case 'addVoucher':
				event = 'couponCodeSuccess';
				linkCategory = 'promo code applied';
				linkSection = 'promo code success';
				if (ceData.paramObj.json && ceData.paramObj.json.code) {
					linkName = ceData.paramObj.json.code;
				} else if (ceData.data && ceData.data.name) {
					linkName = ceData.data.name;
				}
				break;
			case 'deleteVoucher':
				event = 'couponCodeRemoved';
				linkCategory = 'promo code removal';
				linkSection = 'promo code removed';
				if (ceData.paramObj.svc && ceData.paramObj.svc.includes('/vouchers/')) {
					let vouchers = ceData.paramObj.svc.split('/vouchers/');
					linkName = vouchers && vouchers.length > 1 ? vouchers[1] : '';
				}
				break;
			case 'invalid_code':
				event = 'couponCodefailure';
				linkCategory = 'promo code applied';
				linkSection = 'promo code failed';
				if (ceData.paramObj.couponCode) {
					linkName = ceData.paramObj.couponCode;
				} else if (_this.additionalCostsEnabled()) {
					linkName = $('input[name="promoCode"]').length ? $('input[name="promoCode"]').val() : '';
				}
				break;
			}
			window.adobeDataLayer.push({
				event,
				links: {
					linkName,
					linkCategory,
					linkSection
				}
			});
		}
	},
	getUtilsData: function () {
		return { orientation: _this.getOrientation(), breakPoint: _this.getBreakpoint() };
	},
	getOrientation: function () {
		return window.innerHeight > window.innerWidth ? 'portrait' : 'landscape';
	},
	getBreakpoint: function () {
		return (qViewport.breakpoint[qViewport.getBreakpoint()].min || 320).toString();
	},
	calculateCombos: function () {
		let combinationRatios = '';
		if (typeof product !== 'undefined') {
			if (product.hasColors) {
				combinationRatios = _this.productPageCountCombos(product.atsByColor);
			} else if (product.hasSizes) {
				combinationRatios = _this.productPageCountCombos(product.atsBySize);
			} else {
				combinationRatios = _this.productPageCountCombos(product.ats);
			}
		}
		return combinationRatios;
	},
	getDeliveryOption: function () {
		let deliveryOption = '';
		if (aem_utag_data.page_type === 'product') {
			if ($('.adPurchase').is('[state=selected]')) {
				deliveryOption = 'auto purchase';
			} else {
				deliveryOption = 'single purchase';
			}
		}
		return deliveryOption;
	},
	additionalCostsEnabled: function() {
		return (typeof qVars.feature.adobeAnalyticsAdditionalCosts !== 'undefined' && qVars.feature.adobeAnalyticsAdditionalCosts);
	},
	getLinkText: function ($el) {
		return qString.condenseForTag($el.data('link-tag') ? $el.data('link-tag') : $el.text());
	},
	getProdNum: function(href){
		let prodNum = href.match(new RegExp(/[/.]product\.(.*)\.html/));
		if (prodNum !== null && prodNum.length > 1) {
			prodNum = prodNum[1];
		}
		return prodNum;
	},
	setProductData: function (itemList, list) {
		const strValue = new URLSearchParams(window.location.search).get('sc') || undefined,
			scQueryParam = _this.sanitizeQueryParamValue(strValue),
			taggingMetaData = itemList['taggingMetaData'],
			itemListSc = taggingMetaData ? taggingMetaData['shoppingCategory'] : 'NSCP',
			shoppingCategory = itemListSc || scQueryParam;
		if (typeof itemList !== 'undefined') {
			let prodID = itemList.sku,
				productID = prodID.includes(' ') ? prodID.split(' ')[0] : prodID,
				brandName = '',
				rating = aem_utag_data.average_rating || '',
				reviewCount = aem_utag_data.number_of_reviews || '',
				minPrice = '',
				maxPrice = '',
				sizeGuideOffered = $('.buyBoxSelectSize .selectLink') && $('.buyBoxSelectSize .selectLink').length > 0 ? 'y' : 'n',
				onAirItem = _this.productPageGetIOA(),
				isExtendedWarranty = aem_utag_data.extended_warranty_offered ? aem_utag_data.extended_warranty_offered.toLowerCase() : 'n',
				referringProductID = aem_utag_data.referring_item || '',
				combinationRatios = _this.calculateCombos(),
				shippingOption = '',
				shippingOptionsCode = '',
				name = itemList.name ? itemList.name.toLowerCase() : '',
				brandID = itemList.brandId || '',
				availability = itemList.stockPhase ? itemList.stockPhase.toLowerCase() : '',
				creditTermCode = itemList.creditTerms && itemList.creditTerms.code && itemList.creditTerms.code !== 'FP' ? itemList.creditTerms.code.toLowerCase() : '',
				specialPriceCode = itemList.priceCode ? itemList.priceCode.toLowerCase() : '',
				quantity = itemList.quantity ? String(itemList.quantity) : '0',
				basePrice = itemList.price ? _this.aaNumFormat(itemList.price) : '0',
				shippingHandlingCost = '0',
				promoDisc = '0',
				tax = '0',
				totalProdPrice = '0',
				variantID = prodID,
				colorCode = prodID.includes(' ') ? prodID.split(' ')[1] : prodID,
				sizeID = prodID.includes(' ') ? prodID.split(' ')[2] : prodID,
				deliveryDate = '',
				easyPaymentNo = itemList.creditTerms && itemList.creditTerms.numberOfInstallments ? itemList.creditTerms.numberOfInstallments.toString() : '',
				categoryID = aem_utag_data.product_category_id && aem_utag_data.product_category_id.length && aem_utag_data.product_category_id[0] ? aem_utag_data.product_category_id[0] : '',
				deliveryOption = '',
				revenue = Number(basePrice) && Number(quantity) ? _this.aaNumFormat(Number(basePrice) * Number(quantity)) : '0';

			if (itemList.estimatedDeliveryDate) {
				let deliveryDateTime = new Date(itemList.estimatedDeliveryDate),
					date = deliveryDateTime.getDate(),
					month = (deliveryDateTime.getMonth() + 1),
					year = deliveryDateTime.getFullYear();
				deliveryDate =  ('by ' + date + '/' + month + '/' + year);
			} else if (itemList.estimatedDeliveryDateDesc) {
				deliveryDate = itemList.estimatedDeliveryDateDesc;
			}
			if (itemList.costs && itemList.costs.length) {
				itemList.costs.forEach(cost => {
					if (cost && cost.name === 'shipping') {
						shippingHandlingCost = _this.aaNumFormat(cost.amount);
					}
					if (cost && cost.name === 'tax') {
						tax = _this.aaNumFormat(cost.amount);
					}
				});
			}
			if (_this.additionalCostsEnabled()) {
				tax = itemList.taxValue && Number(itemList.taxValue) && Number(quantity) ? _this.aaNumFormat(Number(itemList.taxValue) * Number(quantity)) : '0';
				if (Array.isArray(aem_utag_data.product_id) && aem_utag_data.product_id.includes(prodID)) {
					let idx = aem_utag_data.product_id.indexOf(prodID);
					creditTermCode = typeof aem_utag_data.product_credit_terms_code[idx] !== 'undefined' && aem_utag_data.product_credit_terms_code[idx] ? aem_utag_data.product_credit_terms_code[idx] : creditTermCode;
				}
			}
			if (itemList.discounts && itemList.discounts.length) {
				promoDisc = _this.getDiscounts(itemList.discounts);
			}
			if (itemList.shippingOptionCode) {
				shippingOptionsCode = itemList.shippingOptionCode;
				shippingOption = _this.shippingOptions(itemList.shippingOptionCode);
			} else if (itemList.shippingOptionDescription) {
				shippingOption = itemList.shippingOptionDescription.toLowerCase();
			}
			if (typeof list !== 'undefined' && list.shippingName === 'AutoDelivery') {
				deliveryOption = 'auto delivery';
			} else {
				deliveryOption = _this.getDeliveryOption();
			}
			totalProdPrice = ((((Number(basePrice) * Number(quantity)) + Number(shippingHandlingCost)) - Number(promoDisc)) + Number(tax)).toString();
			if (typeof itemList.total !== 'undefined') {
				totalProdPrice = itemList.total;
			}
			return {
				productID,
				name,
				brandID,
				brandName,
				availability,
				rating,
				reviewCount,
				creditTermCode,
				sizeGuideOffered,
				onAirItem,
				specialPriceCode,
				isExtendedWarranty,
				referringProductID,
				minPrice: _this.aaNumFormat(minPrice),
				maxPrice: _this.aaNumFormat(maxPrice),
				shippingHandlingCost: _this.aaNumFormat(shippingHandlingCost),
				basePrice: _this.aaNumFormat(basePrice),
				totalProdPrice: _this.aaNumFormat(totalProdPrice),
				tax: _this.aaNumFormat(tax),
				promoDisc: _this.aaNumFormat(promoDisc),
				quantity,
				colorCode,
				sizeID,
				variantID,
				combinationRatios,
				prodID,
				deliveryDate,
				shippingOptionsCode,
				shippingOption,
				easyPaymentNo,
				deliveryOption,
				categoryID,
				revenue,
				shoppingCategory
			};
		}
	},
	/**
	 * Sanitizes the input query parameter value by ensuring it contains only word characters (alphanumeric and underscores).
	 *
	 * @param {string} strValue - The input string representing a query parameter value.
	 * @returns {string} - The sanitized query parameter value if it contains only word characters; otherwise, an empty string.
	 *
	 */
	sanitizeQueryParamValue: function (strValue) {
		const wordCharacterRegex = /^\w+$/;
		if (!strValue) {
			return '';
		}
		return wordCharacterRegex.test(strValue) ? strValue : 'NSCP';
	},
	setProdTxnData: function () {
		let prodTxnData = _this.cartPageGetCartProdTxnData();
		adobeDataLayerObject.ecommerce.product = prodTxnData.productData;
		adobeDataLayerObject.ecommerce.txn = prodTxnData.cartTxn;
	},
	getEventData: function (epData, aaDataLayerObject) {
		let prodTxnData = {
			productData: [],
			cartTxn: {
				cartID: '',
				cartType: '',
				checkoutPath: '',
				totalItemCount: '',
				subTotal: '',
				tax: '',
				total: '',
				paymentType: '',
				orderID: '',
				couponDiscountAmount: '',
				couponCode: ''
			}
		};
		switch (epData.paramObj.name) {
		case 'cartCreate':
			aem_utag_data.eventInfo = 'newCartAddition';
			break;
		case 'cartAddItem':
			if (aem_utag_data.eventInfo) {
				aaDataLayerObject.eventInfo = aem_utag_data.eventInfo;
				delete aem_utag_data.eventInfo;
			} else {
				aaDataLayerObject.eventInfo = 'existingCartAddition';
			}
			aaDataLayerObject.event = 'addToCart';
			if (epData.data.items && epData.data.items.length) {
				if (_this.additionalCostsEnabled()) {
					aaDataLayerObject.ecommerce.product.push( _this.setProductData(epData.data.items[epData.data.items.length - 1]));
				} else {
					aaDataLayerObject.ecommerce.product.push( _this.setProductData(epData.data.items[0]));
				}
			}
			break;
		case 'speedbuyPdp':
			if (aem_utag_data.eventInfo) {
				aaDataLayerObject.eventInfo = aem_utag_data.eventInfo;
				delete aem_utag_data.eventInfo;
			} else {
				aaDataLayerObject.eventInfo = 'existingCartAddition';
			}
			aaDataLayerObject.event = 'speedBuy';
			prodID = epData.paramObj.json.cartItem.sku;
			prodQty = epData.paramObj.json.cartItem.quantity;
			aaDataLayerObject.ecommerce.product = _this.productPageGetProductData({ prodID, prodQty });
			break;
		case 'wishAdd':
			aaDataLayerObject.event = 'addToWishlist';
			prodID = epData.data.id;
			if (_this.additionalCostsEnabled()) {
				prodID = epData.data.data.productNumber;
			}
			prodQty = (epData.data.quantity).toString();
			aaDataLayerObject.ecommerce.product = _this.productPageGetProductData({ prodID, prodQty });
			if (!aaDataLayerObject.ecommerce.product.length) {
				aaDataLayerObject.ecommerce.product = _this.getWishProdData({ prodID, prodQty, epData });
			}
			break;
		case 'modifyCartItem':
			aaDataLayerObject.event = 'quantityChange';
			prodID = epData.paramObj.json.sku;
			prodQty = epData.paramObj.json.quantity;
			prodTxnData = _this.cartPageGetCartProdTxnData();
			aaDataLayerObject.ecommerce.product = prodTxnData.productData.filter(prodData => (prodData.prodID === prodID));
			aaDataLayerObject.ecommerce.txn = prodTxnData.cartTxn;
			if (typeof aem_utag_data.eventInfo === 'undefined') {
				aaDataLayerObject.utils = _this.getUtilsData();
				window.adobeDataLayer.push(aaDataLayerObject);
			}
			break;
		case 'cartDeleteItem': {
			if (epData.paramObj.oOptionalParams.purpose === 'deleteCartItem') {
				aaDataLayerObject.event = 'prodRemove';
			} else if (epData.paramObj.oOptionalParams.purpose === 'saveForLater') {
				aaDataLayerObject.event = 'saveForLater';
			}
			let prodSKU = epData.paramObj.svc;
			if (typeof prodSKU !== 'undefined' && prodSKU.includes('/items/') &&  prodSKU.split('/items/')[1].includes('?') && prodSKU.split('/items/')[1].split('?')[0].includes('%20')) {
				prodSKU = prodSKU.split('/items/')[1].split('?')[0].split('%20').join(' ');
			}
			prodTxnData = _this.cartPageGetCartProdTxnData();
			aaDataLayerObject.ecommerce.product = prodTxnData.productData.filter(prodData => (prodData.prodID === prodSKU));
			aaDataLayerObject.ecommerce.txn.cartID = prodTxnData.cartTxn.cartID;
			aaDataLayerObject.ecommerce.txn.cartType = prodTxnData.cartTxn.cartType;
		}
			break;
		case 'cartMerge':
			if (epData.paramObj.oOptionalParams.sContext === 'payPal') {
				aaDataLayerObject.event = 'paypalCheckout';
			} else {
				aaDataLayerObject.event = 'checkout';
			}
			prodTxnData = _this.cartPageGetCartProdTxnData();
			aaDataLayerObject.ecommerce.product = prodTxnData.productData;
			aaDataLayerObject.ecommerce.txn = prodTxnData.cartTxn;
			break;
		case 'payPalExpress':
			aaDataLayerObject.event = 'paypalCheckout';
			prodTxnData = _this.cartPageGetCartProdTxnData();
			aaDataLayerObject.ecommerce.product = prodTxnData.productData;
			aaDataLayerObject.ecommerce.txn = prodTxnData.cartTxn;
			aaDataLayerObject.ecommerce.txn.paymentType = 'paypal';
			break;
		case 'cancelItem': {
			aaDataLayerObject.event = 'cancelItem';
			let orderID = productID = '';
			if (epData.paramObj.svc.includes('orders/')) {
				orderID = epData.paramObj.svc.split('orders/')[1];
				orderID = orderID.includes('/') ? orderID.split('/')[0] : orderID;
			}
			if (typeof epData.paramObj.extra.obj[0].innerText !== 'undefined') {
				productID = epData.paramObj.extra.obj[0].innerText;
				productID = productID.includes('\n') ? productID.split('\n')[1] : productID;
				productID = productID.includes(' ') ? productID.split(' ')[0] : productID;
			}
			aaDataLayerObject.ecommerce.txn.orderID = orderID;
			aaDataLayerObject.ecommerce.product = [{ productID: productID }];
		}
			break;
		case 'cancelOrder':
			aaDataLayerObject.event = 'cancelOrder';
			if (epData.paramObj.svc.includes('orders/')) {
				aaDataLayerObject.ecommerce.txn.orderID = epData.paramObj.svc.split('orders/')[1];
			}
			break;
		case 'wishDelete':
			aaDataLayerObject.event = 'removeWishlist';
			if (epData.paramObj.extra && epData.paramObj.extra.eid) {
				let variantID = epData.paramObj.extra.eid,
					productID = variantID.includes(' ') ? variantID.split(' ')[0] : variantID;
				aaDataLayerObject.ecommerce.product = [{ productID, variantID }];
			}
			break;
		case 'addVoucher':
			aaDataLayerObject.event = 'couponCodeSuccess';
			break;
		}
	},
	getDiscounts: function (discounts = []) {
		let discount = 0;
		discounts.forEach(disc => {
			if (disc && disc.name === 'SHIPPING' && disc.amount) {
				discount += Number(disc.amount);
			}
		});
		return _this.aaNumFormat(discount);
	},
	getWishProdData: function (wishData) {
		let rProdID = productData = [],
			shippingHandlingCost = '',
			shippingOption = '',
			availability = _this.translateATS(wishData.epData.data.data.items[0].ats),
			basePrice = wishData.epData.data.data.items[0].itemPricing.currentSellingPrice,
			totalProdPrice =  _this.aaNumFormat((Number(basePrice) * Number(wishData.prodQty)) + Number(shippingHandlingCost));
		if (wishData.epData.data.data.relatedProducts) {
			wishData.epData.data.data.relatedProducts.forEach(rProd => {
				if (rProd && rProd.productNumber) {
					rProdID.push(rProd.productNumber);
				}
			});
		}
		if (wishData.epData.data.data.shippingAndHandling) {
			shippingHandlingCost =  _this.aaNumFormat(Number(wishData.epData.data.data.shippingAndHandling.shippingHandlingCharge) * Number(wishData.prodQty));
		}
		productData.push({
			productID: wishData.prodID.includes(' ') ? wishData.prodID.split(' ')[0] : wishData.prodID,
			name: wishData.epData.data.data.shortDescription,
			brandID: wishData.epData.data.data.brandId,
			availability,
			rating: wishData.epData.data.data.reviews.averageRating,
			reviewCount: wishData.epData.data.data.reviews.count,
			referringProductID: rProdID.join(','),
			shippingHandlingCost,
			shippingOption,
			basePrice,
			totalProdPrice: _this.aaNumFormat(totalProdPrice),
			promoDisc: '0',
			quantity: wishData.prodQty,
			colorCode: wishData.prodID.includes(' ') ? wishData.prodID.split(' ')[1] : '',
			sizeID: wishData.prodID.includes(' ') ? wishData.prodID.split(' ')[2] : '',
			variantID: wishData.prodID,
			prodID: wishData.prodID,
		});
		return productData;
	},
	aaNumFormat: function (num) {
		return !num || Number.isNaN(Number(num)) ? '' : Number(num).toFixed(2).toString();
	},
	generalClickHandler: function (ele) {
		let $el = $(ele),
			$elParent = $el.parent(),
			linkCategory = 'IROA_FILTERS',
			linkSection = '',
			linkName = '',
			iroaSourceCode = '',
			programName = '';

		if ($el.closest('.iroa-module .iroaChannels select').length) {
			linkCategory = 'IROA';
			linkSection = 'CHANNEL_SELECTOR';
			linkName = $el.closest('[data-channelname]').length ? ($el.closest('[data-channelname]').data('channelname')) : '';
			channelCode = $el.closest('[data-channelcode]').length ? ($el.closest('[data-channelcode]').data('channelcode')) : '';
			if (!linkName && channelCode && typeof aem_utag_data.aemchannels !== 'undefined' && typeof aem_utag_data.aemchannels[channelCode] !== 'undefined') {
				linkName = aem_utag_data.aemchannels[channelCode].name ? aem_utag_data.aemchannels[channelCode].name : '';
			}
		}
		if ($el.closest('.iroaDates li:not(.selected)').length || $el.closest('.iroaMobileMenu select').length) {
			linkSection = 'DATE_SELECTOR';
			let daysIndex = $el.prop('nodeName').toLowerCase() === 'select' ? $el.find(':selected').index() : $el.index();
			if ($el.prop('nodeName').toLowerCase() === 'a') {
				daysIndex = $elParent.index();
			}
			if (daysIndex > 1){
				linkName = daysIndex + '_DAYS_AGO';
			} else {
				linkName = daysIndex ? 'YESTERDAY' : 'TODAY';
			}
		}
		if ($el.parents('.iroaShows').find('li').hasClass('selected')) {
			linkSection = 'SHOW_SELECTOR';
			linkName = $el.closest('[data-source]').length ? String($el.closest('[data-source]').data('source')) : '';
			iroaSourceCode = $el.closest('[data-source]').length ? String($el.closest('[data-source]').data('source')) : '';
			programName = $el.closest('[data-source]').length && $el.closest('[data-source]').find('span.title').length ? $el.closest('[data-source]').find('span.title').text() : '';
		}
		if ($el.closest('.iroaAddNavComp').length) {
			linkSection =  $el.closest('[data-component-type]').length ? $el.closest('[data-component-type]').attr('data-component-type') : '';
			linkName = $el.closest('[data-feature-name]').length ? $el.closest('[data-feature-name]').attr('data-feature-name') : '';
		}
		if ($el.parents('.iroaTimeZoneSelect').length) {
			linkSection = 'SHOW_SELECTOR';
			let timezoneData = $el.closest('[data-timezoneshort]').length ? $el.closest('[data-timezoneshort]').attr('data-timezoneshort') : '';
			linkName = 'TIMEZONE' + timezoneData.replace('S','');
		}
		return { linkCategory, linkSection, linkName, iroaSourceCode, programName };
	},
	getProductIDs: function () {
		let productIDS = [], prodType = 'prodId', productList = $('[data-component-type="PRODUCT_LISTING_LIST"] [data-prod-id]');
		if (_this.additionalCostsEnabled()) {
			productList = $('[data-component-type="PRODUCT_LISTING_LIST"] [data-scala-item]');
			prodType = 'scalaItem';
		}
		if (productList.length > 0) {
			for (let productIndex = 0; productIndex < productList.length; productIndex++) {
				if ($(productList[productIndex]).data(prodType)) {
					productIDS.push($(productList[productIndex]).data(prodType));
				}
			}
		}
		return productIDS;
	},
	setAnonymizedUserID: function () {
		sessionStorage.setItem('aaAnonymizedUserID', Math.random().toString(16).substring(2, 12));
	},
	getAnonymizedUserID : function () {
		return (sessionStorage.getItem('aaAnonymizedUserID'));
	},
	/**
	 * Normalizes a module type name by converting it to lowercase,
	 * replacing underscores with spaces, and trimming whitespace.
	 *
	 * @param {string} nameValue - The module type name to normalize.
	 * @returns {string} The normalized module type name.
	 * @throws {Error} Throws an error if the `nameValue` is not a string.
	 */
	normalizeModuleTypeName: (nameValue) => {
		if (typeof nameValue !== 'string') {
			throw new Error(`Can't normalize a module type name that is not a string`);
		}
		return nameValue.toLowerCase().replace('_', ' ').trim();
	},
	/**
	 * Checks if a jQuery element or any of its parent elements has a `data-module-type` attribute
	 * with a value that is considered a "super module".
	 *
	 * @param {jQuery} $element - The jQuery element to be checked.
	 * @returns {boolean} - Returns `true` if any element in the hierarchy is marked as a "super module", otherwise `false`.
	 */
	hasSuperModule: ($element) => {
		const $currentElement = $element.addBack().add($element.parents());
		for (let i = 0; i < $currentElement.length; i++) {
			let dataModuleType = $($currentElement[i]).data('module-type');
			if (dataModuleType && _this.isSuperModuleType(dataModuleType)) {
				return true;
			}
		}
		return false;
	},
	/**
	 * Checks if a given module type name is considered a "super module" based on a predefined lookup.
	 *
	 * This function takes a module type name, normalises it by converting it to lowercase,
	 * replacing underscores with spaces, and trimming whitespace. It then checks if the
	 * normalised name matches any of the values in the `superModuleLookup` array, also normalised in the same manner.
	 *
	 * @param {string} moduleTypeName - The name of the module type to be checked.
	 * @returns {boolean} - Returns `true` if the module type is a "super module", otherwise `false`.
	 */
	isSuperModuleType: (moduleTypeName) => {
		const superModuleLookup = [
				'PRODUCT_LISTING'
			].map(item => _this.normalizeModuleTypeName(item)),
			normalizedValue = _this.normalizeModuleTypeName(moduleTypeName);
		for (let i = 0; i < superModuleLookup.length; i++) {
			if (superModuleLookup[i] === normalizedValue) {
				return true;
			}
		}
		return false;
	},
	aaGDPR: (c) => {
		if (qVars.optOut) {
			return !gdprCheck('q') || gdprCheck(c);
		}
		return gdprCheck(c);
	},
	addMemberNumber: function() {
		const cookieValue = document.cookie.match(/(^|;)\s*qvcmn=([^;]+)/);
		if (cookieValue) {
			const mValue = decodeURIComponent(cookieValue[2]);
			if (window.adobeDataLayer) {
				window.adobeDataLayer.push({
					user: {
						memberNumber: mValue
					}
				});
			}
		}
	},
	productPageCountCombos: function (obj) {
		let combos = [0, 0, 0, 0];
		if (typeof obj === 'object') {
			for (let i in obj) {
				for (let j in obj[i].items) {
					switch (obj[i].items[j].ats) {
					case 'Y': combos[0]++; break;
					case 'W': combos[1]++; break;
					case 'A': combos[2]++; break;
					case 'N': combos[3]++; break;
					}
				}
			}
		} else if (typeof obj === 'string') {
			switch (obj) {
			case 'Y': combos[0]++; break;
			case 'W': combos[1]++; break;
			case 'A': combos[2]++; break;
			case 'N': combos[3]++; break;
			}
		}
		return combos.join('|');
	},
	productPageGetIOA: function () {
		if (typeof aem_utag_data.product_on_air !== 'undefined') {
			return String(aem_utag_data.product_on_air).toLowerCase();
		} else if (urlManager.getParam('sc') === 'IOA' || urlManager.getParam('UDC') === 'ONAIR') {
			return 'y';
		}
		return 'n';
	},
	productPageGetProductData: function (resData) {
		let productData = [],
			productIDs = [];
		if (aem_utag_data.product_id && aem_utag_data.product_id.length && Array.isArray(aem_utag_data.product_id)) {
			productIDs = aem_utag_data.product_id;
		} else if (typeof resData !== 'undefined' && resData.prodID) {
			productIDs.push(resData.prodID);
		}
		if (productIDs && productIDs.length) {
			productIDs.forEach((pID, index) => {
				if (pID) {
					let prodID = pID,
						productID = prodID.includes(' ') ? prodID.split(' ')[0] : prodID,
						name = aem_utag_data.product_name && aem_utag_data.product_name.length && aem_utag_data.product_name[index] ? aem_utag_data.product_name[index].toLowerCase() : '',
						brandID = aem_utag_data.brand_id && aem_utag_data.brand_id.length && aem_utag_data.brand_id[index] ? aem_utag_data.brand_id[index] : '',
						brandName = aem_utag_data.brand_name && aem_utag_data.brand_name.length && aem_utag_data.brand_name[index] ? aem_utag_data.brand_name[index].toLowerCase() : '',
						availability = typeof product !== 'undefined' && product.ats ? _this.translateATS(product.ats) : '',
						rating = aem_utag_data.average_rating || '',
						reviewCount = aem_utag_data.number_of_reviews || '',
						creditTermCode = aem_utag_data.credit_terms_code ? aem_utag_data.credit_terms_code.toLowerCase() : '',
						sizeGuideOffered = $('.buyBoxSelectSize .selectLink') && $('.buyBoxSelectSize .selectLink').length > 0 ? 'y' : 'n',
						onAirItem = _this.productPageGetIOA(),
						specialPriceCode = aem_utag_data.special_price_code ? aem_utag_data.special_price_code.toLowerCase() : '',
						isExtendedWarranty = aem_utag_data.extended_warranty_offered ? aem_utag_data.extended_warranty_offered.toLowerCase() : 'n',
						referringProductID = aem_utag_data.referring_item || '',
						colorCode = aem_utag_data.product_color && aem_utag_data.product_color.length && aem_utag_data.product_color[index] ? aem_utag_data.product_color[index] : '',
						sizeID = aem_utag_data.product_size && aem_utag_data.product_size.length && aem_utag_data.product_size[index] ? aem_utag_data.product_size[index] : '',
						quantity = aem_utag_data.product_quantity && aem_utag_data.product_quantity.length && aem_utag_data.product_quantity[index] ? aem_utag_data.product_quantity[index] : '1',
						variantID = prodID,
						productPrice = aem_utag_data.product_price ?  _this.aaNumFormat(aem_utag_data.product_price) : '0',
						shippingHandlingCost = '0',
						maxPrice = '',
						minPrice = '',
						promoDisc = '0',
						basePrice = '0',
						totalProdPrice = '0',
						combinationRatios = '',
						deliveryDate = '',
						shippingOption = '',
						easyPaymentNo = '',
						categoryID = aem_utag_data.product_category_id && aem_utag_data.product_category_id.length && aem_utag_data.product_category_id[index] ? aem_utag_data.product_category_id[index] : '',
						deliveryOption = '',
						findingMethod = '',
						revenue = '0',
						anwendung = aem_utag_data.anwendung || '',
						bekleidungsart = aem_utag_data.bekleidungsart || '',
						schmuckart = aem_utag_data.schmuckart || '',
						shape = aem_utag_data.shape || '',
						productCategoryName = aem_utag_data.product_category_name || '',
						productType = aem_utag_data.product_type || '',
						defaultShippingMethodCode = aem_utag_data.default_shipping_method_code || '';
					if (aem_utag_data.product_max_price) {
						maxPrice =  _this.aaNumFormat(aem_utag_data.product_max_price);
					}
					if (maxPrice && typeof product !== 'undefined' && (product.hasColors || product.hasSizes) && (Number(maxPrice) > Number(productPrice))) {
						minPrice = productPrice;
						if (_this.additionalCostsEnabled() && !(product.hasPriceRange)) {
							maxPrice = '';
							minPrice = '';
						}
					}

					if (typeof resData !== 'undefined') {
						prodID = resData.prodID;
						quantity = (resData.prodQty).toString();
						colorCode = prodID.includes(' ') ? prodID.split(' ')[1] : '';
						sizeID = prodID.includes(' ') ? prodID.split(' ')[2] : '';
						let selectedProd = _this.findSelectedComboProd({ colorCode, sizeID });
						productPrice = selectedProd.basePrice ?  _this.aaNumFormat(selectedProd.basePrice) : '';
						availability = selectedProd.ats ? _this.translateATS(selectedProd.ats) : '';
					}
					if (aem_utag_data.product_sh_price) {
						shippingHandlingCost =  _this.aaNumFormat(Number(aem_utag_data.product_sh_price) * Number(quantity));
					}
					combinationRatios = _this.calculateCombos();
					totalProdPrice = _this.aaNumFormat((Number(productPrice) * Number(quantity)) + Number(shippingHandlingCost));
					basePrice = _this.aaNumFormat(productPrice);
					if (_this.additionalCostsEnabled() && typeof aem_utag_data.base_price_exclude_tax !== 'undefined') {
						basePrice = aem_utag_data.base_price_exclude_tax;
					}
					if (typeof resData === 'undefined' && maxPrice && minPrice && (Number(maxPrice) > Number(minPrice))) {
						basePrice = '';
						totalProdPrice = '';
					} else {
						maxPrice = '';
						minPrice = '';
					}
					deliveryOption = _this.getDeliveryOption();
					//Modify values
					if (creditTermCode === 'fp') {
						creditTermCode = '';
					}
					if (aem_utag_data.shopping_category && aem_utag_data.shopping_category === 'IOA') {
						onAirItem = 'y';
					}
					basePrice = Number(basePrice) ? basePrice : '';
					promoDisc = Number(promoDisc) ? promoDisc : '';
					shippingHandlingCost = Number(shippingHandlingCost) ? shippingHandlingCost : '';
					findingMethod = _this.productPageGetProductFindingMethod();
					revenue = Number(basePrice) && Number(quantity) ? _this.aaNumFormat(Number(basePrice) * Number(quantity)) : '0' ;
					productData.push({
						productID,
						name,
						brandID,
						brandName,
						availability,
						rating,
						reviewCount,
						creditTermCode,
						sizeGuideOffered,
						onAirItem,
						specialPriceCode,
						isExtendedWarranty,
						referringProductID,
						minPrice,
						maxPrice,
						shippingHandlingCost,
						basePrice,
						totalProdPrice,
						promoDisc,
						quantity,
						colorCode,
						sizeID,
						variantID,
						combinationRatios,
						prodID,
						deliveryDate,
						shippingOption,
						easyPaymentNo,
						deliveryOption,
						categoryID,
						findingMethod,
						revenue,
						anwendung,
						bekleidungsart,
						schmuckart,
						shape,
						productCategoryName,
						productType,
						defaultShippingMethodCode
					});
				}
			});
		}
		return productData;
	},
	cartPageGetCartProdTxnData: function () {
		let productData = [];
		let cartTxn = {
			cartID: '',
			cartType: '',
			checkoutPath: '',
			totalItemCount: '',
			subTotal: '',
			tax: '',
			total: '',
			paymentType: '',
			orderID: '',
			couponDiscountAmount: '',
			couponCode: '',
			shippingHandlingCost: ''
		};
		if (typeof cartVars !== 'undefined' && cartVars.cart) {
			if (cartVars.cart.shippingList && cartVars.cart.shippingList.length) {
				cartVars.cart.shippingList.forEach(list => {
					if (list && list.shippingItemList && list.shippingItemList.length) {
						list.shippingItemList.forEach(itemList => {
							if (itemList) {
								productData.push(_this.setProductData(itemList, list));
							}
						});
					}
				});
			}
			cartTxn.cartID = cartVars.cart.cartId || qCookie.get(qCookie.name.cartId) || '';
			cartTxn.cartType = JSON.parse(localStorage.getItem('cartStorage')) && JSON.parse(localStorage.getItem('cartStorage')).cartId === cartTxn.cartID ? 'existing' : 'new';
			cartTxn.subTotal =  _this.aaNumFormat(cartVars.cart.subTotal);
			cartTxn.total =  _this.aaNumFormat(cartVars.cart.cartTotal);
			//Captures the tax amount.
			if (cartVars.cart.cartCosts && Array.isArray(cartVars.cart.cartCosts) && cartVars.cart.cartCosts.length) {
				cartVars.cart.cartCosts.forEach(costs => {
					if (costs && costs.name === 'tax') {
						cartTxn.tax =  _this.aaNumFormat(costs.amount);
					}
					if (costs && costs.name === 'shipping') {
						cartTxn.shippingHandlingCost =  _this.aaNumFormat(costs.amount);
					}
				});
			}
			//Captures the payment method used by customer to place.
			if (cartVars.cart.paymentMethods && Array.isArray(cartVars.cart.paymentMethods) && cartVars.cart.paymentMethods.length) {
				let payments = [];
				cartVars.cart.paymentMethods.forEach(payment => {
					if (payment && payment.paymentMethodType) {
						let paymentType = payment.paymentMethodType.toLowerCase();
						if (paymentType === 'credit') {
							paymentType = 'gift card - qvc gift card' + ' : ' +  _this.aaNumFormat(payment.data.amount);
						} else if (paymentType === 'creditcard') {
							paymentType = 'credit card';
						}
						payments.push(paymentType);
					}
				});
				cartTxn.paymentType = payments.join(' | ');
			}
			cartTxn.totalItemCount = cartVars.size || '';
			if (cartVars.cart.cartVouchers && Array.isArray(cartVars.cart.cartVouchers) && cartVars.cart.cartVouchers.length) {
				let vouchers = {
					vouchersCodes: [],
					vouchersAmounts: []
				};
				cartVars.cart.cartVouchers.forEach(voucher => {
					if (voucher && voucher.name && voucher.amount) {
						vouchers.vouchersCodes.push(voucher.name);
						vouchers.vouchersAmounts.push(voucher.amount);
					}
				});
				cartTxn.couponCode = vouchers.vouchersCodes.join('|');
				cartTxn.couponDiscountAmount = vouchers.vouchersAmounts.join('|');
			}
		}
		//Capture checkoutPath
		_this.globalPageSetUserFlow();
		cartTxn.checkoutPath = adobeDataLayerObject.ecommerce.txn.checkoutPath;
		if (Number(cartTxn.subTotal) && !(Number(cartTxn.total))) {
			cartTxn.total =  _this.aaNumFormat(Number(cartTxn.subTotal) + Number(cartTxn.tax));
		}
		return { productData, cartTxn };
	},
	globalPageSetUserFlow: function () {
		let promoCat = window.localStorage.getItem('promoCat');
		if ($('body').hasClass('checkout')) {
			aem_utag_data.site_promo_path = '';
			// checkout customer flow
			if (location.href.indexOf('/checkout/login.speedbuy') > -1) {
				adobeDataLayerObject.pageInfo.pageCategory = 'SPEEDSIGN';
				adobeDataLayerObject.ecommerce.txn.checkoutPath = 'SPEEDBUY';
				aem_utag_data.site_promo_path = 'CHECKOUT';
			} else if (aem_utag_data.page_type === 'checkout-login') {
				adobeDataLayerObject.ecommerce.txn.checkoutPath = 'CHECKOUT';
				aem_utag_data.site_promo_path = 'CHECKOUT_SIGN_IN';
			} else if (promoCat === 'SPEEDBUY_CHECKOUT' && adobeDataLayerObject.pageInfo.siteSection !== 'cart') {
				adobeDataLayerObject.pageInfo.pageCategory = 'P0004';
				aem_utag_data.cust_type =  'SPEEDBUY';
				adobeDataLayerObject.ecommerce.txn.checkoutPath = 'SPEEDBUY CHECKOUT';
			} else if (promoCat === 'PAYPAL_CHECKOUT' && adobeDataLayerObject.pageInfo.siteSection !== 'cart') {
				adobeDataLayerObject.pageInfo.pageCategory = 'PP105';
				aem_utag_data.cust_type =  'PAYPAL';
				adobeDataLayerObject.ecommerce.txn.checkoutPath = 'PAYPAL CHECKOUT';
			} else if (location.href.indexOf('/checkout/reg/') > -1) {
				promoCat = 'STREAMLINE_CHECKOUT';
				adobeDataLayerObject.pageInfo.pageCategory = 'P0289';
				aem_utag_data.cust_type = 'STREAMLINE';
				adobeDataLayerObject.ecommerce.txn.checkoutPath = 'STREAMLINE CHECKOUT';
			} else if (location.href.indexOf('/checkout/npo/') > -1) {
				promoCat = 'NPO_CHECKOUT';
				adobeDataLayerObject.pageInfo.pageCategory = 'P1005';
				aem_utag_data.cust_type = 'NPO';
				adobeDataLayerObject.ecommerce.txn.checkoutPath = 'NPO CHECKOUT';
			} else if (location.href.indexOf('/checkout/new/') > -1) {
				if (cartVars.cart.guestCart) {
					promoCat = 'GUEST_CHECKOUT';
					adobeDataLayerObject.ecommerce.txn.checkoutPath = 'GUEST CHECKOUT';
					adobeDataLayerObject.pageInfo.pageCategory = 'P000G1';
					aem_utag_data.cust_type = 'GUEST';
				} else {
					promoCat = 'NEW_CUSTOMER_CHECKOUT';
					aem_utag_data.cust_type = 'NEW_CUSTOMER';
					adobeDataLayerObject.pageInfo.pageCategory = 'P0003';
					adobeDataLayerObject.ecommerce.txn.checkoutPath = 'NEW CUSTOMER CHECKOUT';
				}
			}
			if (aem_utag_data.page_id === 'APPLY FOR QCARD - QUICKSCREEN') {
				if (promoCat === 'SPEEDBUY_CHECKOUT') {
					adobeDataLayerObject.ecommerce.txn.checkoutPath = 'SPEEDBUY CHECKOUT';
				} else if (promoCat === 'PAYPAL_CHECKOUT') {
					adobeDataLayerObject.ecommerce.txn.checkoutPath = 'PAYPAL CHECKOUT';
				} else if (document.referrer.indexOf('/checkout/reg/') > -1) {
					adobeDataLayerObject.ecommerce.txn.checkoutPath = 'STREAMLINE CHECKOUT';
				} else if (document.referrer.indexOf('/checkout/npo/') > -1) {
					adobeDataLayerObject.ecommerce.txn.checkoutPath = 'NPO CHECKOUT';
				} else if (document.referrer.indexOf('/checkout/new/') > -1) {
					adobeDataLayerObject.ecommerce.txn.checkoutPath = 'NEW CUSTOMER CHECKOUT';
				}
			}
			let errRespCode = '';
			let url = location.href;
			if (url.indexOf('account-locked.cvv') > -1) {
				errRespCode = '10006';
			} else if (url.indexOf('account-locked.login') > -1) {
				errRespCode = '10005';
			} else if (url.indexOf('account-locked.qa') > -1) {
				errRespCode = '10007';
			} else if (url.indexOf('account-locked.other') > -1) {
				errRespCode = typeof urlManager === 'object' && typeof urlManager.getParam(url, 'code') === 'string' ? urlManager.getParam(url, 'code') : '10008';
			}
			if (location.href.indexOf('account-locked') > -1 && promoCat === 'SPEEDBUY_CHECKOUT') {
				adobeDataLayerObject.pageInfo.pageCategory = 'SPEEDSIGN' + errRespCode;
			} else if (location.href.indexOf('account-locked') > -1 && promoCat === 'PAYPAL_CHECKOUT') {
				adobeDataLayerObject.pageInfo.pageCategory = 'PPSIGN' + errRespCode;
			} else if (location.href.indexOf('account-locked') > -1) {
				adobeDataLayerObject.pageInfo.pageCategory = 'COSIGN' + errRespCode;
			}
			if (aem_utag_data.site_promo_path === ''){
				aem_utag_data.site_promo_path = adobeDataLayerObject.ecommerce.txn.checkoutPath.replace(/ /g, "_");
			}
		} else if ($('body').hasClass('dqNotification') && location.href.indexOf('/checkout/') > -1) {
			adobeDataLayerObject.pageInfo.pageCategory = 'COSIGN';
			if (promoCat === 'SPEEDBUY_CHECKOUT') {
				adobeDataLayerObject.pageInfo.pageCategory = 'SPEEDSIGN';
			} else if (promoCat === 'PAYPAL_CHECKOUT') {
				adobeDataLayerObject.pageInfo.pageCategory = 'PPSIGN';
			}
		} else if ($('body').hasClass('dqNotification') && location.href.indexOf('/myaccount/') > -1) {
			adobeDataLayerObject.pageInfo.pageCategory = 'EPSIGN';
		} else if (aem_utag_data.page_type === 'product') {
			if (promoCat === 'SPEEDBUY_CHECKOUT' && $('.overlayGenericError').length > 0) {
				adobeDataLayerObject.ecommerce.txn.checkoutPath = 'SPEEDBUY';
			}
			window.localStorage.removeItem('promoCat');
		} else if (aem_utag_data.page_id === 'ACCOUNT SETTINGS') {
			if ($('#emailWr .sign-in-to-edit').hasClass('active')) {
				adobeDataLayerObject.ecommerce.txn.checkoutPath = 'UPDATE EMAIL';
			} else if (jQuery('#securityQuestionWr .sign-in-to-edit').hasClass('active')) {
				adobeDataLayerObject.ecommerce.txn.checkoutPath = 'UPDATE QUESTIONS';
			}
		} else if (aem_utag_data.page_id === 'SIGN IN: FORGOT PASSWORD') {
			if (promoCat === 'SPEEDBUY_CHECKOUT') {
				adobeDataLayerObject.ecommerce.txn.checkoutPath = 'SPEEDBUY';
			} else if (promoCat === 'PAYPAL_CHECKOUT') {
				adobeDataLayerObject.ecommerce.txn.checkoutPath = 'PAYPAL';
			} else {
				adobeDataLayerObject.ecommerce.txn.checkoutPath = 'CHECKOUT';
			}
		} else if (adobeDataLayerObject.pageInfo.siteSection === 'ott-login') {
			errRespCode = '';
			url = location.href;
			if (url.indexOf('account-locked.login') > -1) {
				errRespCode = '10005';
			} else if (url.indexOf('account-locked.other') > -1) {
				errRespCode = typeof urlManager === 'object' && typeof urlManager.getParam(url, 'code') === 'string' ? urlManager.getParam(url, 'code') : '10008';
			}
			adobeDataLayerObject.pageInfo.pageCategory = 'OTTSIGN' + errRespCode;
		}
	},
	productPageGetProductFindingMethod: function () {
		let findingMethod = 'browse',
			aaSearchData = sessionStorage.getItem('AAfinding'),
			searchStorageData = aaSearchData && aaSearchData.length ? JSON.parse(aaSearchData) : '',
			shoppingCategory = aem_utag_data.shopping_category;
		switch (shoppingCategory) {
		case 'srch':
		case 'isrc':
		case 'srchcontent':
		case 'srchspot':
		case 'psch':
			break;
		default:
			searchStorageData = '';
			break;
		}
		if (searchStorageData) {
			if (searchStorageData.originalTerm && searchStorageData.originalTerm.length) {
				findingMethod = 'Site search';
			}
			if (searchStorageData.resultClicked && searchStorageData.resultClicked.length) {
				findingMethod = 'Search typeahead';
			}
		}
		if (aem_utag_data.referring_item && aem_utag_data.referring_item.length) {
			findingMethod = 'Product recommendations';
		}
		sessionStorage.removeItem('AAfinding');
		return findingMethod;
	},
	findSelectedComboProd: function ({ colorCode, sizeID }) {
		let pData = { currentPrice: '', ats: '' };
		if (colorCode === qVars.format.colorDefaultAjax) {
			colorCode = '';
		}
		if (sizeID === qVars.format.sizeDefaultAjax) {
			sizeID = '';
		}
		if (typeof product !== 'undefined') {
			if (colorCode && sizeID && product.atsByColor) {
				pData = product.atsByColor[colorCode].items[sizeID];
			} else if (colorCode && product.atsByColor) {
				pData = product.atsByColor[colorCode];
			} else if (sizeID && product.atsBySize) {
				pData = product.atsBySize[sizeID];
			}
		}
		return { basePrice: parseFloat(pData.currentPrice.substring(1)), ats: pData.ats };
	}
};

module.exports = _this;
