const GlobalPage = require('../aa-global-page');
const _this = {
	addListeners: function(){
		window.addEventListener("ie-stream-modal-opened", function(e) {
			let data = Object.assign({}, adobeDataLayerObject, {event: "pageLoad"});
			data.pageInfo.pageCategory = e.detail.pageCategory;
			data.pageInfo.pageName = e.detail.pageName;

			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-social-feed-post-modal-opened", function(e) {
			let data = Object.assign({}, adobeDataLayerObject, {event: "pageLoad"});
			data.pageInfo.pageCategory = e.detail.pageCategory;
			data.pageInfo.pageName = e.detail.pageName;

			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-assistant-page-load", function(e) {
			let data = Object.assign({}, adobeDataLayerObject, {links: e.detail.links, search: e.detail.search, event: "pageLoad"});
			data.pageInfo.pageCategory = e.detail.pageCategory;
			data.pageInfo.pageName = e.detail.pageName;

			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-submit-search", function(e) {
			let data = Object.assign({}, adobeDataLayerObject, {links: e.detail.links, search: e.detail.search, event: "searchInitiates"});
			data.pageInfo.pageCategory = e.detail.pageCategory;
			data.pageInfo.pageName = e.detail.pageName;

			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-search-complete", function(e) {
			let data = Object.assign({}, adobeDataLayerObject, {links: e.detail.links, search: e.detail.search, event: "searchComplete"});
			data.pageInfo.pageCategory = e.detail.pageCategory;
			data.pageInfo.pageName = e.detail.pageName;

			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-select-search", function(e) {
			let data = Object.assign({}, adobeDataLayerObject, {links: e.detail.links, search: e.detail.search, event: "searchResultClick"});
			data.pageInfo.pageCategory = e.detail.pageCategory;
			data.pageInfo.pageName = e.detail.pageName;

			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-open-link", function(e) {
			let data = Object.assign({}, adobeDataLayerObject, {links: e.detail.links, event: "linkClick"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-open-product-link", function(e) {
			let data = Object.assign({}, adobeDataLayerObject, {
				links: e.detail.links,
				event: "linkClick",
				aaSp: `PRODUCT_LINK-_-PICKED_FOR_YOU-_-${e.detail.productNumber}`
			});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-tag-select", function(e) {
			let data = Object.assign({}, adobeDataLayerObject, {links: e.detail.links, event: "linkClick"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-open-stream-calendar", function(e) {
			let data = Object.assign({}, adobeDataLayerObject, {links: e.detail.links, event: "linkClick"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-share-stream", function(e) {
			let data = Object.assign({}, adobeDataLayerObject, {links: e.detail.links, event: "linkClick"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-share-post", function(e) {
			let data = Object.assign({}, adobeDataLayerObject, {links: e.detail.links, event: "linkClick"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-like-post", function(e) {
			let data = Object.assign({}, adobeDataLayerObject, {links: e.detail.links, event: "linkClick"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-like-stream", function(e) {
			let data = Object.assign({}, adobeDataLayerObject, {links: e.detail.links, event: "linkClick"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-comment-on-post", function(e) {
			let data = Object.assign({}, adobeDataLayerObject, {links: e.detail.links, event: "linkClick"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-comment-on-stream", function(e) {
			let data = Object.assign({}, adobeDataLayerObject, {links: e.detail.links, event: "linkClick"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-video-loaded", function(e) {
			let data = Object.assign({}, {content: e.detail.content, links: e.detail.links, event: "videoLoad"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-video-pause", function(e) {
			let data = Object.assign({}, {content: e.detail.content, links: e.detail.links, event: "videoPause"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-video-start", function(e) {
			let data = Object.assign({}, {content: e.detail.content, links: e.detail.links, event: "videoStart"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-video-resume", function(e) {
			let data = Object.assign({}, {content: e.detail.content, links: e.detail.links, event: "videoResume"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-video-seeked", function(e) {
			let data = Object.assign({}, {content: e.detail.content, links: e.detail.links, event: "videoSeek"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-video-terminated", function(e) {
			let data = Object.assign({}, {content: e.detail.content, links: e.detail.links, event: "videoTerminate"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-video-progress-25", function(e) {
			let data = Object.assign({}, {content: e.detail.content, links: e.detail.links, event: "videoProgress"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-video-progress-50", function(e) {
			let data = Object.assign({}, {content: e.detail.content, links: e.detail.links, event: "videoProgress"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-video-progress-75", function(e) {
			let data = Object.assign({}, {content: e.detail.content, links: e.detail.links, event: "videoProgress"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-video-progress-100", function(e) {
			let data = Object.assign({}, {content: e.detail.content, links: e.detail.links, event: "videoProgress"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-video-ended", function(e) {
			let data = Object.assign({}, {content: e.detail.content, links: e.detail.links, event: "videoComplete"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("ie-video-error", function(e) {
			let errorMessage = e.detail.errorMessage;
			GlobalPage.captureEventData({
				error: {
					errorCode: errorMessage,
					errorMessage: 'VIDEO ERROR: '+ errorMessage,
					errorType: 'VIDEO ERROR'
				},
				event: 'errorObject',
			});
		});
	},
	init: function(){
		_this.addListeners();
	}
};

module.exports = _this;
