const DataLayerConfigurator = require('./aa-aem-data-layer-setup');
const OffCanvasTag = require('../aa-off-canvas');
const QvcIntegratedExperience = require('../aa-integrated-experience');
const NextGen = require('../aa-nextgen');
const Registration = require('../aa-register');
const SearchPage = require('../aa-search-page');
const CheckoutPage = require('../aa-checkout-page');
const ShopCategory = require('../aa-shop-category');
const SitePromo = require('../aa-site-promo');
const HelperTag = require('../aa-helper');
const GlobalPage = require('../aa-global-page');
const VideoTag = require('../aa-video');

/***	Function Initialization Script	***/
/***	Place All Function Calls Here	***/

const _this = {
	delayPageView : false,
	triggerGlobal: function () {
		try { //protect AEM from potential javascript errors in Tagging
			GlobalPage.getSiteSection();
			GlobalPage.getPageName();
			GlobalPage.setGlobalPageAttr();
			GlobalPage.getPageCategory();
			GlobalPage.addListener();
			GlobalPage.getProductImpression();
			GlobalPage.addVideoListener(VideoTag.init);
			Registration.addListeners();
			SearchPage.addSearchTagListener();
			GlobalPage.addErrorListener();

			// AA for Integrated Experience and Next Gen
			if ($('[data-module-type="CORE_MODULE_CONTAINER"] .ie-feature').length) {
				QvcIntegratedExperience.init();
			}
			if ($('[data-module-type="CORE_MODULE_CONTAINER"] .nextgen-feature').length) {
				NextGen.init();
			}

			if (typeof qVars.feature.adobeAnalyticsSPEnabled !== 'undefined' && qVars.feature.adobeAnalyticsSPEnabled) {
				SitePromo.addSitePromoTagListener();
			}
		} catch (e) {
			console.error('Tagging Error: ' + e);
		}
	},
	fireAEM: function () {
		//Trigger AEM analytics call
		try {
			adobeDataLayerObject.event = 'pageLoad';
			adobeDataLayerObject.aaSp = qGet.urlParam('cm_sp') || '';
			let pageType = aem_utag_data.page_type || '';
			switch (pageType) {
			case 'product':
				if ($('.divGroupSnippet').length > 0) {
					adobeDataLayerObject.eventInfo = 'regularPageLoad';
				} else {
					const paramValue = new URLSearchParams(window.location.search).get('sc');
					adobeDataLayerObject.ecommerce.product[0]['shoppingCategory'] = HelperTag.sanitizeQueryParamValue(paramValue) ;
					adobeDataLayerObject.eventInfo = 'prodView';
				}
				break;
			case 'cart':
				adobeDataLayerObject.eventInfo = 'cartView';
				break;
			case 'checkout':
				switch (qVars.page) {
				case 'payment-options':
					adobeDataLayerObject.eventInfo = 'paymentOptionPage';
					break;
				case 'order-review':
					adobeDataLayerObject.eventInfo = 'orderReview';
					break;
				case 'payment-offers':
					adobeDataLayerObject.eventInfo = 'billingOptionPage';
					break;
				case 'shipping-options':
					adobeDataLayerObject.eventInfo = 'shippingOptionPage';
					break;
				}
				break;
			case 'order':
				if (qVars.page === 'order-confirmation') {
					adobeDataLayerObject.eventInfo = 'orderConfirmation';
				}
				break;
			default:
				adobeDataLayerObject.eventInfo = 'regularPageLoad';
				if (urlManager.getParam(location.href, 'sa').match(/submit|recent|suggested|redirect/)){ //this was a search action, so treat it as a event3(searchComplete) event
					adobeDataLayerObject.eventInfo = 'searchComplete';
					window.history.replaceState({}, "", urlManager.removeParams(location.href, ['sa']));
					//remove sa(search action) from history so that reload or back button to this page doesn't refire searchComplete again.
				}
				if (qVars.page.indexOf('search') > -1) {
					if (adobeDataLayerObject.pageInfo.pagination && adobeDataLayerObject.pageInfo.pagination > 1){
						adobeDataLayerObject.eventInfo = 'pagination';
					} else if (adobeDataLayerObject.pageInfo.sort && adobeDataLayerObject.pageInfo.sort !== ""){
						adobeDataLayerObject.eventInfo = 'sort';
					} else if (adobeDataLayerObject.pageInfo.productRefinementCnt && adobeDataLayerObject.pageInfo.productRefinementCnt !== '0') {
						adobeDataLayerObject.eventInfo = 'filterApplied';
					}
				}
				break;
			}
			window.adobeDataLayer.push(adobeDataLayerObject);
			_this.checkMultiOrder();
		} catch (e) {
			console.error('Tagging Error: ' + e);
		}
	},
	extractMarketingParams: function(){
		let url = window.location.href,
			resource = decodeURIComponent(urlManager.getParam(url, 'resource'));
		if (resource !== ""){
			//extract marketing params from resource and apply to login page so that they fire on 1st page landing.
			url = urlManager.removeParams(url, ['resource']);
			let cid = urlManager.getParam(resource, 'cid');
			if (cid !== "") {
				resource = urlManager.removeParams(resource,['cid']);
				url = urlManager.addParam(url,'cid', cid);
				for (let i = 4; i < 34; i++){ // loop through e4-e33 and check for params
					let evar = urlManager.getParam(resource, `e${i}`);
					if (evar !== "") {
						resource = urlManager.removeParams(resource, [`e${i}`]);
						url = urlManager.addParam(url, `e${i}`, evar);
					}
				}
			}
			url = urlManager.addParam(url,'resource', resource);
			window.history.replaceState({}, "", url);
		}
	},
	checkMultiOrder: function () {
		if (aem_utag_data.page_type === 'order' && qVars.page === 'order-confirmation' && aem_utag_data.trans_orders && aem_utag_data.trans_orders.length > 1) {
			CheckoutPage.setMultiOrdersData();
		}
	},
	updateData: function (){
		if (typeof utag_data !== 'undefined') {
			aem_utag_data = JSON.parse(JSON.stringify(utag_data));
			aem_utag_data.defaultChannelSC = 'LIVE';
			aem_utag_data.shopping_category = aem_utag_data.shopping_category && aem_utag_data.shopping_category !== "" ? aem_utag_data.shopping_category.toLowerCase() : 'NSCP';
		}
	},
	initAA: function () {
		DataLayerConfigurator.setUpDataLayer();
		if (!HelperTag.getAnonymizedUserID()) {
			HelperTag.setAnonymizedUserID();
		}
		_this.extractMarketingParams();
		_this.updateData();
		_this.triggerGlobal();
		_this.fireAEM();
		ShopCategory.init();
		OffCanvasTag.initOffCanvas(_this.fireAEM);
		HelperTag.addMemberNumber();
	},
};

module.exports = _this;
