const GlobalPage = require('../aa-global-page');
const _this = {
	addListeners: function(){
		window.addEventListener("nextgen-open-stream", function(e) {
			let data = Object.assign({}, adobeDataLayerObject, {event: "pageLoad"});
			data.pageInfo.pageCategory = e.detail.pageCategory;
			data.pageInfo.pageName = e.detail.pageName;

			GlobalPage.captureEventData(data);
		});

		window.addEventListener("nextgen-open-thumbable-feed", function(e) {
			let data = Object.assign({}, adobeDataLayerObject, {event: "pageLoad"});
			data.pageInfo.pageCategory = e.detail.pageCategory;
			data.pageInfo.pageName = e.detail.pageName;

			GlobalPage.captureEventData(data);
		});

		window.addEventListener("nextgen-open-link", function(e) {
			let data = Object.assign({}, adobeDataLayerObject, {links: e.detail.links, event: "linkClick"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("nextgen-open-product-link", function(e) {
			let data = Object.assign({}, adobeDataLayerObject, {
				links: e.detail.links,
				event: "linkClick",
				aaSp: `PRODUCT_LINK-_-PICKED_FOR_YOU-_-${e.detail.productNumber}`
			});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("nextgen-share-post", function(e) {
			let data = Object.assign({}, adobeDataLayerObject, {links: e.detail.links, event: "linkClick"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("nextgen-video-loaded", function(e) {
			let data = Object.assign({}, {content: e.detail.content, links: e.detail.links, event: "videoLoad"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("nextgen-video-pause", function(e) {
			let data = Object.assign({}, {content: e.detail.content, links: e.detail.links, event: "videoPause"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("nextgen-video-start", function(e) {
			let data = Object.assign({}, {content: e.detail.content, links: e.detail.links, event: "videoStart"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("nextgen-video-resume", function(e) {
			let data = Object.assign({}, {content: e.detail.content, links: e.detail.links, event: "videoResume"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("nextgen-video-seeked", function(e) {
			let data = Object.assign({}, {content: e.detail.content, links: e.detail.links, event: "videoSeek"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("nextgen-video-terminated", function(e) {
			let data = Object.assign({}, {content: e.detail.content, links: e.detail.links, event: "videoTerminate"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("nextgen-video-progress-25", function(e) {
			let data = Object.assign({}, {content: e.detail.content, links: e.detail.links, event: "videoProgress"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("nextgen-video-progress-50", function(e) {
			let data = Object.assign({}, {content: e.detail.content, links: e.detail.links, event: "videoProgress"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("nextgen-video-progress-75", function(e) {
			let data = Object.assign({}, {content: e.detail.content, links: e.detail.links, event: "videoProgress"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("nextgen-video-progress-100", function(e) {
			let data = Object.assign({}, {content: e.detail.content, links: e.detail.links, event: "videoProgress"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("nextgen-video-ended", function(e) {
			let data = Object.assign({}, {content: e.detail.content, links: e.detail.links, event: "videoComplete"});
			GlobalPage.captureEventData(data);
		});

		window.addEventListener("nextgen-video-error", function(e) {
			let errorMessage = e.detail.errorMessage;
			GlobalPage.captureEventData({
				error: {
					errorCode: errorMessage,
					errorMessage: 'VIDEO ERROR: '+ errorMessage,
					errorType: 'VIDEO ERROR'
				},
				event: 'errorObject',
			});
		});
	},
	init: function(){
		_this.addListeners();
	}
};

module.exports = _this;
