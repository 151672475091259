//this object handles shopping category tagging attachment to product links.
const _this = {
	attachSC : function(e){
		let $e = $(e),
			href = $e.attr('href'),
			component = $e.parents('[data-component-type]'),
			module = $e.parents('[data-module-type]'),
			moduleType = module.data('module-type'),
			compType = component.last().data('component-type'),
			isWishlist = (module.find('.wishlistCarousel').length > 0),
			isSavedItems = (module.find('.savedItemsCarousel').length > 0),
			dataSC = '';
		switch (moduleType){
		case 'LUNCH_TIME_SPECIALS':
			dataSC = 'LTS';
			break;
		case 'LATE_NIGHT_SPECIALS':
			dataSC = 'LNS';
			break;
		case 'PRIME_TIME_SPECIALS':
			dataSC = 'PTS';
			break;
		case 'PRODUCT_LISTING':
			if (aem_utag_data.shopping_category === 'NAVLIST') {
				dataSC = 'NAVLIST';
			} else if (aem_utag_data.shopping_category === 'SRCH') {
				dataSC = 'SRCH';
			} else if (aem_utag_data.shopping_category === 'PSCH') {
				dataSC = 'PSCH';
			}
			break;
		case 'SPOTLIGHT_CAROUSEL':
			dataSC = 'SRCHSPOT';
			break;
		case 'DEALS_EVENT_MODULE':
			dataSC = 'DEALS';
			break;
		case 'IROA_PRODUCT_LISTING':
			dataSC = aem_utag_data.shopping_category;
			break;
		case 'TV_PROGRAM_GUIDE':
			dataSC = 'GUIDE';
			break;
		case 'PRODUCT_CAROUSEL':
			dataSC = 'PRODFEED';
			if ($e.closest('.recentlyViewedCarousel').length > 0) {
				dataSC = 'RECVIEW';
			} else if (module.find('.productCarousel.ioCarousel').length > 0) {
				switch (aem_utag_data.page_type) {
				case 'home':
					dataSC = 'HPREC'; break;
				case 'product-listing-search':
					dataSC = 'SRCHREC'; break;
				case 'product':
					if (href.indexOf('recommendationTypeId=IOFFER') > -1) {
						dataSC = 'DTLR';
					} else if (href.indexOf('recommendationTypeId=BROWSEMORE') > -1) {
						dataSC = 'DTBM';
					} else if (href.indexOf('recommendationTypeId=RECENTVIEWED') > -1) {
						dataSC = 'RECVIEW';
					}
					break;
				case 'cart':
					dataSC = 'CRTR'; break;
				case 'order-detail':
					dataSC = 'ODRI'; break;
				case 'order':
					dataSC = 'OCRI'; break;
				case 'order-tracking':
					dataSC = 'OTRI'; break;
				default:
					if (qVars.page === 'my-recommendations') {
						dataSC = 'PERSONAL';
					} else {
						dataSC = 'NAVREC';
					}
				}
			}
			break;
		}
		switch (compType){
		case 'ON_AIR':
			dataSC = 'IOA';
			break;
		case 'ITEMS_RECENTLY_ON_AIR':
			dataSC = '24HR';
			break;
		}
		if (aem_utag_data.shopping_category === 'SRCH' && ['LARGE_STATIC_IMAGE', 'PRODUCT_CAROUSEL', 'CONTAINER_STRIP', 'FEATURED_BRANDS', 'SHOP_BY_CATEGORY'].indexOf(moduleType) > -1) {
			dataSC = 'SRCHCONTENT';
		}
		if (isWishlist) {
			switch (aem_utag_data.page_type) {
			case 'home':
				dataSC = 'HPWISH'; break;
			case 'product-listing-search':
				dataSC = 'SRCHWISH'; break;
			case 'product':
				dataSC = 'DTLWISH'; break;
			case 'cart':
				dataSC = 'CRTWISH'; break;
			case 'order-detail':
				dataSC = 'ODWISH'; break;
			case 'order':
				dataSC = 'OCWISH'; break;
			case 'order-tracking':
				dataSC = 'OTWISH'; break;
			default:
				if (qVars.page === 'my-recommendations') {
					dataSC = 'PERSONALWISH';
				} else {
					dataSC = 'NAVWISH';
				}
			}
		} else if (isSavedItems) {
			switch (aem_utag_data.page_type) {
			case 'home':
				dataSC = 'HPSAVED'; break;
			case 'product-listing-search':
				dataSC = 'SRCHSAVED'; break;
			case 'product':
				dataSC = 'DTLSAVED'; break;
			case 'cart':
				dataSC = 'CRTSAVED'; break;
			case 'order-detail':
				dataSC = 'ODSAVED'; break;
			case 'order':
				dataSC = 'OCSAVED'; break;
			case 'order-tracking':
				dataSC = 'OTSAVED'; break;
			default:
				if (qVars.page === 'my-recommendations') {
					dataSC = 'PERSONALSAVED';
				} else {
					dataSC = 'NAVSAVED';
				}
			}
		}
		if (component.data('shopping-category') && component.data('shopping-category').length > 0) {
			dataSC = component.data('shopping-category');
		}
		if (dataSC !== ''){
			$e.attr('href', urlManager.addParam(href, 'sc', dataSC));
		}
	},
	productSC: function(){
		//iterate through all product links and attempt to attach shopping category (sc=) tagging param
		$('a[href*=".product."], a[data-prod-id]').each((index, element) => {
			if (index === undefined && element === undefined) {
				// trhowing a silly error so both index and element are used in this scope and I don't get the "no unsused variables" error from eslint
				throw new Error('Expected either "index" or "element" to be defined');
			}
			_this.attachSC(element);
		});
	},
	init: function(){
		_this.productSC();
		$(document).ajaxComplete(function(){
			_this.productSC();
		});
	}
};

module.exports = _this;
