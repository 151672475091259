const _this = {
	getPageName: function () {
		let pageType = aem_utag_data.page_type || '';
		switch (pageType) {
		case 'wishlist':
			if (window.location.pathname.search(/\/reg\/wishlist/i) >= 0) {
				adobeDataLayerObject.pageInfo.pageName = 'WISHLIST';
			}
			if (window.location.pathname.search(/shared-wishlist\.html/i) >= 0) {
				adobeDataLayerObject.pageInfo.pageName = 'WISHLIST: SHARED';
			}
			break;
		case 'order-detail':
			if (location.href.indexOf('order-receipt') > -1) {
				adobeDataLayerObject.pageInfo.pageName = 'CHECKOUT: ORDER RECEIPT';
			}
			break;
		case 'reset-password':
			adobeDataLayerObject.pageInfo.pageName = 'SIGN IN: RESET PASSWORD';
			if (window.location.pathname.search(/forced/i) >= 0) {
				adobeDataLayerObject.pageInfo.pageName = 'SIGN IN: FORCED PASSWORD RESET';
			}
			break;
		case 'myaccount-order-status': {
			let orderType = window.location.hash.substring(1).trim().toLowerCase();
			switch (orderType) {
			case 'easypaymonthly':
				adobeDataLayerObject.pageInfo.pageName = 'ORDER STATUS: EASY PAY ORDERS';
				break;
			case 'autodelivery':
				adobeDataLayerObject.pageInfo.pageName = 'ORDER STATUS: AUTO-DELIVERY ORDERS';
				break;
			case 'waitlist':
				adobeDataLayerObject.pageInfo.pageName = 'ORDER STATUS: ADVANCED AND WAITLIST ORDERS';
				break;
			case 'returned':
				adobeDataLayerObject.pageInfo.pageName = 'ORDER STATUS: RETURNED ORDERS';
				break;
			default:
				if (window.location.href.indexOf('writereview') > -1) {
					adobeDataLayerObject.pageInfo.pageName = 'MY ACCOUNT: WRITE A REVIEW';
				} else {
					adobeDataLayerObject.pageInfo.pageName = 'ORDER STATUS: ALL ORDERS';
				}
				break;
			}
			window.addEventListener('hashchange', () => {
				orderType = window.location.hash.substring(1).trim().toLowerCase();
				const dl = adobeDataLayer.getState();
				switch (orderType) {
				case 'easypaymonthly':
					dl.pageInfo.pageName = 'ORDER STATUS: EASY PAY MONTHLY ORDERS';
					break;
				case 'autodelivery':
					dl.pageInfo.pageName = 'ORDER STATUS: AUTO-DELIVERY ORDERS';
					break;
				case 'waitlist':
					dl.pageInfo.pageName = 'ORDER STATUS: ADVANCED AND WAITLIST ORDERS';
					break;
				case 'returned':
					dl.pageInfo.pageName = 'ORDER STATUS: RETURNED ORDERS';
					break;
				default:
					if (window.location.href.indexOf('writereview') > -1) {
						dl.pageInfo.pageName = 'MY ACCOUNT: WRITE A REVIEW';
					} else {
						dl.pageInfo.pageName = 'ORDER STATUS: ALL ORDERS';
					}
					break;
				}
				adobeDataLayerObject = dl;
				adobeDataLayer.push({
					event: 'pageLoad',
					pageInfo: {
						pageName: adobeDataLayerObject.pageInfo.pageName
					},
				});
			});
		}
		}
		adobeDataLayerObject.pageInfo.pageName = adobeDataLayerObject.pageInfo.pageName ? adobeDataLayerObject.pageInfo.pageName.toLowerCase() : '';
	},
	loginRef: function () {
		let ref = document.referrer;
		sessionStorage.setItem('loginRef', ref);
	},
	getPageCategory: function () {
		let qq = qGet.urlParam('qq'); // bc = breadcrumb, mh = masthead, ft = footer, em = email, oc = order confirm, ec = email order confirm, es = email ship confirm, ez = manage ezpay
		let resource = qGet.urlParam('resource') !== null ? decodeURIComponent(qGet.urlParam('resource')) : '';
		let ref = document.referrer;
		let resQQ = qGet.queryParam(resource, 'qq');
		let cmVen = qGet.urlParam('cm_ven') !== null ? qGet.urlParam('cm_ven') : qGet.queryParam(resource, 'cm_ven');
		let pageType = aem_utag_data.page_type || '';
		switch (pageType) {
		case 'wishlist':
			if (window.location.pathname.search(/\/reg\/wishlist/i) >= 0 || window.location.pathname.search(/shared-wishlist\.html/i) >= 0) {
				adobeDataLayerObject.pageInfo.pageCategory = 'WISH';
			} else {
				adobeDataLayerObject.pageInfo.pageCategory = pageType;
			}
			break;
		case 'myaccount-landing':
			if (platform.device === 'android') {
				adobeDataLayerObject.pageInfo.pageCategory = 'AND24';
			} else if (platform.device === 'iphone' || platform.device === 'ipad') {
				adobeDataLayerObject.pageInfo.pageCategory = 'IOS24';
			} else if (qq === 'bc') {
				adobeDataLayerObject.pageInfo.pageCategory = 'P0024R';
			} else if (qq === 'mh') {
				adobeDataLayerObject.pageInfo.pageCategory = 'P0024MH';
			}
			break;
		case 'myaccount-login':
		case 'myaccount-dq-notification':
		case 'myaccount-locked':
		case 'forgot-password':
		case 'forgot-pin':
		case 'reset-password':
		case 'reset-pin': {
			let page_id = aem_utag_data.page_id || adobeDataLayerObject.pageInfo.pageName ? adobeDataLayerObject.pageInfo.pageName.toUpperCase() : '';
			if (pageType === 'forgot-password') {
				if (ref.indexOf('speedbuy') > -1 && page_id === 'SIGN IN: FORGOT PASSWORD') {
					adobeDataLayerObject.pageInfo.pageCategory = 'SPEEDSIGN';
					break;
				} else if (ref.indexOf('checkout') > -1 && page_id === 'SIGN IN: FORGOT PASSWORD') {
					adobeDataLayerObject.pageInfo.pageCategory = 'COSIGN';
					break;
				}
			}
			if (pageType === 'myaccount-login') {
				_this.loginRef();
			} else {
				if (ref.indexOf('LogonForm') > -1 || ref.indexOf('login.html') > -1) {
					if (resource === null || resource === '') {
						resource = qGet.queryParam(ref, 'resource') !== null ? decodeURIComponent(qGet.queryParam(ref, 'resource')) : '';
					}
					if (qq === null) {
						qq = qGet.queryParam(ref, 'qq');
					}
					if (resQQ === null) {
						resQQ = qGet.queryParam(resource, 'qq');
					}
					if (cmVen === null) {
						cmVen = qGet.queryParam(ref, 'cm_ven');
					}
				}
			}
			adobeDataLayerObject.pageInfo.pageCategory = 'MHSIGN';
			aem_utag_data.site_promo_path = 'GENERAL_SIGN_IN';
			aem_utag_data.pathIndicator = 'GENERAL SIGN IN';
			if (qq === 'mh' || resQQ === 'mh') {
				return;
			} else if (qq === 'em' || resQQ === 'em') {
				adobeDataLayerObject.pageInfo.pageCategory = 'RAESIGN';
				aem_utag_data.site_promo_path = 'REAUTH_EMAIL_SIGN_IN';
				aem_utag_data.pathIndicator = 'UPDATE EMAIL';
			} else if (qq === 'ez' || resQQ === 'ez' || (resource.indexOf('order-status.html') > -1 && resource.indexOf('orderType=EasyPay') > -1 && resource.indexOf('monthly=true') > -1)) {
				adobeDataLayerObject.pageInfo.pageCategory = 'EPSIGN';
				aem_utag_data.site_promo_path = 'EASYPAY_SIGN_IN';
				aem_utag_data.pathIndicator = 'MANAGE EASYPAY';
			} else if ((resource.indexOf('auto-delivery') > -1 && resource.indexOf('plan-summary.html') > -1) || (resource.indexOf('order-status.html') > -1 && resource.indexOf('orderType=AutoDelivery') > -1)) {
				adobeDataLayerObject.pageInfo.pageCategory = 'ADSIGN';
				aem_utag_data.site_promo_path = 'AD_SIGN_IN';
				aem_utag_data.pathIndicator = 'MANAGE AUTO-DELIVERY';
			} else if (resource.indexOf('order-status.html') > -1) {
				adobeDataLayerObject.pageInfo.pageCategory = 'OSSIGN';
				aem_utag_data.site_promo_path = 'ORDER_STATUS_SIGN_IN';
				aem_utag_data.pathIndicator = 'ORDER STATUS';
			} else if (resource.indexOf('account-settings.html') > -1) {
				adobeDataLayerObject.pageInfo.pageCategory = 'ACSSIGN';
				aem_utag_data.site_promo_path = 'ACC_SET_SIGN_IN';
				aem_utag_data.pathIndicator = 'ACCOUNT SETTINGS';
			} else if (resource.indexOf('shipping-addresses.html') > -1) {
				adobeDataLayerObject.pageInfo.pageCategory = 'SASIGN';
				aem_utag_data.site_promo_path = 'ADDRESS_SIGN_IN';
				aem_utag_data.pathIndicator = 'MANAGE SHIPPING ADDRESS';
			} else if (resource.indexOf('payment-methods.html') > -1) {
				adobeDataLayerObject.pageInfo.pageCategory = 'PMSIGN';
				aem_utag_data.site_promo_path = 'PAY_METHOD_SIGN_IN';
				aem_utag_data.pathIndicator = 'MANAGE PAY METHOD';
			} else if (resource.indexOf('user-preferences.html') > -1) {
				adobeDataLayerObject.pageInfo.pageCategory = 'EMAILSIGN';
				aem_utag_data.site_promo_path = 'EMAIL_SIGN_IN';
				aem_utag_data.pathIndicator = 'EMAIL PREFERENCES';
			} else if (resource.indexOf('wishlist.html') > -1) {
				adobeDataLayerObject.pageInfo.pageCategory = 'WLSIGN';
				aem_utag_data.site_promo_path = 'WISHLIST_SIGN_IN';
				aem_utag_data.pathIndicator = 'WISHLIST';
			}
		}
			break;
		case 'myaccount-order-status':
			adobeDataLayerObject.pageInfo.pagination = '1';
			adobeDataLayerObject.pageInfo.pageCategory = 'OSOTHER';
			if (ref.indexOf('LogonForm') > -1) {
				if (qq === null) {
					qq = qGet.queryParam(ref, 'qq');
				}
				if (cmVen === null) {
					cmVen = qGet.queryParam(ref, 'cm_ven');
				}
			}
			if (window.location.href.indexOf('writereview') > -1) {
				adobeDataLayerObject.pageInfo.pageCategory = 'P0046';
			}
			if ((platform.device === 'iphone' || platform.device === 'ipad') && mastheadDisplay.isMobileApp) {
				adobeDataLayerObject.pageInfo.pageCategory = 'OSIOS';
			} else if (platform.device === 'android' && mastheadDisplay.isMobileApp) {
				adobeDataLayerObject.pageInfo.pageCategory = 'OSAND';
			} else if (qq === 'bc') {
				adobeDataLayerObject.pageInfo.pageCategory = 'OSNAV';
			} else if (qq === 'mh') {
				adobeDataLayerObject.pageInfo.pageCategory = 'OSMH';
			} else if (qq === 'ft') {
				adobeDataLayerObject.pageInfo.pageCategory = 'OSFOOT';
			} else if (qq === 'es' || cmVen === 'SHIPCONFIRM') {
				adobeDataLayerObject.pageInfo.pageCategory = 'OSSHIP';
			} else if (qq === 'ec' || cmVen === 'ORDERCONFIRM') {
				adobeDataLayerObject.pageInfo.pageCategory = 'OSCONFE';
			} else if (qq === 'oc') {
				adobeDataLayerObject.pageInfo.pageCategory = 'OSCONF';
			} else if (ref.indexOf('login.html') > -1 && typeof sessionStorage.getItem('loginRef') === 'string') {
				let loginRef = sessionStorage.getItem('loginRef');
				switch (true) {
				case loginRef.indexOf('my-account.html') > -1:
					adobeDataLayerObject.pageInfo.pageCategory = 'OSACCT';
					break;
				case loginRef === '' || loginRef.indexOf(window.location.hostname) < 0:
					adobeDataLayerObject.pageInfo.pageCategory = 'OSLP';
					break;
				}
			} else if (ref.indexOf('my-account.html') > -1) {
				adobeDataLayerObject.pageInfo.pageCategory = 'OSACCT';
			} else if (ref === '' || ref.indexOf(window.location.hostname) < 0) {
				adobeDataLayerObject.pageInfo.pageCategory = 'OSLP';
			}
			break;
		case 'order-detail': {
			let pCat = window.localStorage.getItem('promoCat');
			if (location.href.indexOf('order-receipt') > -1 && ref.indexOf('/checkout/reg/') > -1 && pCat === null) {
				adobeDataLayerObject.pageInfo.pageCategory = 'P0289';
			} else if (location.href.indexOf('order-receipt') > -1 && ref.indexOf('/checkout/npo/') > -1) {
				adobeDataLayerObject.pageInfo.pageCategory = 'P1005';
			} else if (location.href.indexOf('order-receipt') > -1 && ref.indexOf('/checkout/new/') > -1) {
				adobeDataLayerObject.pageInfo.pageCategory = 'P0003';
			} else if (location.href.indexOf('order-receipt') > -1 && pCat === 'SPEEDBUY_CHECKOUT') {
				adobeDataLayerObject.pageInfo.pageCategory = 'P0004';
			} else if (location.href.indexOf('order-receipt') > -1 && pCat === 'PAYPAL_CHECKOUT') {
				adobeDataLayerObject.pageInfo.pageCategory = 'PP105';
			} else {
				adobeDataLayerObject.pageInfo.pageCategory = pageType;
			}
			window.localStorage.removeItem('promoCat');
		}
			break;
		}
	},
};

module.exports = _this;
