const HelperTag = require('./aa-helper');

module.exports = HelperTag;

if (window) {
	// TO DO - Retire this code
	// Assigns 'helperTag' to the global window object to retain compatibility with existing legacy 
	// setups that previously relied on global scope availability. 
	// This approach ensures that as we transition to using 'require' for module management, along with 
	// Gulp for building a single optimised bundle with source maps, existing integrations remain functional.
	// This step is temporary and part of a phased migration, where we're modularising one file at a time.
	window['helperTag'] = HelperTag;
}
