const HelperTag = require('../aa-helper');
const _this = {
	setCartViewAttr: function () {
		let prodTxnData = _this.getCartProdTxnData();
		adobeDataLayerObject.ecommerce.product = prodTxnData.productData;
		adobeDataLayerObject.ecommerce.txn = prodTxnData.cartTxn;
	},
	getCartProdTxnData: function () {
		return HelperTag.cartPageGetCartProdTxnData();
	},
};

module.exports = _this;
