const HelperTag = require('../aa-helper');
const _this = {
	/**
	 * Initializes event listeners for various elements on the page.
	 * 
	 * This method checks if an element with the ID 'reviewsContainer' exists on the page.
	 * If it does, it calls the `initRatingAndReviewSubmitListener` method to set up listeners
	 * for rating and review submissions. It can be extended to initialize more event listeners as needed
	 */
	initEventListeners: () => {
		if (document.getElementById('reviewsContainer') !== null) {
			_this.initRatingAndReviewSubmitListener();
		}
	},
	/**
	 * Initializes a listener for the submission of ratings and reviews.
	 * 
	 * This method overrides the `XMLHttpRequest.open` and `XMLHttpRequest.send` methods
	 * to intercept requests to the Bazaarvoice API. When a request to submit a rating
	 * or review is detected, an event is pushed to the Adobe Data Layer.
	 * 
	 * @example
	 * // Usage example:
	 * initRatingAndReviewSubmitListener();
	 * 
	 * // Now, any XMLHttpRequest to the specified URL pattern will trigger the event push.
	 */
	initRatingAndReviewSubmitListener: () => {
		const originalOpen = XMLHttpRequest.prototype.open,
			originalSend = XMLHttpRequest.prototype.send,
			urlPattern = /https:\/\/(?:stg\.)?api\.bazaarvoice\.com\/data\/progressiveSubmit\.json/;
		XMLHttpRequest.prototype.open = function(method, url, async, user, password) {
			this._url = url; // Save the URL to a custom property to use it in the onload handler
			originalOpen.call(this, method, url, async, user, password);
		};
		XMLHttpRequest.prototype.send = function(body) {
			this.addEventListener('load', function() {
				if (urlPattern.test(this._url)) {
					const currentDL = adobeDataLayer.getState();
					currentDL['event'] = 'ratingReviewSubmit'; // event26
					adobeDataLayer.push(currentDL);
				}
			});
			originalSend.call(this, body);
		};
	},
	getPageName: function () {
		let productName = aem_utag_data.product_name && aem_utag_data.product_name.length && aem_utag_data.product_name[0] ? aem_utag_data.product_name[0] : '';
		let productID = aem_utag_data.product_id && aem_utag_data.product_id.length && aem_utag_data.product_id[0] ? aem_utag_data.product_id[0] : '';
		if (!(aem_utag_data.page_id)) {
			if ($('.divGroupSnippet').length > 0 && productName) {
				// Group Page
				adobeDataLayerObject.pageInfo.pageName = 'GROUP: ' + productName;
			} else if ($('.makeSelection').length > 0) {
				// MYS Page
				adobeDataLayerObject.pageInfo.pageName = 'MAKE YOUR SELECTION: ' + productName + ' (' + productID + ')';
			} else {
				adobeDataLayerObject.pageInfo.pageName = 'PRODUCT: ' + productName;
			}
		} else {
			adobeDataLayerObject.pageInfo.pageName = 'PRODUCT: ' + productName + ' (' + productID + ')';
		}
	},
	getPageCategory: function () {
		let pageCategory = aem_utag_data.product_category_id || '';
		if (pageCategory) {
			adobeDataLayerObject.pageInfo.pageCategory = pageCategory;
		}
		if ($('.divGroupSnippet').length > 0) {
			// Group Page
			adobeDataLayerObject.pageInfo.pageCategory = 'GROUP';
		} else if ($('.makeSelection').length > 0) {
			// MYS Page
			adobeDataLayerObject.pageInfo.pageCategory = 'MYS';
			if (pageCategory) {
				adobeDataLayerObject.pageInfo.pageCategory += pageCategory;
			}
		}
	},
	setProductViewAttr: function () {
		adobeDataLayerObject.ecommerce.product = _this.getProductData();
	},
	countCombos: function (obj) {
		return HelperTag.productPageCountCombos(obj);
	},
	createVariantID: function (sProdId, sColor, sSize) {
		let variantData;
		if (sColor && sSize) {
			variantData = sProdId + ' ' + sColor + ' ' + sSize;
		} else if (sColor) {
			variantData = sProdId + ' ' + sColor + ' ' + qVars.format.sizeDefaultAjax;
		} else if (sSize) {
			variantData = sProdId + ' ' + qVars.format.colorDefaultAjax + ' ' + sSize;
		} else {
			variantData = sProdId + ' ' + qVars.format.colorDefaultAjax + ' ' + qVars.format.sizeDefaultAjax;
		}
		return variantData;
	},
	getProductData: function (resData) {
		return HelperTag.productPageGetProductData(resData);
	},
	getIOA: function () {
		return HelperTag.productPageGetIOA();
	},
	generalClickHandler: function (ele) {
		let $el = $(ele),
			$elParents = $el.parents(),
			linkCategory = 'PRODUCTDETAIL',
			linkSection = 'BAZAARVOICE',
			linkName = '';

		if ($elParents.is('#reviewsSummary') || $elParents.is('.bv-stars-container')) {
			linkName = 'READ_ALL_MY_REVIEWS';
		}
		if ($elParents.is('.bv-control-bar-sort')) {
			linkName = 'SORT';
		}
		if ($el.is('.bv-write-review-label.bv-first-to-review')) {
			linkName = 'WRITE_REVIEW:FIRST_TO_REVIEW';
		}
		if ($el.is('.bv-write-review')) {
			linkName = 'WRITE_REVIEW';
		}
		if ($el.is('.bv-content-report-btn') || $elParents.is('.bv-content-report-btn')) {
			linkName = 'INAPPROPRIATE';
		}
		if ($el.is('.bv-content-btn-feedback-yes')|| $elParents.is('.bv-content-btn-feedback-yes')) {
			linkName = 'HELPFUL_REVIEW_YES';
		}
		if ($el.is('.bv-content-btn-feedback-no')|| $elParents.is('.bv-content-btn-feedback-no')) {
			linkName = 'HELPFUL_REVIEW_NO';
		}
		if ($el.is('.bv-content-pagination-buttons-item-next') && $elParents.is('.bv-content-pagination-buttons-item-next')) {
			linkName = 'MORE_REVIEWS';
		}
		if ($el.is('.bv-content-pagination-buttons-item-previous') && $elParents.is('.bv-content-pagination-buttons-item-previous')) {
			linkName = 'PREVIOUS_REVIEWS';
		}
		if ($el.is('.bv-ask-question-label.bv-first-to-ask-question')) {
			linkName = 'ASK_QUESTION:FIRST_TO_ASK_REVIEW';
		}
		if ($el.is('.bv-ask-question')) {
			linkName = 'ASK_QUESTION';
		}
		if (($elParents.is('.reviewsContainer') || $elParents.is('#reviewsContainer')) && $elParents.is('headerText')) {
			linkName = 'QA';
		}
		if ($el.is('.bv-content-data-count[data-content-type="review"]') || $elParents.is('.bv-content-data-count[data-content-type="review"]')) {
			linkName = 'READ_ALL_MY_REVIEWS';
		}
		if ($el.is('.bv-content-data-count[data-content-type="question"]') || $elParents.is('.bv-content-data-count[data-content-type="question"]')) {
			linkName = 'VIEWALL_QA';
		}
		if ($elParents.is('#BVQASearchContainer') && $el.is('input[name="bv-search"]')) {
			linkName = 'QA_ENABLE_SEARCH';
		}
		if ($elParents.is('#BVQASearchContainer') && $el.is('bv-content-search-btn')) {
			linkName = 'QA_SEARCH';
		}
		if ($elParents.is('#BVRRSearchContainer') && $el.is('input[name="bv-search"]')) {
			linkName = 'REVIEW_ENABLE_SEARCH';
		}
		if ($elParents.is('#BVRRSearchContainer') && $el.is('bv-content-search-btn')) {
			linkName = 'REVIEW_SEARCH';
		}
		if ($elParents.is('bv-content-question') && ($el.is('.bv-content-title-container') || $elParents.is('.bv-content-title-container'))) {
			linkName = 'QUESTION';
		}
		if ($elParents.is('bv-content-question') && $el.is('.bv-content-btn-feedback-yes')) {
			linkName = 'HELPFUL_YES';
		}
		if ($elParents.is('bv-content-question') && $el.is('.bv-content-btn-feedback-no')) {
			linkName = 'HELPFUL_NO';
		}
		if ($elParents.is('bv-content-question') && $el.is('.bv-content-report-btn')) {
			linkName = 'INAPPROPRIATE';
		}
		if ($elParents.is('bv-content-question') && $elParents.is('bv-secondary-content-actions-container')) {
			linkName = 'ANSWER_QUESTION';
		}
		if ($el.is('#bv-textarea-field-reviewtext')) {
			linkName = 'REVIEW_TEXT_ENABLE';
		}
		if ($el.is('#bv-textarea-field-netpromotercomment')) {
			linkName = 'REVIEW_PROMOTER_COMMENT';
		}
		if ($elParents.is('.bv-submission-star-rating-control')) {
			linkName = 'REVIEW_RATING';
		}
		if ($el.is('.bv-email-field-useremail')) {
			linkName = 'EMAIL';
		}
		if ($elParents.is('.bv-fieldset-netpromoterscore-group')) {
			linkName = 'REVIEW_RECOMMEND';
		}
		if ($el.is('#bv-text-field-title')) {
			linkName = 'REVIEW_TITLE';
		}
		if ($elParents.is('.bv-review-media-actions')) {
			linkName = 'REVIEW_MEDIA';
		}
		if ($elParents.is('.bv-fieldset-isrecommended-group') && $el.is('#bv-radio-isrecommended-true-label')) {
			linkName = 'REVIEW_RECOMMEND_YES';
		}
		if ($elParents.is('.bv-fieldset-isrecommended-group') && $el.is('#bv-radio-isrecommended-false-label')) {
			linkName = 'REVIEW_RECOMMEND_NO';
		}
		if ($el.is('button.bv-submission-button-submit[aria-label="Post Review"]')) {
			linkName = 'SUBMIT_REVIEW';
		}
		if ($el.is('.bv-mbox-close') || $elParents.is('.bv-mbox-close')) {
			linkName = 'RETURN';
		}
		if ($el.is('#bv-textarea-field-questionsummary')) {
			linkName = 'QUESTION_SUMMARY';
		}
		if ($el.is('button.bv-submission-button-submit[aria-label="Post Question"]')) {
			linkName = 'POST_QUESTION';
		}
		if ($el.is('.bv-active-filter-button') || $elParents.is('.bv-active-filter-button')) {
			linkName = 'REMOVE_ACTIVE_FILTERS';
		}
		if ($elParents.is('.bv-content-badges-container')) {
			linkName = 'BADGES';
		}
		return { linkCategory, linkSection, linkName };
	},
	getProductFindingMethod: function () {
		return HelperTag.productPageGetProductFindingMethod();
	},
};

module.exports = _this;
