/**
 * Propery of "adobeDataLayer" containing data related to "links"
 * @typedef { {linkCategory: string; linkSection: string; linkName: string; linkType: string;} } DataLayerLinks
 */

const CONSTANTS = require('./aa-site-promo.constants'),
	GlobalPage = require('../aa-global-page'),
	HelperTag = require('../aa-helper');

const _this = {

	/**
	 * 
	 * @param {JQuery<HTMLElement>} element
	 * @returns {string} modulePosition
	 */
	getModulePosition: function (element) {
		const moduleTypeClicked = element.closest(CONSTANTS.CSS_SELECTORS.DataModuleType),
			totalSections = $(CONSTANTS.CSS_SELECTORS.PageContentId)
				.find(CONSTANTS.CSS_SELECTORS.DataModuleType)
				.not(CONSTANTS.CSS_SELECTORS.HideModule),
			sectionPosition = totalSections.index(moduleTypeClicked);
		return sectionPosition > -1 ? String(sectionPosition + 1) : '';
	},
	/**
	 * @param {JQuery<HTMLElement>} element
	 * @returns {string} moduleType
	 */
	getModuleType: function (element) {
		let moduleType = '';
		const elementWithModuleTypeDefinition = $(element.closest(CONSTANTS.CSS_SELECTORS.DataModuleType));
		if (elementWithModuleTypeDefinition.length > 0) {
			moduleType = elementWithModuleTypeDefinition[0].getAttribute('data-module-type') || '';
		}
		return moduleType.toLowerCase();
	},
	/**
	 * @param {JQuery<HTMLElement>} $el
	 * @returns {string} componentType
	 */
	getComponentType: function ($el) {
		const closestElWithCompType = $el.closest('[data-component-type]');
		return (closestElWithCompType && closestElWithCompType.length)
			? $el[0].closest('[data-component-type]').getAttribute('data-component-type') || ''
			: '';
	},
	//Refactored from aa-search-page.js getSearchText
	/**
	 * 
	 * @returns {string}
	 */
	getSearchPageText: function () {
		let searchFormTxt = $('form.searchForm [name="keyword"]').val(),
			mastHeadTxt = $('#txtMastheadSearch').val(),
			searchTerm = '',
			srchTxt = '';
		if (typeof aem_utag_data.search_term !== 'undefined' && aem_utag_data.search_term.length) {
			searchTerm = aem_utag_data.search_term.toLowerCase();
		}
		if (typeof searchFormTxt !== 'undefined' && searchFormTxt.length) {
			srchTxt = searchFormTxt;
		} else if (typeof mastHeadTxt !== 'undefined' && mastHeadTxt.length) {
			srchTxt = mastHeadTxt;
		} else if (typeof searchTerm !== 'undefined' && searchTerm.length) {
			srchTxt = searchTerm;
		}
		return (typeof srchTxt !== 'undefined' && srchTxt.length ? srchTxt.toLowerCase() : '');
	},
	/**
	 * @param {JQuery<HTMLElement>} $el
	 * @returns {string} moduleLink
	 */
	getModuleLink: function ($el) {
		$el = $($el);
		const originalTerm= _this.getSearchPageText(),
			ele = ($el[0] instanceof Document) ? $el[0].activeElement : $el[0],
			componentType = _this.getComponentType($el),
			moduleType = _this.getModuleType($el),
			$elParents = $el.parents();
		let moduleLink = '';

		if (moduleType === 'MH') {
			let dropDownName = '';
			if ($el.closest('.shopByDDOpen').length || $el.closest('.shopByDropDown').length) {
				dropDownName = 'shop by dropdown';
			} else if ($el.closest('.shopByMobile').length || $el.closest('.shopByMobileSub').length) {
				dropDownName = 'shop by dropdown mobile';
			} else if ($el.closest('.myAccountDropDown').length) {
				dropDownName = 'my account dropdown';
			}
			let shopCat = $el.closest('.flyoutSection').length ? $(ele.closest('.flyoutSection')).find('.mobFlyoutCat').text() : '',
				label = $el.closest('[aria-label]').length ? ele.closest('[aria-label]').getAttribute('aria-label') : '',
				linkText = ele.getAttribute('alt') || ele.getAttribute('title') || ele.innerText || '';
			if (dropDownName) {
				moduleLink = `${dropDownName} : `;
			}
			if (shopCat) {
				moduleLink += `${shopCat} : `;
			}
			if (label && label !== 'Main Navigation') {
				if ($el.closest('#shopByCategory').length || label === 'Shop QVC TV' || label === 'Today\'s Special Value & Deals' || label === 'QVC TV' ) {
					label += ' Dropdown';
				}
				moduleLink += `${label} : `;
			}
			if (linkText) {
				moduleLink += `${linkText}`;
			}
			if ($el.closest('[data-component-type]').length && componentType === 'MASTHEAD_POP') {
				moduleLink += ' : HIDDEN';
			}
		} else {
			moduleLink = ele.getAttribute('alt') || ele.getAttribute('title') || ele.innerText || '';
		}
		if (
			!($el.closest('.qComponent').length) &&
			!(originalTerm.length > 0 && componentType === 'PRODUCT_LISTING_LIST' && $elParents.is('.productInfo.productGallery  .galleryItem')) &&
			!(componentType === 'PRODUCT_LISTING_NAV')
		) {
			if ($elParents.is('.searchInputBox')) {
				moduleLink = 'internal search initiated';
			}
			if ($el.closest('.signInGreet').length || $el.closest('.signInAcct').length || $el.parent('.acctGreeting').length) {
				moduleLink = 'my account dropdown';
			}
			if ($elParents.is('.menuHamburger')) {
				if ($el.parents('.menuHamburger').find('.menuNavIcon.open').length) {
					moduleLink = 'menu Hamburger: closed';
				} else {
					moduleLink = 'menu Hamburger: open';
				}
			}
			if ($el.closest('.menuCart').length) {
				moduleLink = 'Shopping Cart';
			}
			if (moduleType === 'TV_PROGRAM_GUIDE') {
				let guideSelectorWeekly = aem_utag_data.page_id === 'PROGRAM GUIDE: WEEKLY';
				channelName = $el.closest('[data-channelname]').length ? ele.closest('[data-channelname]').getAttribute('data-channelname') : '';
				programCode = $el.closest('[data-sourcecode]').length ? ele.closest('[data-sourcecode]').getAttribute('data-sourcecode') : '';
				programName = $el.closest('[data-showname]').length ? ele.closest('[data-showname]').getAttribute('data-showname') : '';
				timeZone = HelperTag.getTimezone();
				if (guideSelectorWeekly) {
					channelName = GlobalPage.getChannelName();
				}
				if ($el.closest('.timeZoneSelection').length) {
					moduleLink = `timezone:${moduleLink}`;
				}
				if ($el.closest('.dropDownDatePicker').length) {
					moduleLink = 'date picker';
				}
				if ($el.closest('.programView .showList').length) {
					let channelName = $el.closest('[data-channelname]').length ? ele.closest('[data-channelname]').getAttribute('data-channelname') : '',
						showName = $el.closest('[data-showname]').length ? ele.closest('[data-showname]').getAttribute('data-showname') : '',
						currentShow = $el.closest('li').hasClass('currentShow'),
						pastShow = $el.closest('li').hasClass('showOver'),
						showStatus = currentShow ? 'current show' : pastShow ? 'past show' : 'future show' ;
					if (guideSelectorWeekly) {
						channelName = GlobalPage.getChannelName();
					}
					moduleLink = `${channelName}:${showStatus}:${showName}:${moduleLink}`;
				}
				if ($elParents.is('.watchShow')) {
					let channelCode = $el.closest('[data-channelcode]').length ? ele.closest('[data-channelcode]').getAttribute('data-channelcode') : '',
						channelName = '',
						showName = $el.closest('[data-showname]').length ? ele.closest('[data-showname]').getAttribute('data-showname') : '',
						showCode = $el.closest('[data-sourcecode]').length ? ele.closest('[data-sourcecode]').getAttribute('data-sourcecode') : '';
					if (typeof aem_utag_data.aemchannels !== 'undefined' && typeof  aem_utag_data.aemchannels[channelCode] !== 'undefined') {
						channelName = aem_utag_data.aemchannels[channelCode].name ? aem_utag_data.aemchannels[channelCode].name : '';
					}
					moduleLink = `${channelName}:current show:${showName}:${showCode}:${moduleLink}`;
				}
			}
			if ($el.closest('.galleryItem').length) {
				moduleLink = $el.closest('.galleryItem').find('.productDesc').text();
			}
		}
		return moduleLink ? moduleLink.toLowerCase() : '';
	},
	/**
	 * 
	 * @returns {string} breakpoint
	 */
	getBreakpoint: function () {
		return GlobalPage.getBreakpoint();
	},
	/**
	 * 
	 * @returns {'portrait' | 'portrait'} breakpoint
	 */
	getOrientation: function () {
		return  GlobalPage.getOrientation();
	},
	/**
	 * Converts all string attributes in an object to lowercase and replaces
	 * underscores with spaces. See https://qurate.atlassian.net/browse/QCAA-189
	 *
	 * @param {Object} obj - The object whose string attributes will be converted.
	 * @returns {Object} The object with its string attributes processed.
	 *
	 * @example
	 *
	 * const input = { name: 'JOHN_DOE', age: 30 };
	 * const result = objAttributesToLowercase(input);
	 * // result is { name: 'john doe', age: 30 }
	 */
	objAttributesToLowercase: (obj) => {
		for (const key in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, key)) {
				const element = obj[key];
				if (typeof element === 'string') {
					obj[key] = element.toLocaleLowerCase().replaceAll('_', ' ');
				}
			}
		}
		return obj;
	}
};

module.exports = _this;