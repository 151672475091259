const _this = {
	getChannelName: () => {
		let channelName = channelCode = '';
		if ($('.channels .activeChannel').data('channelname')) {
			channelName = $('.channels .activeChannel').data('channelname');
		} else if ($('.iroaChannels.desktop select option[selected]').length) {
			channelName = $('.iroaChannels.desktop select option[selected]').text();
		} else if ($('.channelSelector .selectedChannel').data('channelCode')) {
			channelCode = $('.channelSelector .selectedChannel').data('channelCode');
			if (typeof aem_utag_data.aemchannels !== 'undefined' && typeof aem_utag_data.aemchannels[channelCode] !== 'undefined') {
				channelName = aem_utag_data.aemchannels[channelCode].name ? aem_utag_data.aemchannels[channelCode].name : '';
			}
		}
		return channelName;
	}
};
module.exports = _this;
