function createSelector(oSelectors) {
	let sSelector = '';
	$.each(oSelectors, function(n,v) {
		v = typeof v === 'string' ? v : '';
		sSelector += v.length > 0 ? (v + ',') : '';
	});
	return (sSelector = (sSelector.slice(-1) === ',' ? sSelector.substring(0,sSelector.length-1) : sSelector));
}

/**
 * CSS Query Selector Strings
 */
const CSS_SELECTORS = {
		PageContentId: '#pageContent',
		DataModuleType: '[data-module-type]',
		HideModule: '.hideModule'
	},

	/**
	 * List of HTML data attribute names
	 */
	DATA_ATTRIBUTE_NAMES = {
		EventTriggered: 'data-aa_event_triggered'
	},

	/**
	 * List of custom event names
	 */
	CUSTOM_EVENT_NAMES = {
		PrivacyBannerOptIn: 'privacyBannerOptIn',
		AddSpRelated: 'addSpRelatedEvnt'
	},

	DL_LINK_VALUES = {
		CATEGORIES: {
			CHECKOUT: 'CHECKOUT',
			MANAGE: 'MANAGE',
			MANAGE_ACCOUNT: 'MANAGE_ACCOUNT',
			PROMO_CODE: 'PROMO_CODE',
			ITEM_EDIT: 'ITEM_EDIT',
			EMPTY_CART: 'EMPTY_CART',
			MANAGE_PAYMENT: 'MANAGE_PAYMENT',
			QCARD: 'QCARD',
			DIRECT_DEBIT: 'DIRECT_DEBIT',
			SIGN_IN: 'SIGN_IN',
			PROP_65: 'PROP_65',
			WISHLIST: 'WISHLIST',
			MISMATCH: 'MISMATCH',
			SIGNIN: 'SIGNIN',
			OTT_SIGN_IN: 'OTT_SIGN_IN',
			OTT: 'OTT',
			PRODUCTDETAIL: 'PRODUCTDETAIL',
			PRODUCT_CONFIRMATION: 'PRODUCT_CONFIRMATION',
			REFINEMENTS: 'REFINEMENTS'
		},
		TYPES: {
			TYPE_DEFAULT: '',
			TYPE_EVENT: 'event'
		},
		SECTIONS: {
			PERSONAL_DATA: 'PERSONAL_DATA',
			CONTINUE: 'CONTINUE',
			SAVED_ITEMS: 'SAVED_ITEMS',
			TOOLS: 'TOOLS',
			NEW_CUSTOMER: 'NEW_CUSTOMER',
			NPO: 'NPO',
			APPLY_CODE: 'APPLY_CODE',
			REMOVE_CODE: 'REMOVE_CODE',
			ADD_CARD: 'ADD_CARD',
			PROMO_CODE: 'PROMO_CODE',
			CREDIT_CARD: 'CREDIT_CARD',
			GIFT_CARD: 'GIFT_CARD',
			EZPAY: 'EZPAY',
			EDIT_ITEM: 'EDIT_ITEM',
			QCARD: 'QCARD',
			CHANGE_OPTIONS: 'CHANGE_OPTIONS',
			ACCOUNT: 'ACCOUNT',
			OPTIONS:  'OPTIONS',
			EDIT_ORDER: 'EDIT_ORDER',
			ACCOUNT_MANAGEMENT: 'ACCOUNT_MANAGEMENT',
			TERMS: 'TERMS',
			ERROR: 'ERROR',
			ORDER: 'ORDER',
			CREATE_PASSWORD: 'CREATE_PASSWORD',
			TRUTH_IN_LENDING_DISCLOSURE: 'TRUTH_IN_LENDING_DISCLOSURE',
			DIRECT_DEBIT: 'DIRECT_DEBIT',
			EDIT: 'EDIT',
			ADDTO: 'ADDTO',
			CHANGE_PAYMENT: 'CHANGE_PAYMENT',
			CHANGE_OFFER: 'CHANGE_OFFER',
			CANCEL: 'CANCEL',
			CREDITS: 'CREDITS',
			ORDER_DETAIL: 'ORDER_DETAIL',
			UPDATE_EXPIRATION_DATE: 'UPDATE_EXPIRATION_DATE',
			AUTHENTICATE: 'AUTHENTICATE',
			AUTO_DELIVERY: 'AUTO_DELIVERY',
			ADVANCED_ORDER: 'ADVANCED_ORDER',
			WAITLIST: 'WAITLIST',
			ALLSTATE: 'ALLSTATE',
			NEWSLETTER_REGISTRATION: 'NEWSLETTER_REGISTRATION',
			PRODUCT_LISTING_LIST: 'PRODUCT_LISTING_LIST'
		},
		NAMES: {
			CONTINUE: 'CONTINUE',
			UPDATE_CARD: 'UPDATE_CARD',
			PRIV_BANNER_OPT_IN_LINK_NAME: 'ADS:ON|AN_PERS:ON|REW:ON',
			BTN_ADD_TO_CART_OR_WISHLIST: 'ADD_TO_WISHLIST',
			COOKIE_POLICY: 'COOKIE_POLICY',
			PRIVACY: 'PRIVACY',
			TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
			EDIT_CART: 'EDIT_CART',
			VERSAND_IN_ANDERE_LANDER: 'VERSAND_IN_ANDERE_LANDER',
			OPEN: 'OPEN',
			CHECKOUT_TOOLS_VIEW_APM: 'CHECKOUT-_-TOOLS-_-VIEW_ALL_PAYMENT_METHOD',
			SAVE: 'SAVE',
			CANCEL: 'CACEL',
			ADD: 'ADD',
			ADD_CARD: 'ADD_CARD',
			ADD_NEW_CARD: 'ADD_NEW_CARD',
			OFFER_DETAILS: 'OFFER_DETAILS',
			VIEW_ALL_PAYMENT_METHODS: 'VIEW_ALL_PAYMENT_METHODS',
			EDIT_PHONE_NUMBER: 'EDIT_PHONE_NUMBER',
			RETURN_TO_CART: 'RETURN_TO_CART',
			REMOVE_ITEM: 'REMOVE_ITEM',
			SHOPPING: 'SHOPPING',
			VIEW_PRODUCT_PAGE: 'VIEW_PRODUCT_PAGE',
			SAVE_FOR_LATER: 'SAVE_FOR_LATER',
			SHIPPING_INFORMATION: 'SHIPPING_INFORMATION',
			SUBMIT_ORDER: 'SUBMIT_ORDER',
			GIFT: 'GIFT',
			ADD_ALL: 'ADD_ALL',
			REMOVE_ALL: 'REMOVE_ALL',
			EDIT_ADDITIONAL_INFO: 'EDIT_ADDITIONAL_INFO',
			SEE_SIMILAR_ITEMS: 'SEE_SIMILAR_ITEMS',
			EDIT: 'EDIT',
			SAVE_CHANGES: 'SAVE_CHANGES',
			SIGNIN: 'SIGNIN',
			SHOP_DEALS: 'SHOP_DEALS',
			EASY_PAY_BILLING_TERMS: 'EASY_PAY_BILLING_TERMS',
			CAPITALIZED_ACCEPT: 'Accept',
			CAPITALIZED_DECLINE: 'Decline',
			EDIT_ADDRESS: 'EDIT_ADDRESS',
			SETUP: 'SETUP',
			VIEW_EASY_PAY_ORDERS: 'VIEW_EASY_PAY_ORDERS',
			QUALIFICATIONS: 'QUALIFICATIONS',
			VIEW_RECEIPT: 'VIEW_RECEIPT',
			CHECK_STATUS: 'CHECK_STATUS',
			CONTINUE_SHOPPING: 'CONTINUE_SHOPPING',
			OPEN_WARNING: 'OPEN_WARNING',
			BACK: 'BACK',
			AGREE: 'AGREE',
			EMAIL_OPT_IN: 'EMAIL:OPT_IN',
			CART:'CART',
			YES_VIEW_OFFERS: 'YES_VIEW_OFFERS',
			NO_CONTINUE: 'NO_CONTINUE',
			EDIT_SHIPPING_ADDRESS: 'EDIT_SHIPPING_ADDRESS',
			APPLY: 'APPLY',
			REMOVE: 'REMOVE',
			CONTINUE_SELF:'CONTINUE:SELF',
			CONTINUE_OTHER_USER: 'CONTINUE:OTHER_USER',
			CONTINUE_SELF_NO_PASSWORD: 'CONTINUE:SELF_NO_PASSWORD',
			DELETE: 'DELETE',
			ALL_OPEN_ORDERS: 'ALL_OPEN_ORDERS',
			RESET_PASSWORD: 'RESET_PASSWORD',
			LINK_DEVICE: 'LINK_DEVICE',
			SUBMIT_RESET_EMAIL_REQUEST: 'SUBMIT_RESET_EMAIL_REQUEST',
			CONTINUE_WITHOUT_PASSWORD: 'CONTINUE_WITHOUT_PASSWORD',
			TRUEFIT: 'TRUEFIT',
			FORCED_PASSWORD_RESET: 'FORCED_PASSWORD_RESET',
			YES: 'YES',
			NO_ADD_SINGLE_SHIPMENT: 'NO_ADD_SINGLE_SHIPMENT',
			NO: 'NO',
			CONTINUE_TO_CART_ACCEPTED: 'CONTINUE_TO_CART:ACCEPTED',
			CONTINUE_TO_CART_DECLINED: 'CONTINUE_TO_CART:DECLINED',
			SUBMIT: 'SUBMIT',
			OPEN_FILTERS: 'OPEN_FILTERS'
		},
	},

	O_SELECTORS = {
		prod : {
			imgThumbs : '.thumbcell',
			imgEnlargeBtn : '.productPhoto .photoWrapper a.enlarge',
			imgMobileScrollIndicators : '.productStage .carousel-indicators li',
			socialButtons: '.btnEMailAFriend, span[data-pin-log="button_pinit"]',
			sizeGuide : '.buyBoxSelectSize a.selectLink',
			ezPayOffers : '.easyPayDetails a[href*="easypay"]',
			colorSizeOptions : '.swatch',
			qtySelector : '.buyBoxQuantity .stepDown, .buyBoxQuantity .stepUp',
			singleShipmentOrAutoDelivery : '.buyBoxSelectDelivery .deliveryOption',
			creditTerms : '.easyPayInfo input.QPayOpt',
			squareTradeAdd : '.pdSquareTrade .btn',
			squareTradeInfo : '.pdSquareTrade .infoIcon',
			channelSelector : '.channelSelector .btn',
			moreInfoAccordions : '.pdLongDesc .accordionTitle, .accordionFileMenu a, .togglePreview a',
			recommendSize : '#fitsme_launcher',
			special : {
				imgHover : '#s7Flyout',
			},
			saveCancelMYS : '.buyBoxButtons .btn-primary, .buyBoxButtons .btn-secondary',
			retailValue: '.rvPrice',
			reviewsMYS: '.bv_button_buttonMinimalist, .bv_stars_button_container',

		},
		bv : {
			reviewRead : '.pdShortDesc #BVRRSummaryContainer #BVRRRatingOverall_Rating_Summary_1',
			writeReview : '#BVRRRatingSummaryLinkWriteID a',
			writeReviewNew : '#BVRRRatingSummaryLinkWriteFirstID a',
			writeReviewTop : '.bv_cta_button .bv_ffs_hover',
			viewAllQandA : '#BVQASummaryBoxViewQuestionsID a, .BVQAPageTabLink',
			moreReviews : '.BVRRNextPage a',
			previousReviews : '.BVRRPreviousPage a',
			prevReviewsNumb : '.BVRRPageNumber a',
			bvQuestion : '.BVQAQuestionSummary a',
			bvAnswerQuestion : '.BVQAAnswerQuestion a',
			bvEnableSearch : '.BVQASearchFormTextInput',
			bvSearch : '.BVQASearchFormSubmitButton',
			sortReviews : '.BVRRDisplayContentSelect, .BVQAToolbarSortSelect',
			bvAskQuestion : '.BVQAAskQuestionTop a, .BVQANoQuestions a, #BVQASummaryBoxAskFirstQuestionID a',
			helpfulReviewY : '.BVRRContentReview .BVDI_FVPositive a',
			helpfulReviewN : '.BVRRContentReview .BVDI_FVNegative a',
			reportReview : '.BVRRContentReview .BVDI_FVReportLink a',
			helpfulAnswerY : '.BVAnswerData .BVDI_FVPositive a',
			helpfulAnswerN : '.BVAnswerData .BVDI_FVNegative a',
			reportAnswer : '.BVQAContentData .BVDI_FVReportLink a',
			bvPostedBy : '.BVRRNickname',
			communityAddLinks : '.BVQAGuidelines a'
		}
	},
	S_SELECTOR = createSelector(O_SELECTORS.prod),
	S_SELECTOR_BV = createSelector(O_SELECTORS.bv);

module.exports = {
	CSS_SELECTORS,
	DATA_ATTRIBUTE_NAMES,
	CUSTOM_EVENT_NAMES,
	DL_LINK_VALUES,
	O_SELECTORS,
	S_SELECTOR,
	S_SELECTOR_BV
};
