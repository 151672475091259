const AaSitePromoTagModule = require('../aa-site-promo');
const _this = {
	addOffCanvasTagListener: function (pageLoadCallback = () => {}) {
		$(document).on("click", ".addToCartOffCanvasSidebar .buyBoxSelect li", function (event) {
			if (!event.target) {
				throw new Error('Expected an event target for clicks on `.addToCartOffCanvasSidebar .buyBoxSelect li` but got none');
			}
			_this.offCanvasClickHandler($(event.target).find(".swatch")[0], pageLoadCallback);
		});
		$(document).on("click", ".addToCartOffCanvasSidebar .buyBoxQuantity .stepUp,.addToCartOffCanvasSidebar .buyBoxQuantity .stepDown,.addToCartOffCanvasSidebar .productDetails,.addToCartOffCanvasSidebar .btnViewCart", function (event) {
			if (!event.target) {
				throw new Error('Expected an event target for clicks on `.addToCartOffCanvasSidebar .buyBoxQuantity .stepUp,.addToCartOffCanvasSidebar .buyBoxQuantity .stepDown,.addToCartOffCanvasSidebar .productDetails,.addToCartOffCanvasSidebar .btnViewCart` but got none');
			}
			_this.offCanvasClickHandler($(event.target), pageLoadCallback);
		});
		$(document).on("click", ".btnAddToCartCanvas", function (event) {
			if (!event.currentTarget) {
				throw new Error('Expected an event target for clicks on `.btnAddToCartCanvas` but got none');
			}
			let $btn = $(event.currentTarget),
				btnLink = $btn.data("url"),
				skn = _this.getSkn(btnLink);
			adobeDataLayerObject.pageInfo.pageName = `off-canvas: add to cart [${skn}]`;
			adobeDataLayerObject.pageInfo.pageCategory = `OC[${skn}]`;
			pageLoadCallback();
			_this.offCanvasClickHandler($(event.currentTarget), pageLoadCallback);
		});
		$(document).on("click", ".backgroundGreyOut", (event) => _this.closeCallback(event, pageLoadCallback));
		$(document).on("keydown", (event) => _this.closeCallback(event, pageLoadCallback));
		$(document).on("click", ".addToCartOffCanvasSidebar .clBtnCanvas", function () {
			if (aem_utag_data.page_id) {
				adobeDataLayerObject.pageInfo.pageName = aem_utag_data.page_id.toLowerCase();
			}
			pageLoadCallback();
		});
		$(document).on("click", ".addToCartOffCanvasSidebar .btnAddToCart,.addToCartOffCanvasSidebar .btnSpeedBuy", function (event) {
			if (!event.target) {
				throw new Error('Expected an event target for clicks on `.addToCartOffCanvasSidebar .btnAddToCart,.addToCartOffCanvasSidebar .btnSpeedBuy` but got none');
			}
			if (typeof productSelected === "function" && productSelected(false)) {
				_this.offCanvasClickHandler($(event.target), pageLoadCallback);
			}
		});
	},
	closeCallback: function (e, pageLoadCallback) {
		if (e.target === e.currentTarget || e.keyCode === 27) {
			if (aem_utag_data.page_id) {
				adobeDataLayerObject.pageInfo.pageName = aem_utag_data.page_id.toLowerCase();
			}
			pageLoadCallback();
		}
	},
	offCanvasClickHandler: function (ele, pageLoadCallback = () => {}) {
		let $el = $(ele),
			$elParent = $el.parent(),
			tag = "";

		if ($el.hasClass("swatch")) {
			let sColorOrSize = $elParent.hasClass("selectColor") || $elParent.parents(".buyBoxColorList").length > 0 ? "color" : $elParent.hasClass("selectSize") || $elParent.parents(".buyBoxSizeList").length > 0 ? "size" : "";
			tag = `off_canvas-_-make_selection-_-${sColorOrSize}`;
		} else if ($elParent.hasClass("stepWrapper")) {
			tag = `off_canvas-_-make_selection-_-quantity`;
		} else if ($el.hasClass("btnAddToCartCanvas")) {
			if ($el.closest(".wishlistCarousel").length) {
				let header = $el.closest('[data-module-type]').find('.headerText h2'),
					sHeader = _this.getSubHeader(header);
				tag = `USER_HISTORY-_-${sHeader}-_-open_otc_options`;
			}
			else if ($el.closest(".onAirActualData").length) {
				let position = $el.parent().index() + 1;
				header = $el.closest('[data-component-type]').data('component-type');
				tag = `ON_AIR:ONAIRMODULE-_-${header}:${position}-_-open_otc_options`;
			}
			else if ($el.closest(".dynProductRecommendationsCarousel").length) {
				let header = $el.closest('[data-module-type]').find('.headerText h2'),
					sHeader = _this.getSubHeader(header);
				tag = `RECOMMENDATIONS-_-${sHeader}-_-open_otc_options`;
			}
			else if ($el.closest('[data-component-type="CORE_PRODUCT_STRIP"]').length > 0) {
				let compType = 'PRODUCT',
					position = $el.closest("[data-component-type]").index() + 1,
					compCont = $el.closest('[data-component-type="CORE_PRODUCT_STRIP"]').find('.containerStrip'),
					compStyle = compCont.hasClass('carousel') ? 'CAROUSEL:' : 'STACKED:',
					compSource = $el.closest('[data-product-type]').data('product-type'),
					moduleName = $el.closest('[data-module-feature-name]').data('module-feature-name');
				tag = `${moduleName}:${compSource}-_-${compStyle}${compType}:${position}-_-open_otc_options`;
			}
			else if ($el.closest(".iroaListItems").length) {
				tag = `Product_listing-_-product_tile-_-open_otc_options`;
			}
		} else if ($el.hasClass("productDetails")) {
			tag = `off_canvas-_-more_info-_-view_full_details`;
		} else if ($el.hasClass("btnAddToCart")) {
			tag = `off_canvas-_-add_to-_-cart`;
			$(document).on("canvasRecommendRenderedForTagging", function () {
				adobeDataLayerObject.pageInfo.pageName = `off-canvas: cart confirmation`;
				adobeDataLayerObject.pageInfo.pageCategory = `occart`;
				pageLoadCallback();
			});
		} else if ($el.hasClass("btnSpeedBuy")) {
			tag = `off_canvas-_-add_to-_-speedbuy`;
		} else if ($el.hasClass("btnViewCart")) {
			tag = `off_canvas-_-cart-_-open_cart`;
		}
		if (typeof tag !== "undefined" && tag !== "" && window.location.search.indexOf("tealiumNoUtagLink=1") === -1 && tag.includes("-_-") && tag.split("-_-").length > 2) {
			AaSitePromoTagModule.tagArray.push(tag);
			AaSitePromoTagModule.captureSitePromoData($el, { linkType: ele.localName, linkCategory: tag.split("-_-")[0], linkSection: tag.split("-_-")[1], linkName: tag.split("-_-")[2] });
		}
	},
	getSubHeader: function (header) {
		return header.length > 0 ? header.text().toUpperCase().replace(/\t|\n/g, '').trim().replace(/\s/g, '_') : 'NO_CAROUSEL_TYPE';
	},
	getSkn: function (btnLink) {
		let splitUrl = btnLink.split('.canvas.'),
			productNumber = splitUrl[1].split('.html');
		return productNumber[0];
	},
	initOffCanvas: function (pageLoadCallback = () => {}) {
		$(function () {
			_this.addOffCanvasTagListener(pageLoadCallback);
		});
	}
};

module.exports = _this;
