const AaSitePromoHelper = require('../aa-site-promo.helper');

/**
 * Propery of "adobeDataLayer" containing data related to "links"
 * @typedef { {linkCategory: string; linkSection: string; linkName: string; linkType: string;} } DataLayerLinks
 */

/**
 * Propery of "adobeDataLayer" containing data related to "modules"
 * @typedef { {modulePosition: string; moduleType: string; moduleLink: string; componentType: string;} } DataLayerModules
 */

/**
 * Propery of "adobeDataLayer" containing data related to "utils"
 * @typedef { {breakpoint: string; orientation: string;} } DataLayerUtils
 */

/**
 * Propery of "adobeDataLayer" containing data related to "pageInfo"
 * @typedef { {siteSection: string;} } DataLayerPageInfo
 */

/**
 * An object with all data needed for pushing "site promotions" information to Adobe's data-layer
 * @typedef { {links: DataLayerLinks; modules: DataLayerModules; utils: DataLayerUtils; pageInfo: DataLayerPageInfo; aaSp: string;} } SitePromotionData
 */

module.exports = {
	default: this,
	/**
	 * 
	 * @param {JQuery<HTMLElement>} element
	 * @returns {DataLayerModules}
	 */
	buildModuleRelatedData: function (element) {
		const modulePosition = AaSitePromoHelper.getModulePosition(element),
			moduleType = AaSitePromoHelper.getModuleType(element),
			moduleLink = AaSitePromoHelper.getModuleLink(element),
			componentType = AaSitePromoHelper.getComponentType(element);
		return {modulePosition, moduleType, moduleLink, componentType};
	},
	/**
	 * 
	 * @returns {DataLayerUtils}
	 */
	buildUtilsRelatedData: function () {
		const breakpoint = AaSitePromoHelper.getBreakpoint(),
			orientation = AaSitePromoHelper.getOrientation();
		return {breakpoint, orientation};
	},
	/**
	 * 
	 * @returns {DataLayerPageInfo}
	 */
	buildPageInfoRelatedData: function () {
		let siteSection = 'undefined';
		if (aem_utag_data && aem_utag_data.page_type) {
			siteSection = aem_utag_data.page_type;
		} else if (typeof qVars !== 'undefined') {
			siteSection = qVars.pageType || '';
		}
		return {siteSection};
	},
	/**
	 * 
	 * @param {DataLayerLinks} linkData 
	 * @returns {string}
	 */
	buildAaspRelatedData: function (linkData) {
		return `${linkData.linkCategory}-_-${linkData.linkSection}-_-${linkData.linkName}`;
	}
};
