const CartPage = require('../aa-cart-page');
const HelperTag = require('../aa-helper');
const _this = {
	getPageName: function () {
		if (location.href.indexOf('/checkout/account-locked') > -1) {
			adobeDataLayerObject.pageInfo.pageName = 'SIGN IN: ERROR: ACCOUNT SUSPENDED';
		} else if (location.href.indexOf('/checkout/equickscreen-app') > -1) {
			adobeDataLayerObject.pageInfo.pageName = 'APPLY FOR QCARD - QUICKSCREEN';
		}
		if (adobeDataLayerObject.pageInfo.pageName) {
			adobeDataLayerObject.pageInfo.pageName = adobeDataLayerObject.pageInfo.pageName.toLowerCase();
		}
	},
	getPageCategory: function () {
		let pCat = window.localStorage.getItem('promoCat');
		let errRespCode = '';
		if (location.href.indexOf('account-locked.cvv') > -1) {
			errRespCode = '10006';
		}
		if (location.href.indexOf('account-locked.login') > -1) {
			errRespCode = '10005';
		}
		if (location.href.indexOf('account-locked.qa') > -1) {
			errRespCode = '10007';
		}
		if (location.href.indexOf('account-locked.other') > -1) {
			errRespCode = '10008';
		}
		adobeDataLayerObject.pageInfo.pageCategory = aem_utag_data.page_type || '';
		if (location.href.indexOf('account-locked') > -1 && pCat === null) {
			adobeDataLayerObject.pageInfo.pageCategory = 'COSIGN' + errRespCode;
		} else if (location.href.indexOf('account-locked') > -1 && pCat === 'SPEEDBUY_CHECKOUT') {
			adobeDataLayerObject.pageInfo.pageCategory = 'PPSIGN' + errRespCode;
		} else if (location.href.indexOf('account-locked') > -1 && pCat === 'PAYPAL_CHECKOUT') {
			adobeDataLayerObject.pageInfo.pageCategory = 'SPEEDSIGN' + errRespCode;
		}
	},
	setOrderConfirmationPageAttr: function () {
		aem_utag_data.prodItemCount = 0;
		aem_utag_data.shippingListCount = 0;
		let tOrder = aem_utag_data.trans_orders && aem_utag_data.trans_orders.length ? aem_utag_data.trans_orders[0] : [];
		let orderedProdTxnData = _this.getOrderedProdTxnData(tOrder);
		adobeDataLayerObject.ecommerce.product = orderedProdTxnData.orderedProductData;
		adobeDataLayerObject.ecommerce.txn = orderedProdTxnData.cartTxn;
	},
	setMultiOrdersData: function () {
		aem_utag_data.trans_orders.forEach((tOrder, orderCount) => {
			if (tOrder && orderCount > 0) {
				let aaDataLayerObject = {
					eventInfo: '',
					event: 'orderSubmit',
					ecommerce: {
						product: [],
						txn: {}
					},
					user: {
						memberNumber: qCookie.get('qvcmn') || ''
					}
				};
				let orderedProdTxnData = _this.getOrderedProdTxnData(tOrder);
				aaDataLayerObject.ecommerce.product = orderedProdTxnData.orderedProductData;
				aaDataLayerObject.ecommerce.txn = orderedProdTxnData.cartTxn;
				aaDataLayerObject.utils = HelperTag.getUtilsData();
				window.adobeDataLayer.push(aaDataLayerObject);
			}
		});
	},
	getOrderedProducts: function (productList, cartProdData) {
		let orderedProductData = [];
		if (productList && productList.product_id && productList.product_id.length) {
			productList.product_id.forEach((productID, index) => {
				let cartProd = cartProdData[aem_utag_data.prodItemCount],
					prodID = productID,
					name = productList.product_name[index] ? productList.product_name[index].toLowerCase() : '',
					brandID = productList.brand_id[index] || '',
					brandName = productList.brand_name[index] ? productList.brand_name[index].toLowerCase() : '',
					availability = productList.product_availability[index] || '',
					creditTermCode = productList.product_credit_terms_code[index] ? productList.product_credit_terms_code[index].toLowerCase() : '',
					specialPriceCode = productList.product_special_price_code[index] ? productList.product_special_price_code[index].toLowerCase() : '',
					referringProductID = productList.product_referring_item[index] || '',
					basePrice = productList.product_price[index] ? HelperTag.aaNumFormat(productList.product_price[index]) : '0',
					quantity = productList.product_quantity[index] || '0',
					colorCode = productList.product_color[index] || '',
					sizeID = productList.product_size[index] || '',
					variantID = prodID,
					shippingOptionsCode = productList.product_shipping_and_handling[index] ? productList.product_shipping_and_handling[index] : '',
					shippingOption = productList.product_shipping_and_handling[index] ? HelperTag.shippingOptions(productList.product_shipping_and_handling[index]) : '',
					rating = cartProd.rating || '',
					reviewCount = cartProd.reviewCount || '',
					sizeGuideOffered = cartProd.sizeGuideOffered || 'n',
					onAirItem = cartProd.onAirItem || '',
					isExtendedWarranty = cartProd.isExtendedWarranty || 'n',
					minPrice = cartProd.minPrice || '',
					maxPrice = cartProd.maxPrice || '',
					shippingHandlingCost = cartProd.shippingHandlingCost || '0',
					totalProdPrice = cartProd.totalProdPrice || '0',
					tax = cartProd.tax || '0',
					promoDisc = cartProd.promoDisc || '0',
					combinationRatios = cartProd.combinationRatios || '',
					deliveryDate = cartProd.deliveryDate || '',
					easyPaymentNo = cartProd.easyPaymentNo || '',
					deliveryOption = cartProd.deliveryOption || '',
					revenue = Number(basePrice) && Number(quantity) ? HelperTag.aaNumFormat(Number(basePrice) * Number(quantity)) : '0',
					shoppingCategory = productList.product_shopping_category[index];
				productID = prodID.includes(' ') ? prodID.split(' ')[0] : prodID;
				if (availability !== 'instock' && availability !== 'waitlist' && availability !== 'advancedorder' && availability !== 'soldout') {
					availability = cartProd.availability || '';
				}
				orderedProductData.push({
					productID,
					name,
					brandID,
					brandName,
					availability,
					rating,
					reviewCount,
					creditTermCode,
					sizeGuideOffered,
					onAirItem,
					specialPriceCode,
					isExtendedWarranty,
					referringProductID,
					minPrice,
					maxPrice,
					shippingHandlingCost,
					basePrice,
					totalProdPrice,
					tax,
					promoDisc,
					quantity,
					colorCode,
					sizeID,
					variantID,
					combinationRatios,
					prodID,
					deliveryDate,
					shippingOptionsCode,
					shippingOption,
					easyPaymentNo,
					deliveryOption,
					revenue,
					shoppingCategory
				});
				aem_utag_data.prodItemCount += 1;
			});
			return orderedProductData;
		}
	},
	getOrderedProdTxnData: function (tOrder) {
		let orderedProductData = [];
		let cartTxn = {
			cartID: '',
			cartType: '',
			checkoutPath: '',
			shippingHandlingTotal: '',
			lineItems: '',
			totalItemCount: '',
			subTotal: '',
			tax: '',
			total: '',
			paymentType: '',
			orderID: '',
			couponDiscountAmount: '',
			couponCode: ''
		};
		let cartProdTxnData = CartPage.getCartProdTxnData();
		if (tOrder) {
			let productList = tOrder.products_list;
			if (cartProdTxnData.productData.length && cartProdTxnData.cartTxn) {
				orderedProductData = _this.getOrderedProducts(productList, cartProdTxnData.productData);
				cartTxn.cartID = cartProdTxnData.cartTxn.cartID;
				cartTxn.cartType = cartProdTxnData.cartTxn.cartType;
				cartTxn.checkoutPath = cartProdTxnData.cartTxn.checkoutPath;
				cartTxn.paymentType = cartProdTxnData.cartTxn.paymentType;
				cartTxn.couponCode = cartProdTxnData.cartTxn.couponCode;
				cartTxn.couponDiscountAmount = cartProdTxnData.cartTxn.couponDiscountAmount;
				if (tOrder.payment_method_type) {
					cartTxn.paymentType = tOrder.payment_method_type.toLowerCase();
				}
				cartTxn.totalItemCount = tOrder.total_products;
				if (cartVars.cart.shippingList && cartVars.cart.shippingList.length && typeof cartVars.cart.shippingList[aem_utag_data.shippingListCount].shippingCost !== 'undefined') {
					cartTxn.shippingHandlingTotal = HelperTag.aaNumFormat(cartVars.cart.shippingList[aem_utag_data.shippingListCount].shippingCost);
				}
				if (orderedProductData.length) {
					cartTxn.lineItems = orderedProductData.length;
					let totalProdPrice = tax = 0;
					orderedProductData.forEach(pData => {
						if (pData.totalProdPrice) {
							totalProdPrice += Number(pData.totalProdPrice);
							tax += Number(pData.tax);
						}
					});
					if (HelperTag.additionalCostsEnabled()) {
						cartTxn.shippingHandlingTotal =  cartProdTxnData.cartTxn.shippingHandlingCost ? cartProdTxnData.cartTxn.shippingHandlingCost : '';
						totalProdPrice = cartProdTxnData.cartTxn.shippingHandlingCost ? Number(totalProdPrice) + Number(cartProdTxnData.cartTxn.shippingHandlingCost) : totalProdPrice;
					}
					cartTxn.total = HelperTag.aaNumFormat(totalProdPrice);
					cartTxn.tax = HelperTag.aaNumFormat(tax);
					cartTxn.subTotal = HelperTag.aaNumFormat((Number(totalProdPrice) - Number(tax)));
				}
				cartTxn.orderID = _this.getOrderID({tOrder, cartID: cartTxn.cartID});
			}
			aem_utag_data.shippingListCount += 1;
		}
		return { orderedProductData, cartTxn };
	},
	getOrderID: function (orderData) {
		let orderID = orderData.tOrder.order_id;
		if (typeof orderID !== 'string') { //order number doesn't exist
			if (qVars.feature.useCartIdForShop9 && typeof orderData.cartID === 'string') {
				// In DE we don't have the order number generated right away.
				// use the cart id for order number if it's missing.
				orderID = orderData.cartID;
			} else { //generate a Downtime Id
				orderID = _this.createOrderId('DT');
			}
		} else if (HelperTag.additionalCostsEnabled() && typeof orderData.cartID === 'string') {
			// In JP we have same order number generated for all the order placed on a day.
			// use the cart id instead of order number.
			orderID = orderData.cartID;
		} else if ((orderID.length < 8) && !(HelperTag.additionalCostsEnabled())) {
			// US orders are 10 digits and don't need prefix.
			//UK order numbers are only 7 digits (or less if leading zeros are missing) and need date prefix to avoid duplication
			orderID = _this.addDatePrefix(orderID);
		}
		return orderID;
	},
	createOrderId: function(type){
		let custId = qCookie.get('qvcmn') || '';
		return type + custId + Math.floor(Math.random() * 10000000000);
	},
	addDatePrefix: function(id){
		if (id.length < 7){//if order number is less than 7 digits, pad with 0s
			qString.padStart(id,0, 7);
		}
		return dateFormat(new Date(), 'yyyymm') + id;
	},
};

module.exports = _this;
