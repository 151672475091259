const AaInit = require('./aa-init/index');

let adobeAnalyticsFired = window['adobeAnalyticsFired'] || false;

if (utag_data.page_type === 'product-listing-dynamic-search'){
	AaInit.delayPageView = true;
}
// Executes adobe analytics data capturing before HTML-Document is completely loaded and DOM is ready
if (!AaInit.delayPageView) {
	$(document).on('preDomReady', function () {
		adobeAnalyticsFired = true;
		AaInit.initAA();
	});
}
// Executes adobe analytics data capturing once HTML-Document is completely loaded and DOM is ready
$(function () {
	if (!adobeAnalyticsFired && !AaInit.delayPageView) {
		AaInit.initAA();
	}
	$(document).on('virtualPageView searchComplete', function(){ //on virtual page view, re-fire tags
		AaInit.initAA();
	});
});

// these are temporary placeholders to avoid javascript errors from Target campaigns & Tealium as we turn off Acoustic
window.cmCreateManualLinkClickTag = function(){};
window.cmCreateConversionEventTag = function(){};
window.cmCreateElementTag = function(){};
