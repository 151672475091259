/**
 * Initializes and sets up data layer constants within the specified scope.
 * This function prepares various data structures for use in analytics and tracking, such as
 * transaction data and page information, specifically tailored for Adobe Experience Manager (AEM) pages.
 *
 * @param {object} [scope=window] - The global scope to which the data layers are attached. Defaults to `window` if no other scope is provided.
 * 
 * */
function setUpDataLayerConstnats(scope = window) {
	//Creating aem_utag_data that can utilize it for tagging
	scope['aem_utag_data'] = {};

	//Check if adobe analytics is triggred or not.
	scope['adobeAnalyticsFired'] = false;

	//Capture transaction data in general
	scope['txnData'] = {
		cartID: '',
		cartType: '',
		checkoutPath: '',
		subTotal: '',
		tax: '',
		total: '',
		couponDiscountAmount: '',
		couponCode: '',
		paymentType: '',
	};

	//Creating regular page load event informention on pageLoad event for all the pages in AEM
	scope['adobeDataLayerObject'] = {
		event: '',
		eventInfo: '',
		pageInfo: {
			pageName: '',
			pageCategory: '',
			siteSection: '',
			breadCrumb: '',
			currentUrl: '',
			moduleCount: '',
			superModuleCount: '',
			productRefinement: '',
			productRefinementCnt: '',
			nCode: '',
			pagination: '',
			shoppingCategory: '',
			listingSize: '',
		},
		user: {
			globalUserID: '',
			quid: '',
			authStatus: '',
			uattr: {
				customerSegmentCode: '',
				customerType: '',
				firstOrderDate: '',
				lastOrderDate: '',
				merchandisingPreferenceCode: '',
				multiBuyStrataCode: '',
				qCardIndicatorCode: ''
			}
		},
		utils: {
			currency: '',
			country: '',
			language: '',
			platform: '',
			breakPoint: '',
			orientation: '',
			timeStamp: ''
		},
		ecommerce: {
			product: [],
			txn: txnData,
		},
		search: {
			category: '',
			type: '',
			term: '',
			resultCount: '',
			originalTerm: '',
		},
	};
}


/**
 * Configures the adobeDataLayer to process push operations with a delay using an internal queue.
 * 
 * This function overrides the existing `push` method to enqueue all incoming items and processes them one by one 
 * with a specified delay between each operation. The original `push` method is preserved and used during processing.
 * This has been implemented to fix issues found during implementation of "sitePromo" where multiple events ware being pushed
 * too close in time between each other and adobe launch was not picking them up correctly. 
 * https://qurate.atlassian.net/browse/QCAA-213
 * 
 * @param {number} delayMs - The delay in milliseconds to wait between processing each item in the queue.
 */
function setupAdobeDataLayerPushWithQueue(delayMs) {
	const originalPush = window.adobeDataLayer.push.bind(window.adobeDataLayer);
	window.adobeDataLayer.queue = [];
	window.adobeDataLayer.isProcessingQueue = false;

	window.adobeDataLayer.push = (items) => {
		if (typeof items === 'function') {
			return void 0;
		}
		items['forEach'] !== undefined ?
			items.forEach(item => window.adobeDataLayer.queue.push(item)) : window.adobeDataLayer.queue.push(items);
		if (!window.adobeDataLayer.isProcessingQueue) {
			window.adobeDataLayer.processQueue();
		}
	};

	window.adobeDataLayer.processQueue = () => {
		if (window.adobeDataLayer.queue.length === 0) {
			window.adobeDataLayer.isProcessingQueue = false;
			return;
		}
		window.adobeDataLayer.isProcessingQueue = true;
		const item = window.adobeDataLayer.queue.shift();
		originalPush(item);

		setTimeout(() => {
			window.adobeDataLayer.processQueue();
		}, delayMs);
	};
	window.adobeDataLayer.flushQueue = (flushDelayMS) => {
		window.adobeDataLayer.queue.forEach((queueItem, index) => {
			if (index === 0) {
				originalPush(queueItem);
			} else {
				setTimeout(() => originalPush(queueItem), flushDelayMS);
			}
		});
	};
	handleAnalyticsQueueBeforeUnload();
}

/**
 * Ensures crucial analytics events, initiated from user interactions such 
 * as site searching and browsing on QVC, are processed before the page unloads.
 * This function is crucial in preventing data loss during navigations.
 * See: https://qurate.atlassian.net/browse/QCAA-155
 *
 * @function handleAnalyticsQueueBeforeUnload
 * @returns {void} This function does not return a value.
 * @throws {Error} Throws an error if there are more than 3 unsent events in the queue.
 *
 * @description
 * This function sets up an event listener for the `beforeunload` event to 
 * ensure that analytics events queued in the `window.adobeDataLayer` are 
 * processed before the page reloads or exits. With the addition of the 
 * `flushQueue` method, queued events are flushed with a specific delay 
 * (`flushDelayMS`), allowing each item in the queue to be pushed using 
 * calculated timing intervals. This change, aligned with issue QCAA-524, 
 * addresses the decreased Interaction to Next Paint by optimising how events 
 * trigger.
 *
 * A warning is logged as an error if unexpected conditions populate the queue 
 * with more than 3 items. If the queue holds 1 to 3 items, the function flushes 
 * them utilising the `flushQueue` method’s scheduling, preventing loss during 
 * unloading phases.
 * 
 * Previous strategies to block the main thread have been replaced to enhance 
 * UI responsiveness and ensure data integrity in critical navigation edge cases.
 *
 * @example
 * // Use this function to ensure analytics events are processed on page unload
 * handleAnalyticsQueueBeforeUnload();
 */
function handleAnalyticsQueueBeforeUnload() {
	window.addEventListener('beforeunload', function () {
		const queue = window.adobeDataLayer.queue;
		const queueLength = queue.length;

		if (queueLength > 3) {
			throw new Error(`Something must have gone wrong. There are more than 3 events unsent in the "adobeDataLayer"`);
		}

		if (queueLength > 0 && window.adobeDataLayer.flushQueue) {
			window.adobeDataLayer.flushQueue(0);
		}
	});
}

module.exports = {
	default: this,
	setUpDataLayer: () => {
		setUpDataLayerConstnats();
		window.adobeDataLayer.push((dl) => {
			if (!dl) {
				throw new Error(`Expected adobeDatalayer to be initialized at this point.`);
			}
			setTimeout(() => {
				setupAdobeDataLayerPushWithQueue(1000);
			}, 0);
		});
	}
};
