const shippingOptionsMap = new Map([
	['GR', 'standard'],
	['2D', 'express'],
	['1D', 'premium'],
	['UG', 'ups ground'],
	['PM', 'priority us mail'],
	['UB', 'ups express (blue)'],
	['UR', 'ups premium (red)']
]);

const translateATSMap = new Map([
	['Y', 'instock'],
	['W', 'waitlist'],
	['A', 'advancedorder'],
	['N', 'soldout']
]);

module.exports = {
	default: this,
	shippingOptionsMap,
	translateATSMap
};