const Helper = require('../aa-helper');
const CartPage = require('../aa-cart-page');
const MyAccount = require('../aa-my-account');
const ProductPage = require('../aa-product-page');
const CheckoutPage = require('../aa-checkout-page');
const SearchPage = require('../aa-search-page');
const GlobalPageHelper = require('./aa-global-page.helper');

const _this = {
	getPageName: function () {
		Helper.setChannelShopCats();
		let pageType = aem_utag_data.page_type || '';
		if (aem_utag_data.page_id) {
			adobeDataLayerObject.pageInfo.pageName = aem_utag_data.page_id.toLowerCase();
		} else {
			if (typeof qVars !== 'undefined' && qVars.page) {
				let pageNameData = qVars.page.replace(/-{1,}/g, ' ');
				adobeDataLayerObject.pageInfo.pageName = pageType + ': ' + pageNameData;
			} else {
				adobeDataLayerObject.pageInfo.pageName = pageType;
			}
			if (typeof qVars !== 'undefined' && qVars.clientLibs.indexOf('myaccount') > -1) {
				MyAccount.getPageName();
				return;
			}
		}
		let productName = aem_utag_data.product_name || '';
		let productID = aem_utag_data.product_id || '';
		switch (pageType) {
		case 'checkout':
			if (!(aem_utag_data.page_id)) {
				CheckoutPage.getPageName();
			} else if (typeof qVars !== 'undefined' && qVars.page) {
				switch (qVars.page) {
				case 'payment-options':
				case 'order-review':
				case 'payment-offers':
				case 'shipping-options':
					Helper.setProdTxnData();
					break;
				}
			}
			break;
		case 'product':
			ProductPage.getPageName();
			if ($('.divGroupSnippet').length === 0) {
				ProductPage.setProductViewAttr();
			}
			break;
		case 'group':
			adobeDataLayerObject.pageInfo.pageName = 'GROUP: ' + productName + ' (' + productID + ')';
			break;
		case 'mys':
			adobeDataLayerObject.pageInfo.pageName = 'MAKE YOUR SELECTION: ' + productName + ' (' + productID + ')';
			break;
		case 'iroa':
			adobeDataLayerObject.pageInfo.pageName = adobeDataLayerObject.pageInfo.pageName.replace(new RegExp(aem_utag_data.channel_name + '$', 'i'), Helper.getChannelSc(aem_utag_data.channel_name.toUpperCase()));
			break;
		case 'shoplive':
			adobeDataLayerObject.pageInfo.pageName = adobeDataLayerObject.pageInfo.pageName === 'shoplive' ? 'VIDEO STREAMING ' + aem_utag_data.aemchannels[liveDefaultChannel.toUpperCase()].sc : adobeDataLayerObject.pageInfo.pageName;
			break;
		case 'cart':
			CartPage.setCartViewAttr();
			break;
		case 'order':
			if (qVars.page === 'order-confirmation') {
				CheckoutPage.setOrderConfirmationPageAttr();
			}
			break;
		default:
			if (aem_utag_data.page_id) {
				adobeDataLayerObject.pageInfo.pageName = aem_utag_data.page_id.toLowerCase();
			}
			break;
		}
		if (adobeDataLayerObject.pageInfo.pageName) {
			adobeDataLayerObject.pageInfo.pageName = adobeDataLayerObject.pageInfo.pageName.toLowerCase();
		}
	},
	getPageCategory: function () {
		let pageCategory = aem_utag_data.category_id || '';
		let pageType = aem_utag_data.page_type || '';
		let productCategoryID = aem_utag_data.product_category_id || '';
		if (Array.isArray(productCategoryID)) {
			let pCatId = '';
			productCategoryID.forEach(catID => {
				if (catID) {
					pCatId += `${catID} `;
				}
			});
			productCategoryID = pCatId;
		}
		if (!pageCategory) {
			adobeDataLayerObject.pageInfo.pageCategory = pageType || '';
			if (qVars.clientLibs.indexOf('myaccount') > -1) {
				MyAccount.getPageCategory();
				return;
			}
			switch (pageType) {
			case 'checkout-login':
				if (window.location.pathname.search(/\.speedbuy/i) >= 0) {
					adobeDataLayerObject.pageInfo.pageCategory = 'SPEEDSIGN';
				} else {
					adobeDataLayerObject.pageInfo.pageCategory = pageType;
				}
				break;
			case 'checkout': {
				let pCat = window.localStorage.getItem('promoCat');
				let errRespCode = '';
				if (location.href.indexOf('account-locked.cvv') > -1) {
					errRespCode = '10006';
				}
				if (location.href.indexOf('account-locked.login') > -1) {
					errRespCode = '10005';
				}
				if (location.href.indexOf('account-locked.qa') > -1) {
					errRespCode = '10007';
				}
				if (location.href.indexOf('account-locked.other') > -1) {
					errRespCode = '10008';
				}
				if (location.href.indexOf('account-locked') > -1 && pCat === null) {
					adobeDataLayerObject.pageInfo.pageCategory = 'COSIGN' + errRespCode;
				} else if (location.href.indexOf('account-locked') > -1 && pCat === 'SPEEDBUY_CHECKOUT') {
					adobeDataLayerObject.pageInfo.pageCategory = 'PPSIGN' + errRespCode;
				} else if (location.href.indexOf('account-locked') > -1 && pCat === 'PAYPAL_CHECKOUT') {
					adobeDataLayerObject.pageInfo.pageCategory = 'SPEEDSIGN' + errRespCode;
				} else {
					adobeDataLayerObject.pageInfo.pageCategory = pageType;
				}
			}
				break;
			case 'product':
				if ($('.divGroupSnippet').length > 0) {
					// Group Page
					adobeDataLayerObject.pageInfo.pageCategory = 'GROUP';
				} else if ($('.makeSelection').length > 0) {
					// MYS Page
					adobeDataLayerObject.pageInfo.pageCategory = 'MYS' + productCategoryID;
				} else {
					adobeDataLayerObject.pageInfo.pageCategory = productCategoryID;
				}
				break;
			}
		} else {
			let timezone = Helper.getTimezone();
			switch (pageType) {
			case 'group':
				adobeDataLayerObject.pageInfo.pageCategory = 'group';
				break;
			case 'mys':
				adobeDataLayerObject.pageInfo.pageCategory = 'mys' + productCategoryID;
				break;
			case 'content':
				if (pageCategory) {
					adobeDataLayerObject.pageInfo.pageCategory = pageCategory;
				} else {
					adobeDataLayerObject.pageInfo.pageCategory = 'lpglobal';
				}
				break;
			case 'checkout-your-info':
				adobeDataLayerObject.pageInfo.pageCategory = 'p0003';
				adobeDataLayerObject.ecommerce.txn.checkoutPath = 'NEW CUSTOMER CHECKOUT';
				aem_utag_data.newCustomer = true;
				if (location.href.indexOf('NPO=true') > -1) {
					adobeDataLayerObject.pageInfo.pageCategory = 'p1005';
					adobeDataLayerObject.ecommerce.txn.checkoutPath = 'NPO CHECKOUT';
					aem_utag_data.newCustomer = false;
				}
				break;
			case 'forgot-password': {
				let page_id = aem_utag_data.page_id || '';
				if (!page_id && adobeDataLayerObject.pageInfo.pageName) {
					page_id = adobeDataLayerObject.pageInfo.pageName.toUpperCase();
				}
				if (document.referrer.indexOf('speedbuy') > -1 && page_id === 'SIGN IN: FORGOT PASSWORD') {
					adobeDataLayerObject.pageInfo.pageCategory = 'speedsign';
				} else if (document.referrer.indexOf('checkout') > -1 && page_id === 'SIGN IN: FORGOT PASSWORD') {
					adobeDataLayerObject.pageInfo.pageCategory = 'cosign';
				}
			}
				break;
			case 'iroa': {
				let sNewCat = 'NAV',
					sSelDay = 'CS',
					iSelIroaDateIdx = $('.iroaDates.desktop select option[selected]').index(),
					bIsOnair = $('.iroaShows.desktop .selected').hasClass('onair'),
					aDays = ['T', 'Y', '2', '3'];
				if (!bIsOnair) {
					sSelDay = 'PRV' + (iSelIroaDateIdx < 4 ? aDays[iSelIroaDateIdx] : '4');
				}
				if (qGet.urlParam('redirectTerm')) {
					sNewCat = 'SRCH';
					adobeDataLayerObject.pageInfo.shoppingCategory = sNewCat;
				}
				if (document.location.href.indexOf('PROMO') > -1) {
					sNewCat = 'PROMO';
				}
				adobeDataLayerObject.pageInfo.pageCategory = (sNewCat + sSelDay + timezone);
			}
				break;
			case 'tv-program-guide': {
				pageCategory = pageCategory ? pageCategory.toUpperCase() : '';
				let isWeekly = aem_utag_data.page_id === 'PROGRAM GUIDE: WEEKLY';
				let regExp = /(nav).+$/i;
				if (isWeekly) {
					adobeDataLayerObject.pageInfo.pageCategory = pageCategory.replace('D', 'W');
				}
				if (qGet.urlParam('redirectTerm') && pageCategory) {
					adobeDataLayerObject.pageInfo.shoppingCategory = 'srch';
					adobeDataLayerObject.pageInfo.pageCategory = pageCategory.replace(regExp, (isWeekly ? 'W' : '') + adobeDataLayerObject.pageInfo.shoppingCategory);
				} else if (document.location.href.indexOf('PROMO') > -1) {
					adobeDataLayerObject.pageInfo.pageCategory = pageCategory.replace(regExp, (isWeekly ? 'W' : '') + 'PROMO');
				} else {
					adobeDataLayerObject.pageInfo.pageCategory = pageCategory.replace(regExp, '$1');
				}
				_this.getProgramContent();
			}
				break;
			default:
				adobeDataLayerObject.pageInfo.pageCategory = pageCategory;
				_this.setUserFlow();
				aem_utag_data.newCustomer = location.href.indexOf('/checkout/new/') > -1;
				break;
			}
		}
	},
	getChannelName: function () {
		return GlobalPageHelper.getChannelName();
	},
	getProgramContent: function () {
		let minDate = typeof aem_utag_data.program_guide_min_date !== 'undefined' && aem_utag_data.program_guide_min_date.includes(' ') ? aem_utag_data.program_guide_min_date.split(' ')[0] : '',
			maxDate = typeof aem_utag_data.program_guide_max_date !== 'undefined' && aem_utag_data.program_guide_max_date.includes(' ') ? aem_utag_data.program_guide_max_date.split(' ')[0] : '',
			guideSelector = aem_utag_data.page_id === 'PROGRAM GUIDE: WEEKLY' ? 'weekly' : 'daily';
		adobeDataLayerObject.content = {
			program: {
				guideSelector,
				timeZone: Helper.getTimezone(),
				minmaxDate: minDate + ' - ' + maxDate
			}
		};
	},
	getSiteSection: function () {
		if (aem_utag_data && aem_utag_data.page_type) {
			adobeDataLayerObject.pageInfo.siteSection = aem_utag_data.page_type;
		} else if (typeof qVars !== 'undefined') {
			adobeDataLayerObject.pageInfo.siteSection = qVars.pageType || '';
		}
	},
	getBreakpoint: function () {
		return Helper.getBreakpoint();
	},
	getOrientation: function () {
		return Helper.getOrientation();
	},
	getCurrencySymbol: function (val) {
		switch (val) {
		case 'us': return 'usd';
		case 'de': return 'eur';
		case 'uk': return 'gbp';
		case 'jp': return 'jpy';
		}
		return '';
	},
	setUserFlow: function () {
		Helper.globalPageSetUserFlow();
	},
	setGlobalPageAttr: function () { //set default attributes for Page View
		//Check is iframe
		let pageIframe = (self !== top);
		if (typeof pageIframe !== 'undefined' && pageIframe) { return; }
		let isOTTApp = (aem_utag_data.category_id && aem_utag_data.category_id.includes('OTT') && aem_utag_data.category_id.indexOf('OTT') > -1);
		isOTTApp = (!isOTTApp && adobeDataLayerObject && adobeDataLayerObject.pageInfo && adobeDataLayerObject.pageInfo.pageCategory && adobeDataLayerObject.pageInfo.pageCategory.includes('OTT') && adobeDataLayerObject.pageInfo.pageCategory.indexOf('OTT') > -1);
		let isMobileApp = qCookie.get('isMobileApp') === 'true' || urlManager.getParam(window.location.href, 'isMobileApp') === 'true' || navigator.userAgent.match(/(QVC-AND|QVC_|QIP|IPD)/g);
		let platform = 'web';
		if (isMobileApp) {
			platform = qCookie.get('deviceName') || urlManager.getParam(window.location.href, 'deviceName');
			if (platform === null || platform === '') {
				if (navigator.userAgent.match(/(iPhone)/g)) {
					platform = 'iphone';
				} else if (navigator.userAgent.match(/(iPad)/g)) {
					platform = 'ipad';
				} else if (navigator.userAgent.match(/(Android)/g)) {
					platform = 'android';
				} else {
					platform = 'unknown';
				}
			}
		} else if (isOTTApp) {
			platform = 'ott';
		}
		adobeDataLayerObject.pageInfo.pageType = aem_utag_data.page_type || '';
		adobeDataLayerObject.pageInfo.categoryIdNoCm = aem_utag_data.category_id_nocm || '';
		adobeDataLayerObject.utils.platform = platform.toLowerCase();
		adobeDataLayerObject.pageInfo.channelName = _this.getChannelName();
		adobeDataLayerObject.utils.breakPoint = _this.getBreakpoint();
		adobeDataLayerObject.pageInfo.currentUrl = window.location.href;
		adobeDataLayerObject.pageInfo.moduleCount = $('#pageContent').find('[data-module-type]').length - $('#pageContent').find('.hideModule').length;
		adobeDataLayerObject.pageInfo.superModuleCount = '0'; //default
		adobeDataLayerObject.pageInfo.breadCrumb = $('.container.breadcrumbs,.container.searchProductListing').length ? 'y' : 'n';
		let nCode = ($('#refinement').val()) && ($('#refinement').val().includes('/')) ? $('#refinement').val().split('/').pop() : '';
		let aUrlRes = location.href.match(/\/_\/(N-.*)\//i);
		adobeDataLayerObject.pageInfo.nCode = !nCode && aUrlRes && aUrlRes.length > 1 ? aUrlRes[1] : nCode ?  'N-' + nCode : '';
		adobeDataLayerObject.pageInfo.pagination = aem_utag_data.currentPage || '';
		adobeDataLayerObject.pageInfo.sort = urlManager.getParam(adobeDataLayerObject.pageInfo.currentUrl, 'sort');
		//user is opt'd out of non essential analytics
		if (Helper.aaGDPR('c3')) {
			adobeDataLayerObject.user.userConsent = 'optout';
		} else {
			adobeDataLayerObject.user.userConsent = 'optin';
		}
		//Shopping Category Should be Designated based on their Path to product detail.
		if (!(adobeDataLayerObject.pageInfo.shoppingCategory)) {
			adobeDataLayerObject.pageInfo.shoppingCategory = aem_utag_data.shopping_category;
		}
		//Captures the count of product/receipes loaded on listing pages
		if ($('.productGallery') && $('.productGallery').length) {
			adobeDataLayerObject.pageInfo.listingSize = $('.productGallery').data('totalProducts') || '';
			adobeDataLayerObject.pageInfo.productRefinementCnt = $('.facetedMulti .selected').length || '0';
		}
		let prodFilters = '', productRefinement = [];
		if ($('[data-component-type="PRODUCT_LISTING_LIST"] .selectedResultFacets').find('li a').length) {
			prodFilters = $('[data-component-type="PRODUCT_LISTING_LIST"] .selectedResultFacets').find('li a');
		} else if ($('[data-component-type="RECIPE_LISTING_LIST"] .selectedResultFacets').find('li a').length) {
			prodFilters = $('[data-component-type="RECIPE_LISTING_LIST"] .selectedResultFacets').find('li a');
		}
		if (prodFilters.length) {
			for (let filterIndex = 0; filterIndex < prodFilters.length; filterIndex++) {
				let group = prodFilters[filterIndex].getAttribute('data-grouping'),
					value = prodFilters[filterIndex].getAttribute('data-refinement-value');
				if (group && value) {
					productRefinement.push(group + ':' + value);
				}
			}
		}
		//Captures filters applied on page (PLP, Search, etc.). Multiple filters are separated by pipeline. Category and filters are added colon separated.
		adobeDataLayerObject.pageInfo.productRefinement = productRefinement.join('|');
		if (Helper.additionalCostsEnabled()) {
			adobeDataLayerObject.pageInfo.productRefinementCnt = productRefinement.length;
		}
		//Captures user type at time of registration like nc for new customer and ec for existing customer.
		adobeDataLayerObject.user.accountType = '';
		if (location.href.indexOf('NPO=true') > -1) {
			adobeDataLayerObject.user.accountType = 'ec';
		} else if (location.href.indexOf('/checkout/new/') > -1) {
			adobeDataLayerObject.user.accountType = 'nv';
		}
		//Screen Orientation
		if (window.innerHeight > window.innerWidth) {
			adobeDataLayerObject.utils.orientation = 'portrait';
		} else {
			adobeDataLayerObject.utils.orientation = 'landscape';
		}
		adobeDataLayerObject.user.authStatus = Helper.getUserAuthStatus();
		adobeDataLayerObject.user.quid = qCookie.get('quid') || window.localStorage.getItem('quid') || 'unknown';
		adobeDataLayerObject.user.globalUserID = qCookie.get(qCookie.name.globalUserId) || '';
		adobeDataLayerObject.user.anonymizedUserID = Helper.getAnonymizedUserID();
		//Process uattr data into its own properties
		const uattrArray = (qCookie.get('uattr') || '').split('|');
		adobeDataLayerObject.user.uattr.customerType = uattrArray[0] || '';
		adobeDataLayerObject.user.uattr.multiBuyStrataCode = uattrArray[1] || '';
		adobeDataLayerObject.user.uattr.customerSegmentCode = uattrArray[2] || '';
		adobeDataLayerObject.user.uattr.firstOrderDate = uattrArray[3] || '';
		adobeDataLayerObject.user.uattr.lastOrderDate = uattrArray[4] || '';
		adobeDataLayerObject.user.uattr.merchandisingPreferenceCode = uattrArray[5] || '';
		adobeDataLayerObject.user.uattr.qCardIndicatorCode = uattrArray[6] || '';
		let locale = typeof qVars !== 'undefined' && qVars.locale ? qVars.locale : '';
		let country = locale.includes('_') ? locale.split('_')[1] : '';
		let language = locale.includes('_') ? locale.split('_')[0] : '';
		adobeDataLayerObject.utils.country = typeof qVars !== 'undefined' && qVars.format && qVars.format.countryCode ? qVars.format.countryCode.toLowerCase() : country.toLowerCase();
		adobeDataLayerObject.utils.language = language.toLowerCase();
		//Captures the currency code in which transaction will take place. 3 digit currency code will be stored.
		if (aem_utag_data.currency_code) {
			adobeDataLayerObject.utils.currency = aem_utag_data.currency_code.toLowerCase();
		} else if (adobeDataLayerObject.utils.country) {
			adobeDataLayerObject.utils.currency = _this.getCurrencySymbol(adobeDataLayerObject.utils.country.toLowerCase());
		}
		adobeDataLayerObject.utils.timeStamp = new Date().toString().toLowerCase();
		let pageType = aem_utag_data.page_type || '';
		switch (pageType) {
		case 'product-listing-category':
		case 'product-listing-search':
		case 'product-listing-dynamic-search':
		case 'wishlist':
		case 'error':
			adobeDataLayerObject.pageInfo.superModuleCount = '1';
			if (qGet.urlParam('redirectTerm')) {
				adobeDataLayerObject.pageInfo.shoppingCategory = 'SRCH';
			}
			break;
		}
		if (!adobeDataLayerObject.pageInfo.pageCategory) {
			_this.getPageCategory();
		}
	},
	getModuleLink: function (ele) {
		const isSuperModuleClick = Helper.hasSuperModule($(ele));
		let $el = $(ele), $elParents = $el.parents(), componentType = moduleType = moduleLink = moduleFeature = featureDetail = productNumber = '',
			// Sending nothing in the event field for super-module clicks. See https://qurate.atlassian.net/browse/QCAA-214 
			event = isSuperModuleClick ? '' : 'moduleClick';
		if (aem_utag_data.page_type === 'iroa'
			&& (($el.closest('.iroaSelectMenu select').length)
				|| ($el.parents('.iroaShows').find('li').hasClass('selected'))
				|| ($el.closest('.iroaAddNavComp').length)
			)) {
			_this.generalClickHandler(ele);
		} else {
			if ($el.closest('[data-component-type]').length) {
				componentType = ele.closest('[data-component-type]').getAttribute('data-component-type') || '';
			}
			if ($el.closest('[data-module-type]').length) {
				moduleType = ele.closest('[data-module-type]').getAttribute('data-module-type') || '';
			}
			if (moduleType === 'MH') {
				let dropDownName = '';
				if ($el.closest('.shopByDDOpen').length || $el.closest('.shopByDropDown').length) {
					dropDownName = 'shop by dropdown';
				} else if ($el.closest('.shopByMobile').length || $el.closest('.shopByMobileSub').length) {
					dropDownName = 'shop by dropdown mobile';
				} else if ($el.closest('.myAccountDropDown').length) {
					dropDownName = 'my account dropdown';
				}
				let shopCat = $el.closest('.flyoutSection').length ? $(ele.closest('.flyoutSection')).find('.mobFlyoutCat').text() : '',
					label = $el.closest('[aria-label]').length ? ele.closest('[aria-label]').getAttribute('aria-label') : '',
					linkText = ele.getAttribute('alt') || ele.getAttribute('title') || ele.innerText || '';
				if (dropDownName) {
					moduleLink = `${dropDownName} : `;
				}
				if (shopCat) {
					moduleLink += `${shopCat} : `;
				}
				if (label && label !== 'Main Navigation') {
					if ($el.closest('#shopByCategory').length || label === 'Shop QVC TV' || label === 'Today\'s Special Value & Deals' || label === 'QVC TV' ) {
						label += ' Dropdown';
					}
					moduleLink += `${label} : `;
				}
				if (linkText) {
					moduleLink += `${linkText}`;
				}
				if ($el.closest('[data-component-type]').length && componentType === 'MASTHEAD_POP') {
					moduleLink += ' : HIDDEN';
				}
			} else {
				moduleLink = ele.getAttribute('alt') || ele.getAttribute('title') || ele.innerText || '';
			}
			if ($el.closest('[data-module-type]').length) {
				moduleFeature = ele.closest('[data-module-type]').getAttribute('data-module-feature-name') || '';
			}
			//Capture Module position
			let moduleTypeClicked = ele.closest('[data-module-type]'),
				totalSections = $('#pageContent').find('[data-module-type]').not('.hideModule'),
				sectionPosition = totalSections.index(moduleTypeClicked),
				modulePosition = sectionPosition > -1 ? String(sectionPosition + 1) : '',
				//Capture Feature position
				featureClicked = ele.closest('[data-component-type]'),
				totalFeature = $el.closest('[data-module-type]').find('[data-component-type]'),
				sectionFeatuePosition = totalFeature.index(featureClicked),
				featurePosition = modulePosition && sectionFeatuePosition > -1 ? String(sectionFeatuePosition + 1) : '';
			if ($el.parents('.galleryItem').length) {
				featurePosition = $el.parents('.galleryItem').index() + 1;
			}
			if ($el.closest('.qComponent').length) {
				let featureShowCode = $el.closest('[data-feature-show-code]').length ? ele.closest('[data-feature-show-code]').getAttribute('data-feature-show-code') : '',
					featureName = $el.closest('[data-feature-name]').length ? ele.closest('[data-feature-name]').getAttribute('data-feature-name') : '';
				if (featureShowCode && featureName) {
					featureDetail = (featureName + '|' + featureShowCode).trim();
				} else if (featureShowCode) {
					featureDetail = (featureShowCode).trim();
				} else if (featureName) {
					featureDetail = (featureName).trim();
				}
			}
			productNumber = _this.getProductName(ele);
			let originalTerm = SearchPage.getSearchText();
			if ($elParents.is('[id=predictiveSearch]') || $elParents.is('[id="nullSearch"]')) {
				let type = '';
				if ($elParents.is('[id=predictiveSearch]')) {
					type = 'guided';
				}
				if ($elParents.is('[id="nullSearch"]')) {
					type = 'recent';
				}
				SearchPage.getGuidedSearchData(ele, type);
			} else if (componentType === 'PRODUCT_LISTING_LIST' && $elParents.is('.productInfo.productGallery .galleryItem') && (originalTerm.length > 0 || adobeDataLayerObject.search.term === 'Lunchtime Specials')) {
				SearchPage.getSearchResultClickData(ele);
			} else if (componentType === 'PRODUCT_LISTING_NAV') {
				SearchPage.getfilterAppliedClickData(ele);
			} else {
				if ($elParents.is('.searchInputBox')) {
					moduleLink = 'internal search initiated';
				}
				if ($el.closest('.signInGreet').length || $el.closest('.signInAcct').length || $el.parent('.acctGreeting').length) {
					moduleLink = 'my account dropdown';
				}
				if ($elParents.is('.menuHamburger')) {
					if ($el.parents('.menuHamburger').find('.menuNavIcon.open').length) {
						moduleLink = 'menu Hamburger: closed';
					} else {
						moduleLink = 'menu Hamburger: open';
					}
				}
				if ($el.closest('.menuCart').length) {
					moduleLink = 'Shopping Cart';
				}
				let programScheduledTime = channelName = programCode = programName = timeZone = minmaxDate = '';
				if (moduleType === 'TV_PROGRAM_GUIDE') {
					let guideSelectorWeekly = aem_utag_data.page_id === 'PROGRAM GUIDE: WEEKLY';
					channelName = $el.closest('[data-channelname]').length ? ele.closest('[data-channelname]').getAttribute('data-channelname') : '';
					programCode = $el.closest('[data-sourcecode]').length ? ele.closest('[data-sourcecode]').getAttribute('data-sourcecode') : '';
					programName = $el.closest('[data-showname]').length ? ele.closest('[data-showname]').getAttribute('data-showname') : '';
					timeZone = Helper.getTimezone();
					if (guideSelectorWeekly) {
						channelName = _this.getChannelName();
					}
					if ($el.closest('.timeZoneSelection').length) {
						moduleLink = 'timezone:' + moduleLink;
					}
					if ($el.closest('.dropDownDatePicker').length) {
						moduleLink = 'date picker';
					}
					if ($el.closest('.programView .showList').length) {
						let channelName = $el.closest('[data-channelname]').length ? ele.closest('[data-channelname]').getAttribute('data-channelname') : '',
							showName = $el.closest('[data-showname]').length ? ele.closest('[data-showname]').getAttribute('data-showname') : '',
							currentShow = $el.closest('li').hasClass('currentShow'),
							pastShow = $el.closest('li').hasClass('showOver'),
							showStatus = currentShow ? 'current show' : pastShow ? 'past show' : 'future show' ;
						if (guideSelectorWeekly) {
							channelName = _this.getChannelName();
						}
						moduleLink = channelName + ':' + showStatus + ':' + showName + ':'+ moduleLink;
					}
					if ($elParents.is('.watchShow')) {
						let channelCode = $el.closest('[data-channelcode]').length ? ele.closest('[data-channelcode]').getAttribute('data-channelcode') : '',
							channelName = '',
							showName = $el.closest('[data-showname]').length ? ele.closest('[data-showname]').getAttribute('data-showname') : '',
							showCode = $el.closest('[data-sourcecode]').length ? ele.closest('[data-sourcecode]').getAttribute('data-sourcecode') : '';
						if (typeof aem_utag_data.aemchannels !== 'undefined' && typeof  aem_utag_data.aemchannels[channelCode] !== 'undefined') {
							channelName = aem_utag_data.aemchannels[channelCode].name ? aem_utag_data.aemchannels[channelCode].name : '';
						}
						moduleLink = channelName + ':' + 'current show' + ':' + showName + ':' + showCode + ':'+ moduleLink;
					}
				}
				if ($el.closest('.removeReminder').length) {
					event = 'removeProgramReminder';
					let reminderData = $el.closest('.removeReminder').data();
					if (typeof reminderData !== 'undefined' && typeof reminderData.reminderId !== 'undefined' && typeof reminderData.showName !== 'undefined') {
						programName = reminderData.showName;
						programCode = reminderData.reminderId;
					}
				}
				if ($el.closest('.galleryItem').length) {
					moduleLink = $el.closest('.galleryItem').find('.productDesc').text();
				}
				_this.captureEventData({
					event,
					modules: {
						modulePosition,
						moduleType: moduleType ? moduleType.toLowerCase() : '',
						moduleFeature: moduleFeature ? moduleFeature.toLowerCase() : '',
						componentType: componentType ? componentType.toLowerCase() : '',
						featureDetail: featureDetail ? featureDetail.toLowerCase() : '',
						moduleLink: moduleLink ? moduleLink.toLowerCase() : '',
						featurePosition,
						productNumber
					},
					utils: Helper.getUtilsData(),
					program: {
						programScheduledTime,
						channelName,
						programCode,
						programName,
						timeZone,
						minmaxDate
					}
				});
			}
		}
	},
	captureEventData: function (eventData) {
		window.adobeDataLayer.push(eventData);
	},
	getProductName: function (ele) {
		let $el = $(ele), productNumber = '';
		if ($el.closest('[data-prod-id]').length) {
			productNumber = ele.closest('[data-prod-id]').getAttribute('data-prod-id') || '';
		} else if ($el.closest('[data-curitemid]').length) {
			productNumber = ele.closest('[data-curitemid]').getAttribute('data-curitemid') || '';
		} else if ($el.closest('[data-item-id]').length) {
			productNumber = ele.closest('[data-item-id]').getAttribute('data-item-id') || '';
		}
		return productNumber;
	},
	generalClickHandler: function (ele) {
		let $el = $(ele),linkType = linkCategory = linkSection = linkName = productNumber = iroaSourceCode = programName = '',
			pageType = aem_utag_data.page_type,
			event = 'linkClick',
			clickedData = {
				linkCategory,
				linkSection,
				linkName,
				productNumber
			},
			content = {
				program: {
					programScheduledTime : '',
					channelName: '',
					programCode: '',
					programName: '',
					timeZone: '',
					minmaxDate: ''
				}
			};
		if ($el.closest('[data-component-type]').length) {
			clickedData.linkSection = ele.closest('[data-component-type]').getAttribute('data-component-type') || '';
			clickedData.linkName = ele.getAttribute('alt') || ele.getAttribute('title') || ele.innerText || '';
			clickedData.linkCategory = adobeDataLayerObject.pageInfo.siteSection;
			if (!clickedData.linkName && $(ele).closest('button').length) {
				clickedData.linkName = ele.closest('button').innerText;
			}
		}
		if ($el.closest('#reviewsContainer').length || $el.closest('#reviewsSummary').length) {
			if (pageType === 'product') {
				clickedData = ProductPage.generalClickHandler(ele);
			}
		}
		if (pageType === 'iroa') {
			clickedData = Helper.generalClickHandler(ele);
		}
		if (pageType === 'tv-program-guide' && $el.closest('.reminderLayer').find('p[data-reminder-info] .checked').length) {
			event = 'addProgramReminder';
			clickedData.linkCategory = 'tv-program-guide';
			clickedData.linkSection = 'Add Reminder';

			let selectedSlots = $el.closest('.reminderLayer').find('p[data-reminder-info] .checked').closest('p[data-reminder-info]');
			if (selectedSlots.length) {
				for (let slotIndex = 0; slotIndex < selectedSlots.length; slotIndex++) {
					let reminderInfo = selectedSlots[slotIndex].getAttribute('data-reminder-info');
					if (typeof reminderInfo !== 'undefined' && reminderInfo.length && reminderInfo.includes('|')) {
						let reminderData = reminderInfo.split('|'),
							startDate = reminderData.length > 4 ? new Date(reminderData[4]) : '',
							endDate = reminderData.length > 5 ? new Date(reminderData[5]) : '',
							date = startDate.getDate(),
							month = (startDate.getMonth() + 1),
							year = startDate.getFullYear(),
							hours = startDate.getHours(),
							minutes = startDate.getMinutes(),
							seconds = startDate.getSeconds(),
							endHours = endDate.getHours(),
							endMinutes = endDate.getMinutes(),
							endSeconds = endDate.getSeconds(),
							startTime = hours + ':' + minutes + ':' + seconds,
							endTime = endHours + ':' + endMinutes + ':' + endSeconds;

						content.program.timeZone = reminderData.length > 0 ? reminderData[0] : '';
						content.program.programName = reminderData.length > 1 ? reminderData[1] : '';
						clickedData.linkName = content.program.programName;
						content.program.programCode = reminderData.length > 3 ? reminderData[3] : '';
						startDate = reminderData.length > 4 ? new Date(reminderData[4]) : '';
						endDate = reminderData.length > 5 ? new Date(reminderData[5]) : '';
						content.program.programScheduledTime = date + '/' + month + '/' + year + ':' + startTime + '-' + endTime;
					}
				}
			}
		}
		linkCategory = clickedData.linkCategory || '';
		linkSection = clickedData.linkSection || '';
		linkName = clickedData.linkName || '';
		iroaSourceCode = clickedData.iroaSourceCode || '';
		programName = clickedData.programName || '';
		linkType = ele.localName;
		productNumber = _this.getProductName(ele);
		if (aem_utag_data.page_type === 'myaccount-account-settings' && $el.closest('#pageContent').length) {
			if ($el.closest('form button').length === 0) {
				linkName = 'edit';
			}
		}
		if (aem_utag_data.page_type === 'myaccount-login') {
			linkCategory = 'myaccount-login';
			if ($el.is('.forgotPw')) {
				linkSection = 'reset password start';
				linkName = 'reset password';
			}
			if ($el.closest('.newCust').length) {
				linkSection = 'new customer start';
				linkName = 'new customer';
			}
		}
		if (aem_utag_data.page_type === 'forgot-password') {
			linkCategory = 'forgot-password';
			if ($el.closest('form-group continue').length) {
				linkSection = 'new customer start';
				linkName = 'new customer';
			}
		}
		if ($el.closest('#qPrivacyBanner').length || $el.closest('form#gdprOptOut').length) {
			event = 'userConsent';
			linkCategory = 'user consent';
			linkSection = $el.closest('form#gdprOptOut').length ? 'privacy setting page' : 'privacy banner';
			if ($el.closest('.privacyButtons ').length) {
				if ($el.closest('.privacy-btn').length) {
					linkName = 'accept';
				} else if ($el.closest('.privacy-btn-deny').length) {
					linkName = 'deny';
				} else {
					linkName = 'manage cookies';
				}
			}
			if ($el.closest('.btnSave').length) {
				linkName = 'submit';
			}
		}
		if (Helper.additionalCostsEnabled() && $el.closest('body ul.ui-menu.ui-autocomplete').length) {
			SearchPage.getGuidedSearchData(ele, 'guided');
		}
		if (linkSection === 'CREATE_ACCOUNT') {
			const button = document.querySelector('.btnContinue');
			if (button) {
				event = 'signupAttempt';
			}
			_this.captureEventData({
				event,
				links: {
					linkType,
					linkCategory,
					linkSection,
					linkName,
					productNumber,
					iroaSourceCode,
					programName
				},
				content
			});
		}
	},
	addListener: function () {
		$(document).off('.aaModuleClick').on('mouseup.aaModuleClick keyup.aaModuleClick', 'a:not(.addToCartOffCanvasSidebar a), button:not(.addToCartOffCanvasSidebar button, .btnAddToCartCanvas)', function (event) {
			let $el = $(event.target);
			if (!($el.closest('.fancybox-close').length) && !($el.closest('#videoWrapper .videoPlayer').length)) {
				if ($el.closest('[data-module-type]').length && $el.closest('ul.ui-menu.ui-autocomplete').length < 1) {
					_this.getModuleLink(event.target);
				} else {
					_this.generalClickHandler(event.target);
				}
			}
		});
		document.onreadystatechange = () => {
			if (document.readyState === "complete") {
				ProductPage.initEventListeners();
			}
		};
	},
	productImpressionListener: function (productIDs) {
		let for50 = for75 = for100 = true,
			productListSize = 24,
			prodImpress = [],
			pageLoadEvent = adobeDataLayer.find(item => item['event'] === 'pageLoad');
		if (!pageLoadEvent) {
			setTimeout(() => {
				_this.productImpressionListener(productIDs);
			}, 500);
			return void 0;
		}
		if (productIDs.length < productListSize) {
			prodImpress = productIDs;
			_this.captureEventData({
				event: 'prodImpress',
				prodImpression: prodImpress
			});
		} else {
			let lastEle = (productIDs.length - 1) ;
			prodImpress = productIDs.slice(0, Math.ceil(lastEle * 0.25));
			_this.captureEventData({
				event: 'prodImpress',
				prodImpression: prodImpress
			});
			$(window).on('scroll', function () {
				let scrollTop = $(window).scrollTop(),
					docHeight = $(document).height(),
					winHeight = $(window).height(),
					scrollPercent = (scrollTop) / (docHeight - winHeight),
					scrollPercentRounded = Math.ceil(scrollPercent * 100),
					index = 0,
					totalLength = productIDs.length,
					lastIndex = productIDs.length - 1;
				prodImpress = [];
				if (for100 && scrollPercentRounded > 75) {
					index = Math.round(totalLength * 0.75);
					prodImpress = productIDs.slice(index, totalLength);
					for100 = false;
				} else if (for75 && scrollPercentRounded > 50) {
					index = Math.round(totalLength * 0.50);
					lastIndex = Math.ceil(totalLength * 0.75);
					prodImpress = productIDs.slice(index, lastIndex);
					for75 = false;
				} else if (for50 && scrollPercentRounded > 25) {
					index = Math.round(totalLength * 0.25);
					lastIndex = Math.ceil(totalLength * 0.50);
					prodImpress = productIDs.slice(index, lastIndex);
					for50 = false;
				}
				if (prodImpress.length) {
					_this.captureEventData({
						event: 'prodImpress',
						prodImpression: prodImpress
					});
				}
			});
		}
	},
	getProductImpression: function () {
		let productIDs = Helper.getProductIDs(),
			enableProdImpression = productIDs.length;
		if (enableProdImpression) {
			_this.productImpressionListener(productIDs);
		}
	},
	addVideoListener: function (videoInitCallback = () => {}) {
		if ($('.ssvideo, .videoPlayer, .videoGalleryCarousel').length) {
			adobeDataLayerObject.pageInfo.videoPresent = 'yes';
			videoInitCallback();
		} else {
			adobeDataLayerObject.pageInfo.videoPresent = 'no';
		}
	},
	addErrorListener: function () {
		$(document).on('qError', function(e, params){
			if (typeof params === 'object' && typeof params.eid === 'string' && typeof params.ecat === 'string') {
				let regex = new RegExp(/ ?\[ID=\d+\]/i),
					pageName = typeof aem_utag_data.page_id === 'string' && aem_utag_data.page_id.length ? aem_utag_data.page_id : '',
					pageCategory = typeof aem_utag_data.category_id === 'string' && aem_utag_data.category_id.length ? aem_utag_data.category_id : '',
					pathIndicator = typeof aem_utag_data.path_indicator === 'string' && aem_utag_data.path_indicator.length ? aem_utag_data.path_indicator : '',
					errorCode = typeof params.type === 'string' && params.type.length ? params.type : 'NO CODE AVAILABLE',
					errorMessage = typeof params.msg === 'string' ? params.msg.replace(regex, ''): '',
					checkoutPath = typeof adobeDataLayerObject.ecommerce.txn.checkoutPath !== 'undefined' ? adobeDataLayerObject.ecommerce.txn.checkoutPath : '',
					errorType = checkoutPath || pathIndicator || 'GENERIC ERROR';
				if (pageName && pageName.indexOf('ORDER DETAIL:') === 0) {
					errorType = 'ORDER MANAGEMENT';
				} else if (params.ecat.indexOf('ACCOUNT SETTINGS') > -1 || (pageName && (pageName === 'SAVED PAYMENT METHODS' || pageName === 'ADDRESS BOOK'))) {
					errorType = 'ACCOUNT MANAGEMENT';
				} else if (params.eid === oTaggingDefaults.elemId.errPayPaypal){
					errorType = 'PAYPAL CHECKOUT';
				} else if (pageCategory && pageCategory.indexOf('OTT') > -1) {
					errorType = 'LINK DEVICES';
				}
				if (e.type === 'qError' && pageName && pageName === 'SIGN IN') {
					errorType =  oTaggingDefaults.elemCat.errSignInForm;
				}
				if (e.type === 'qError' && pageName && pageName ==='CHECKOUT: REVIEW ORDER' && params.eid === oTaggingDefaults.elemId.errVoucherCodeDeclined) {
					let couponCode = '';
					if (typeof params.linkClickParams === 'object' && typeof params.linkClickParams.linkClickMsg === 'string' && params.linkClickParams.linkClickMsg.length && params.linkClickParams.linkClickMsg.includes(':INVALID_CODE')) {
						couponCode = params.linkClickParams.linkClickMsg.split(':INVALID_CODE')[0];
					}
					Helper.captureCouponData({
						paramObj: {
							name: errorMessage ? errorMessage.toLowerCase() : '',
							couponCode: couponCode
						}
					});
				}
				_this.captureEventData({
					event: 'errorObject',
					error: {
						errorCode,
						errorMessage,
						errorType
					}
				});
			}
		});
		//sign in easy pay error
		$(document).on('click', '.dqNotification #pageContent .btn', function() {
			_this.captureEventData({event: 'errorObject', error: {errorCode: 'NO CODE AVAILABLE',errorMessage: 'VIEW_EASY_PAY_ORDERS',errorType: 'SIGN_IN'}});
		});
		//login, account locked
		$(document).on('click', '.accountLockScreen a', function(clickEvent) {
			_this.captureEventData({event: 'errorObject', error: {errorCode: 'NO CODE AVAILABLE', errorMessage: $.trim($(clickEvent.target).text().toUpperCase()).replace(' ', '_'), errorType: 'SIGN_IN'}});
		});
		//Recipes
		$(document).on('click', '.errorBtn .text-right a', function() {
			_this.captureEventData({event: 'errorObject', error: {errorCode: 'NO CODE AVAILABLE', errorMessage: 'REDIRECT_HOME', errorType: 'RECIPES'}});
		});
		$(document).on('click', '.errorBtn .text-left a', function() {
			_this.captureEventData({event: 'errorObject', error: { errorCode: 'NO CODE AVAILABLE', errorMessage: 'REDIRECT_CONTACT_US', errorType: 'RECIPES'}});
		});
		//My Account Delinquent Easy Pay Order
		if (qVars.page === 'dq-notification') {
			$(document).on('click', '.dqNotification #pageContent .btn', function() {
				_this.captureEventData({event: 'errorObject', error: {errorCode: 'NO CODE AVAILABLE', errorMessage: 'VIEW_EASY_PAY_ORDERS', errorType: 'SIGN_IN'}});
			});
		}
		//My Account Account Locked
		if (aem_utag_data.page_type === 'myaccount-locked') {
			$(document).on('click', '[data-component-type="ACCOUNT_LOCK"] a, [data-component-type="ACCOUNT_LOCK"] button', function(clickEvent) {
				_this.captureEventData({event: 'errorObject', error: {errorCode: 'NO CODE AVAILABLE', errorMessage: qString.condenseForTag($(clickEvent.target).text()), errorType: 'SIGN_IN'}});
			});
		}
		if ($('.overlayGenericError').length > 0) {
			if (aem_utag_data.pageName === 'PRODUCT: ' + aem_utag_data.product_name) {
				_this.captureEventData({event: 'errorObject',error: {errorCode: 'NO CODE AVAILABLE', errorMessage: 'PRODUCT: ' + aem_utag_data.product_name + '-ORDER NOT SUBMITTABLE - GENERIC ERROR', errorType: 'SPEED BUY ORDER ISSUE'}});
			} else if (aem_utag_data.pageName === 'SIGN IN' && location.href.indexOf('login.speedbuy') > -1) {
				_this.captureEventData({event: 'errorObject',error: {errorCode: 'NO CODE AVAILABLE', errorMessage: 'SIGN IN - ORDER NOT SUBMITTABLE - GENERIC ERROR', errorType: 'SPEED BUY ORDER ISSUE'}});
			} else if ($('.errorMsgOverlay').length > 0 && $('.errorMsgOverlay p').first().html().indexOf('limited') > -1) {
				_this.captureEventData({event: 'errorObject',error: {errorCode: 'NO CODE AVAILABLE', errorMessage: 'ADD TO CART ERROR - GENERIC ERROR', errorType: 'ADD TO CART ERROR'}});
			} else {
				_this.captureEventData({event: 'errorObject',error: {errorCode: 'NO CODE AVAILABLE', errorMessage: 'GENERIC ERROR', errorType: 'GENERIC ERROR'}});
			}

		}
	},
};

module.exports = _this;
