const GlobalPageHelper = require('../aa-global-page/aa-global-page.helper');

const _this = {
	iLiveShowVideoLength: 86400,
	videoWatchTime: 0,
	getVideoPlayerData: function (id) {
		let isShopLive = aem_utag_data.page_type === 'shoplive' && id === ssVideoPlayer.dataKeys.live,
			isLive = shopLiveTV.player.sPlayerType === 'live',
			isShopLiveType = isShopLive && isLive,
			isProgramGuide = aem_utag_data.page_type === 'tv-program-guide' && id === ssVideoPlayer.dataKeys.programGuide,
			isProductPage = typeof product !== 'undefined' && id === ssVideoPlayer.dataKeys.product,
			vjs = videojs.getPlayer(id),
			videoUrl = vjs.src(),
			$player = $(vjs.el()),
			sClient = ssVideoPlayer.getPlayerClient($player),
			videoTitle = '',
			videoCategory = '',
			videoChannel = GlobalPageHelper.getChannelName(),
			videoRunningLocation = vjs.isFullscreen() ? 'full_screen_open' : 'full_screen_close',
			mediaID = id,
			autoPlay = vjs.autoplay() ? 'y' : 'n',
			watchTime = ((isShopLive || isProgramGuide) && isLive) ? _this.videoWatchTime : Math.floor(vjs.currentTime()),
			videoDuration = ((isShopLive || isProgramGuide) && isLive) ? _this.iLiveShowVideoLength : Math.floor(vjs.duration()) ? Math.floor(vjs.duration()) : '',
			videoPercent = ((isShopLive || isProgramGuide) && isLive) ? '' : watchTime && videoDuration ? Math.floor((watchTime / videoDuration) * 100) : '';
		if (isShopLive) {
			videoTitle = isShopLiveType && shopLiveTV.player.curShowTitle ? shopLiveTV.player.curShowTitle : $('.vjs-title-display').html() ? $('.vjs-title-display').html() : 'VIDEO TITLE NOT PROVIDED';
			videoCategory = 'VIDEO-SHOWS-LIVE';
			videoChannel = aem_utag_data.aemchannels[shopLiveTV.player.curChannel].name;
		} else if (isProgramGuide) {
			videoTitle = $('.fancybox-inner.showDataContainer').data('showname');
			videoCategory = 'VIDEO-SHOWS-LIVE';
			let dataState = adobeDataLayer.getState();
			if (typeof dataState !== 'undefined' && dataState.program && dataState.program.channelName && dataState.program.channelName.length) {
				videoChannel = dataState.program.channelName;
			}
		} else if (isProductPage) {
			let vid = ssVideoPlayer.getCurrentVideoId($player),
				videoData = ssVideoPlayer.getVideoData(vid, id);
			videoTitle = videoData.videoType.toUpperCase() + ':' + product.id;
			videoCategory = 'VIDEO-PRODUCTDETAIL';
			mediaID = sClient + vid;
		} else {
			let vid = ssVideoPlayer.getCurrentVideoId($player),
				videoData = ssVideoPlayer.getVideoData(vid, id);
			videoTitle = videoData && videoData.title ? qString.condense(videoData.title, 40, 'upper') : 'VIDEO TITLE NOT PROVIDED';
			videoCategory = 'VIDEO-CLIP-MODULE';
			mediaID = sClient + vid;
		}
		return { videoCategory, videoTitle, videoUrl, autoPlay, videoDuration, mediaID, watchTime, videoRunningLocation, videoChannel, videoPercent};
	},
	playerEventHandler: function (id) {
		let vjs = videojs.getPlayer(id);
		if (typeof(vjs) === 'undefined') {
			return;
		}
		let errorObj = vjs.error(),
			playTime = 0,
			watchTime = 0,
			pause = resume = seek = false,
			for25 = for50 = for75 = for100 = true,
			isShopLive = aem_utag_data.page_type === 'shoplive' && id === ssVideoPlayer.dataKeys.live,
			isLiveType = shopLiveTV.player.sPlayerType === 'live',
			isProgramGuide = aem_utag_data.page_type === 'tv-program-guide' && id === ssVideoPlayer.dataKeys.programGuide,
			isLive = ((isShopLive || isProgramGuide) && isLiveType) ;
		if (errorObj !== null) {
			let errCode = errorObj.code || 'NO CODE AVAILABLE';
			window.adobeDataLayer.push({
				event: 'errorObject',
				error: {
					errorCode: errCode,
					errorMessage: 'VIDEO ERROR: '+ errCode,
					errorType: 'VIDEO ERROR'
				}
			});
		}
		vjs.on('timeupdate', function() {
			if (isLive) {
				_this.videoWatchTime = Math.floor((new Date().getTime() - playTime)/1000);
				if (_this.videoWatchTime % 1500 === 0 && _this.videoWatchTime !== watchTime) {
					watchTime = _this.videoWatchTime;
					window.adobeDataLayer.push({
						event: 'liveVideoProgress',
						content: {
							video: _this.getVideoPlayerData(id),
						}
					});
				}
			} else {
				let vProgress = false,
					videoDuration = vjs.duration(),
					watchTime = vjs.currentTime(),
					videoPercent = Math.floor((watchTime / videoDuration) * 100),
					watchDiff = Math.abs(watchTime - _this.videoWatchTime);
				if (videoPercent > 0 && watchDiff > 2) {
					window.adobeDataLayer.push({
						event: 'videoSeek',
						content: {
							video: _this.getVideoPlayerData(id),
						}
					});
					seek = true;
					resume = false;
				}
				_this.videoWatchTime = Math.floor(vjs.currentTime());
				if (for100 && videoPercent === 100) {
					vProgress = true;
					for100 = false;
					for75 = false;
					for50 = false;
					for25 = false;
					resume = false;
				} else if (for75 && videoPercent === 75) {
					vProgress = true;
					for75 = false;
					for50 = false;
					for25 = false;
				} else if (for50 && videoPercent === 50) {
					vProgress = true;
					for50 = false;
					for25 = false;
				} else if (for25 && videoPercent === 25) {
					vProgress = true;
					for25 = false;
				}
				if (vProgress) {
					vProgress = false;
					window.adobeDataLayer.push({
						event: 'videoProgress',
						eventInfo: videoPercent ? videoPercent.toString() : '',
						content: {
							video: _this.getVideoPlayerData(id),
						}
					});
				}
			}
		});
		vjs.on('firstplay', function() {
			window.adobeDataLayer.push({
				event: 'videoStart',
				content: {
					video: _this.getVideoPlayerData(id),
				}
			});
			playTime = new Date().getTime();
			resume = true;
		});
		vjs.on('play', function() {
			if (pause) {
				window.adobeDataLayer.push({
					event: 'videoResume',
					content: {
						video: _this.getVideoPlayerData(id),
					}
				});
				resume = true;
				pause = false;
			}
			if (seek) {
				resume = true;
			}
		});
		vjs.on('pause', function() {
			let videoDuration = watchTime = videoPercent = 0,
				is100 = false;
			if (!isLive) {
				videoDuration = Math.floor(vjs.duration());
				watchTime =  Math.floor(vjs.currentTime());
				videoPercent = Math.floor((watchTime / videoDuration) * 100);
				is100 = videoPercent === 100;
			}
			if (resume && !is100) {
				window.adobeDataLayer.push({
					event: 'videoPause',
					content: {
						video: _this.getVideoPlayerData(id),
					}
				});
				pause = true;
			}
		});
		vjs.on('fullscreenchange', function() {
			let isFullscreen = vjs.isFullscreen(),
				event = '';
			if (isFullscreen) {
				event = 'fullScreenChange';
			} else {
				event = 'fullScreenChange';
			}
			window.adobeDataLayer.push({
				event,
				content: {
					video: _this.getVideoPlayerData(id),
				}
			});
		});
	},
	addVideoData: function (id) {
		_this.playerEventHandler(id);
	},
	init: function() {
		/*every time video is built, reattach*/
		$(document).off('ssmp.built.aa').on('ssmp.built.aa', '.videoPlayer', function(e, id) {
			_this.addVideoData(id);
		});
		/*if already exists, attach*/
		let videoData = $('.video-js');
		if (videoData && videoData.length) {
			for (let videoIndex = 0; videoIndex < videoData.length; videoIndex++) {
				if ($(videoData[videoIndex]).prop('id')) {
					let id = $(videoData[videoIndex]).prop('id');
					_this.addVideoData(id);
				}
			}
		}
	}
};

module.exports = _this;
