const AaSitePromoHelper = require('./aa-site-promo.helper');
const SitePromoFactory = require('./factory/index');
const Helper = require('../aa-helper');
const GlobalPage = require('../aa-global-page');
const _this = {
	tagArray: [],
	count:0,
	addSitePromoTagListener: function () {

		// Capture Site Promo Tagging
		_this.addSPListener(); // url based &cm_sp=XYZ tagging
		$(document).off('.cmspClick').on('mouseup.cmspClick keyup.cmspClick', 'a, button', function (event) { _this.addSPListener(event.target); }); // manual_cm_sp="XYZ" tagging

		if (aem_utag_data.page_type === 'product' || aem_utag_data.page_type === 'group' || aem_utag_data.page_type === 'mys') {
			// Product Detail Tagging
			_this.addProductSPListener();
		}
		if (typeof aem_utag_data.trans_orders !== 'undefined') {
			// Orders Tagging
			_this.addOrdersSPListener();
		}

		if (qVars.clientLibs.indexOf('myaccount') > -1) {
			// My Account Tagging
			_this.addMyAccountSPListener();
		} else if (qVars.clientLibs.indexOf('checkout') > -1) {
			// Checkout Tagging
			_this.addCheckoutSPListener();
		}

		if (aem_utag_data.page_type === 'device-link' || aem_utag_data.page_type.indexOf('ott') > -1) {
			// OTT pages Tagging
			_this.addOTTDeviceSPListener();
		}

		// Link Click Tagging
		_this.addLinkSPListener();

		// Sign In Tagging
		_this.addSignInSPListener();

		// Video Player Tagging
		if (aem_utag_data.page_type === 'product' || aem_utag_data.page_type === 'tv-program-guide' || aem_utag_data.page_type === 'shoplive' || $('.videoPlayer, .videoGalleryCarousel').length) {
			/*every time video is built, reattach*/
			$(document).off('ssmp.built.aasp').on('ssmp.built.aasp', '.videoPlayer', function(e, id) {
				_this.addVideoSPListener(id);
			});
			/*if already exists, attach*/
			let videoData = $('.video-js');
			if (videoData && videoData.length) {
				for (let videoIndex = 0; videoIndex < videoData.length; videoIndex++) {
					if ($(videoData[videoIndex]).prop('id')) {
						let id = $(videoData[videoIndex]).prop('id');
						_this.addVideoSPListener(id);
					}
				}
			}
		}

		// qError qSuccess qInfo Tagging
		_this.addNotifySPListener();

		// GDPR Opt In Tagging
		$(document).on('privacyBannerOptIn', function(event) { _this.captureSitePromoData(event.target, { linkType: 'event', linkCategory: 'MANAGE', linkSection: 'PERSONAL_DATA', linkName: 'ADS:ON|AN_PERS:ON|REW:ON' }); });
	},
	addSPListener: function (ele) {
		let linkType = 'pageLoad',
			aaSpData = _this.getSitePromoData();
		if (typeof ele !== 'undefined') {
			linkType = ele.localName;
			aaSpData = _this.getSitePromoData(ele);
		}
		if (aaSpData && aaSpData.includes('-_-') && aaSpData.split('-_-').length > 2) {
			_this.captureSitePromoData(ele, {
				linkType,
				linkCategory: aaSpData.split('-_-')[0],
				linkSection: aaSpData.split('-_-')[1],
				linkName: aaSpData.split('-_-')[2]
			});
		}
	},
	addCheckoutSPListener: function () {
		// cart
		$(document).on('click', '.btnCheckout, .btnSpeedBuy', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'CONTINUE', linkName: _this.cartCheckout(event.target) }); });
		$(document).on('click', '.btnAddToCart, .btnAddToWishlist', function(event) { if (!$(event.target).closest('.offCanvasSidebar').hasClass('addToCartOffCanvasSidebar')) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'SAVED_ITEMS', linkName: _this.cartSavedItems(event.target) }); }});

		// cart carousels
		$(document).on('click', 'body.cart .relatedItemsCarousel .carouselItem a', function(event) { _this.cartCarousel($(event.target), 'relatedItems'); });
		$(document).on('click', 'body.cart .recentlyViewedCarousel .carouselItem a', function(event) { _this.cartCarousel($(event.target), 'recentlyViewed'); });

		//your-information
		$(document).on('click', '.yourInfoCont .btnContinue', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_ACCOUNT', linkSection: _this.infoContTag(), linkName: 'CONTINUE' }); });
		$(document).on('change', '.yourInfoCont .sameAddress input[type="checkbox"]', function(event) { _this.sameAddressTag(event.target); });
		$(document).on('click', '.yourInfoCont .privacyPolicy, .yourInfoCont .cookiePolicy, .yourInfoCont .termsAndConditions, .yourInfoCont .cartEdit a, .yourInfoCont .additionalShippingInfo a', function(event) { _this.yourInfoToolsTag(event.target); });

		//payment-options
		$(document).on('click', '.giftPromo .btnAddGiftCard, .pgOrderReview .btnSaveCard, .pgPayOptions .btnSaveCard, .pgOrderReview .btnAddAnotherCard, .pgPayOptions .btnAddAnotherCard, .pgPayOptions .overlayNewCard .btnSave, .pgPayOptions .overlayNewCard .btnCancel, .pgPayOptions .btnContinue, .pgPayOptions .btnAddPromoCode, .overlayPromoCode .btnApplyPromoCode, .pgPayOptions .btnAddNewCard, .pgPayOptions .viewAll, .promoCode a, .overlayNewCard .btnSave, .overlayNewCard .btnCancel, .btnAddNewCard, .promoFormOuter .btnPromoCode, .btnRemoveVoucher, .voucherAppliedOuter .closeBtn', function(event) { _this.paymentMethodsTag(event.target); });

		//Speedbuy
		$(document).on('click', '.infoSpeedBuy .easypay', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'EZPAY', linkName: 'OFFER_DETAILS' }); });
		$(document).on('click', '.yourInfoCont .cartSummary .editCart', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'TOOLS', linkName: 'EDIT_CART' }); });

		//order review edit
		$(document).on('click', '.orderReviewCont .address .edit, .orderReviewCont .payment .edit, .orderReviewCont .cartSummary .editCart, .orderReviewCont .btnEditPaymentOffer, .orderReviewCont .billingOptions .edit', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: aem_utag_data.site_promo_path, linkSection: 'EDIT_ORDER', linkName: _this.editOrderReview(event.target) }); });
		$(document).on('click', '.cartSummary .addInfo:last p a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'TOOLS', linkName: 'SHIPPING_INFORMATION' }); });
		$(document).on('click', '.cartSummary .btnSubmitOrder', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: aem_utag_data.cust_type, linkName: 'SUBMIT_ORDER' }); });
		$(document).on('click', '.product .shipping .edit, .installment .editOption .edit', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: aem_utag_data.site_promo_path, linkSection: 'EDIT_ITEM', linkName: _this.editItems(event.target) }); });
		$(document).on('click', '.editCreditCard', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PAYMENT', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'UPDATE_CARD' }); });
		$(document).on('click', '.payOptions .viewAllPayments a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'TOOLS', linkName: 'VIEW_ALL_PAYMENT_METHODS' }); });
		$(document).on('click', '.cartSummary .linkTxtEP', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'TOOLS', linkName: 'EASY_PAY_BILLING_TERMS' }); });
		$(document).on('click', '.qsfButtons .btnAccept', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'QCARD', linkSection: 'TERMS', linkName: 'Accept' }); });
		$(document).on('click', '.qsfButtons .btnDecline', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'QCARD', linkSection: 'TERMS', linkName: 'Decline' }); });
		$(document).on('click', '.editPhoneNbr:visible', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'TOOLS', linkName: 'EDIT_ADDRESS' }); });
		$(document).on('click', '.payOptions .btnAddIban', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory:  aem_utag_data.site_promo_path, linkSection: 'DIRECT_DEBIT', linkName: 'SETUP' }); });

		//order review eQuickScreen and Qcard Offer Alert
		$(document).on('click', '.eQuickScreenAlert .btnEQuickScreenAccept, .eQuickScreenAlert .btnEQuickScreenNotNow, .eQuickScreenAlert .linkEQuickScreenDontShowAgain, .eQuickScreenAlert .eQuickScreenDisclaimer a, .eQuickScreenAlert .incentiveText a, .qCardOfferAlert .qCardLearnMore', function(event) { _this.eQuickScreenTag(event.target); });

		//EquickScreen Application
		$(document).on('click', '.eQuickscreenCont .btnContinue, .eQuickscreenCont .btnCancel, .eQuickscreenCont .accountOpening, .eQuickscreenCont .ccAgreement, .eQuickscreenCont .electronics, .eQuickscreenCont .privacyPolicy, .eQuickscreenCont .infoDisclosuresLink .disclosures', function(event) { _this.eQuickScreenAppTag(event.target); });
		$(document).on('change', '.eQuickscreenCont .checkRequest input[type="checkbox"]', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'QCARD', linkSection: 'CHECKBOX', linkName: $(event.target).prop('checked') ? 'CONFIRM' : 'REMOVE' }); });

		//Successful adding Qcard overlay
		$(document).on('click', '.qsfOffersAvaialable .qsfBtn a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: aem_utag_data.site_promo_path, linkSection: 'QCARD', linkName: $(event.target).hasClass('btnCont') ? 'NO_CONTINUE' : 'YES_VIEW_OFFERS' }); });

		//shipping options
		$(document).on('click', '.shipOptionsCont .continue .btnContinue', function(event) { _this.shippingOptions(event.target, 'CONTINUE'); });
		$(document).on('click', '.shipOptionsCont .exLinks a', function(event) { _this.shippingOptions(event.target, 'INFO'); });

		//Checkout, Unable to ship
		$(document).on('click', '.overlayUnableToShip .btn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'TOOLS', linkName: ($(event.target).hasClass('btnSave') ? 'EDIT_SHIPPING_ADDRESS' : 'RETURN_TO_CART') }); });

		// Payment Conflict
		$(document).on('click', '.overlayGenericError.paymentConflictOptions .btn, .overlayGenericError.paymentConflictOffers .btn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MISMATCH', linkSection: 'CHANGE_' + ($(event.target).parents().hasClass('paymentConflictOptions') ? 'PAYMENT' : 'OFFER'), linkName: ($(event.target).hasClass('btnSave') ? 'CONTINUE' : 'CANCEL') }); });

		//Checkout, payment offers
		$(document).on('click', '.paymentOffers .btnContinue', function(event) { _this.paymentOffersTag(event.target, 'CONTINUE'); });
		$(document).on('click', '.paymentOffers .heading a', function(event) { _this.paymentOffersTag(event.target, 'EZPAYDETAILS'); });

		//sign in easy pay error
		$(document).on('click', '.dqNotification #pageContent .btn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'SIGN_IN', linkSection: 'ERROR', linkName: 'VIEW_EASY_PAY_ORDERS' }); });

		//login, account locked
		$(document).on('click', '.accountLockScreen a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'SIGNIN', linkSection: 'ERROR', linkName: $.trim($(event.target).text().toUpperCase()).replace(' ', '_') }); });

		//promo code accordion
		$(document).on('click', '.overlayPromoCode .accordionTitle, .disclaimerLink span', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: aem_utag_data.site_promo_path, linkSection: 'PROMO_CODE', linkName: 'QUALIFICATIONS' });  });

		// order confirmation
		$(document).on('click', '.orderConfirmation .viewReceipt', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: aem_utag_data.site_promo_path, linkSection: 'ORDER', linkName: 'VIEW_RECEIPT' }); });
		$(document).on('click', '.orderConfirmation .newCustomerPwd .btnContinue, .orderConfirmation .existingCustomer a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: aem_utag_data.site_promo_path, linkSection: 'CREATE_PASSWORD', linkName: 'SAVE' }); });
		$(document).on('click', '.orderConfirmation .orderStatus a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: aem_utag_data.site_promo_path, linkSection: 'ORDER', linkName: 'CHECK_STATUS' }); });
		$(document).on('click', '.credits input:checkbox', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: aem_utag_data.site_promo_path, linkSection: 'CREDITS', linkName: $(event.target).prop('checked') ? 'APPLY' : 'REMOVE' }); });
		$(document).on('click', '#orderConfirmationPromotionalEmailSignMeUpButton', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE', linkSection: 'EDIT', linkName: 'EMAIL:OPT_IN' }); });
		$(document).on('click', '.orderConfirmation .bottomBox .btnContinue', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: aem_utag_data.site_promo_path, linkSection: 'TOOLS', linkName: 'CONTINUE_SHOPPING' }); });

		//prop 65
		$(document).on('click', '.prop65 .warningOverlay', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'PROP_65', linkSection: aem_utag_data.cust_type, linkName: 'OPEN_WARNING' }); });

		//qCard
		$(document).on('click', '.chooseOption .linkViewTerms', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'QCARD', linkName: 'DETAILS:' + $(event.target).prev('label').children('input').data('term') + '_MONTH_FINANCING' }); });
		$(document).on('click', '.qsfOfferStandIn button', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'QCARD', linkSection: 'TERMS', linkName: _this.qCardAcceptDecline(event.target) }); });

		//update CreditCard
		$(document).on('click', '.expDateAndCVV .btnSave, .expDateAndCVV .btnCancel', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: aem_utag_data.site_promo_path, linkSection: 'UPDATE_EXPIRATION_DATE', linkName: $.trim($(event.target).text()).toUpperCase() }); });

		//tila overlay
		$(document).on('click', '.tilaOverlay .btnDecline', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'TRUTH_IN_LENDING_DISCLOSURE', linkName: 'BACK' }); });
		$(document).on('click', '.tilaOverlay .btnAccept', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'TRUTH_IN_LENDING_DISCLOSURE', linkName: 'AGREE' }); });

		//add new direct debit overlay
		$(document).on('click', '.overlayAddIban .btnSave', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: aem_utag_data.site_promo_path, linkSection: 'DIRECT_DEBIT', linkName: 'SAVE' }); });
		$(document).on('click', '.overlayAddIban .btnCancel', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: aem_utag_data.site_promo_path, linkSection: 'DIRECT_DEBIT', linkName: 'CANCEL' }); });
		$(document).on('click', '.overlayAddIban .termsAndConditions', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'TOOLS', linkName: 'TERMS_AND_CONDITIONS' }); });
		$(document).on('click', '.overlayAddIban .privacyPolicy', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'TOOLS', linkName: 'PRIVACY' }); });

		//Phone Required overlay
		$(document).on('click', '.overlayPhoneRequired .btnSave', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'TOOLS', linkName: 'EDIT_PHONE_NUMBER' }); });
		$(document).on('click', '.overlayPhoneRequired .btnCancel', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'TOOLS', linkName: 'RETURN_TO_CART' }); });

		$(document).on('click', '.linkContinueShopping', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'CONTINUE', linkName: 'SHOPPING' }); });
		$(document).on('click', '.btnRemove', function(event) {  _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: _this.cartRemoveItem(event.target), linkName: 'REMOVE_ITEM' }); });
		$(document).on('click', '.cartItem img, .cartItem .prodDescr a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'EDIT_ITEM', linkName: 'VIEW_PRODUCT_PAGE' }); });
		$(document).on('click', '.savedItem .row a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'SAVED_ITEMS', linkName: 'VIEW_PRODUCT_PAGE' }); });
		$(document).on('change', '#removeSavedItems', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'SAVED_ITEMS', linkName: _this.removeSavedItemsGallery(event.target) }); });
		$(document).on('click', '.btnSaveForLater', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'EDIT_ITEM', linkName: 'SAVE_FOR_LATER' }); });
		$(document).on('change', '.cartItem .qty select', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'EDIT_ITEM', linkName: ($(event.target).val() === '0' ? 'REMOVE_QUANTITY' : 'CHANGE_QUANTITY') }); });
		$(document).on('click', '.btnGiftOption', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'EDIT_ITEM', linkName: 'GIFT' }); });
		$(document).on('click', '.easyPay .fancybox', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'EZPAY', linkName: 'OFFER_DETAILS' }); });
		$(document).on('click', '.btnMoveAllToCart', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'SAVED_ITEMS', linkName: 'ADD_ALL' }); });
		$(document).on('click', '.btnRmvSavedItems', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'SAVED_ITEMS', linkName: 'REMOVE_ALL' }); });
		$(document).on('click', '.btnEditPersonalization', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'EDIT_ITEM', linkName: 'EDIT_ADDITIONAL_INFO' }); });
		$(document).on('click', '.termsAndCond a:first-child', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'TOOLS', linkName: 'TERMS_AND_CONDITIONS' }); });
		$(document).on('click', '.termsAndCond a:nth-child(2)', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'TOOLS', linkName: 'PRIVACY' }); });
		$(document).on('click', '.qsfOffer a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'QCARD', linkName: _this.cartQcard(event.target) }); });
		$(document).on('click', '.cartCont .btnGiftOption, .cart .overlayGiftOptions .btnSave, .cart .overlayGiftOptions .btnCancel, .cart .giftOptions .btnGiftOption, .cart .giftOptions .giftOptionEditMessage, .cart .overlayGiftOptions .btnSave', function() { _this.giftOptionsTag(event.target); });
		$(document).on('click', '.btnSeeSimilar', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'SAVED_ITEMS', linkName: 'SEE_SIMILAR_ITEMS' }); });
		$(document).on('click', '.offCanvasSidebarToggleForSavedItems.btnEdit', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'CHECKOUT', linkSection: 'SAVED_ITEMS', linkName: 'EDIT' }); });
		$(document).on('click', '.offCanvasSidebar .btn', function(event) { if (!$(event.target).closest('.offCanvasSidebar').hasClass('addToCartOffCanvasSidebar')) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ITEM_EDIT', linkSection: 'CHANGE_OPTIONS', linkName: 'SAVE_CHANGES' }); }});
		$(document).on('click', '.emptyCartSignIn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'EMPTY_CART', linkSection: 'ACCOUNT', linkName: 'SIGNIN' }); });
		$(document).on('click', '.emptyCartTodaysDeal', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'EMPTY_CART', linkSection: 'OPTIONS', linkName: 'SHOP_DEALS' }); });
		$(document).on('click', '.offCanvasSidebar  .closeBtn', function(event) { if (!$(event.target).closest('.offCanvasSidebar').hasClass('addToCartOffCanvasSidebar')) {  _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ITEM_EDIT', linkSection: 'CHANGE_OPTIONS', linkName: 'CANCEL' }); }});
		$(document).on('click', '.offCanvasWrapper', function(clickedOn) { if (clickedOn.target === clickedOn.currentTarget && !$(clickedOn.target).find('.offCanvasSidebar').hasClass('addToCartOffCanvasSidebar')) { _this.captureSitePromoData(clickedOn.target, { linkType: clickedOn.localName, linkCategory: 'ITEM_EDIT', linkSection: 'CHANGE_OPTIONS', linkName: 'CANCEL' }); } });
		$(document).on('click', '.offCanvasSidebar .buyBoxSelect li', function(event) { if (!$(event.target).closest('.offCanvasSidebar').hasClass('addToCartOffCanvasSidebar')) { _this.generalProdClickHandler($(event.target).find('.swatch')[0]); }});

		// paypal
		$(document).on('paypal.click', function (event) {
			let linkCategory = aem_utag_data.site_promo_path,
				linkSection = 'CONTINUE',
				linkName = '',
				linkType = 'paypal';
			if (aem_utag_data.page_type === 'cart') {
				linkCategory = 'CHECKOUT';
				linkName = 'PAYPAL';
				window.localStorage.setItem('promoCat', 'PAYPAL_CHECKOUT');
			} else if (aem_utag_data.page_id === 'CHECKOUT: PAYMENT METHOD') {
				linkName = 'ADD_PAYPAL';
			} else if (aem_utag_data.page_id === 'CHECKOUT: REVIEW ORDER') {
				linkCategory = 'MANAGE_ACCOUNT';
				linkSection = 'PAYPAL';
				linkName = 'CONTINUE';
			}
			_this.captureSitePromoData(event.target, {
				linkType,
				linkCategory,
				linkSection,
				linkName
			});
		});

		$(document).on('fancybox.beforeClose', function(e, params){
			if (e === undefined) {
				// trhowing a silly error so both index and element are used in this scope and I don't get the "no unsused variables" error from eslint
				throw new Error('Expected element "e" to be defined');
			}
			//this allows us to detect close by button and close by click outside of overlay and know which overlay was closed.
			if (params !== 'undefined' && params.overlayClass !== 'undefined'){
				let linkName = '';
				if (params.overlayClass.indexOf('overlayEditShippingAddress') > -1) {
					linkName = _this.actAddress(e, 'EDITCLOSE');
					if (linkName !== '') {
						_this.captureSitePromoData(e, { linkType: e.localName, linkCategory: 'MANAGE_ADDRESS', linkSection: aem_utag_data.site_promo_path, linkName });
					}
				} else if (params.overlayClass.indexOf('overlayAddShippingAddress') > -1){
					if ((typeof e.currentTarget.activeElement.className === 'undefined') || e.currentTarget.activeElement.className.indexOf('btn') === -1){
						// overlay is closed by (X) or ESC or click outside
						linkName = _this.actAddress(e, 'ADDCLOSE');
						if (linkName !== '') {
							_this.captureSitePromoData(e, { linkType: e.localName, linkCategory: 'MANAGE_ADDRESS', linkSection: aem_utag_data.site_promo_path, linkName });
						}
					}
				} else if (params.overlayClass.indexOf('overlayEditBillingAddress') > -1){
					linkName = _this.actAddress(e, 'BILLCLOSE');
					if (linkName !== '') {
						_this.captureSitePromoData(e, { linkType: e.localName, linkCategory: 'MANAGE_ADDRESS', linkSection: aem_utag_data.site_promo_path, linkName });
					}
				} else if (params.overlayClass.indexOf('overlayGiftOptions') > -1) {
					_this.giftOptionsCancelTag(e);
				}
			}
		});
	},
	addLinkSPListener: function() {
		$(document).off('.aaspLinkClick');//remove old clicks incase tagging is being reattached due to ajax load.
		$(document).on('mouseup.aaspLinkClick', 'section.qModule a:not([href*="#scroll"]):not([data-component-type="MASTHEAD_SKIP_NAVIGATION"] a, #shopByCategory, #mobileMenu, .mainMenuMobile, #btnMastheadSearch, .refinementDiv .extended a, .facetedMulti .moreOptions a, .facetedMulti .moreOptions button, .plContent .sortLink li a, .extended a, a.openMobileFilter, .watchLiveLink a, .iroaDates option:not(selected), .iroaListItems .galleryItem a, .iroaAddNavComp .qComponent .btn, .daySelection li a, .hourListWrapper a, .weekSelection a, .modeLinks a, .reminderLayer .btn, .weeklyView .printLink, .programView .carouselItem a, .fancybox-close, .show .open, .wishListCard a, .brandListing a, .remindMeLink, .iroa-navigation .iroaTimeZoneSelect a, .timeZoneSelection li a, [data-component-type="BREADCRUMBS"] a, [data-module-type="PAGE_HEADER"] a, [data-module-type="PRODUCT_LISTING_HEADER"] a, [data-module-type="RECIPE_LISTING_HEADER"] a, [data-component-type="CORE_BREADCRUMB"] a, .myAccountArea [data-module-feature-name="MYACCOUNT_LANDING_PAGE_LINKS"] a, .accountArea [data-module-type="ORDER_STATUS"] a, [data-module-type="WRITE_REVIEW"] a)', function(e) { _this.generalLinkSPClick(e.target, e); });
		$(document).on('click.aaspLinkClick', 'section.qModule .onAirChannel .channelMenu ul li, section[data-module-type="CORE_MODULE_CONTAINER"] .carArrow button', function(e) { _this.generalLinkSPClick(e.target, e); });

		$(document).on('btmDrawerOpen.aaspLinkClick', function(event) { _this.captureSitePromoData(event.target, { linkType: 'event', linkCategory: 'BOTTOMDRAWER', linkSection: 'BANNER', linkName: 'EXPAND' }); });
		$(document).on('btmDrawerClose.aaspLinkClick', function(event) { _this.captureSitePromoData(event.target, { linkType: 'event', linkCategory: 'BOTTOMDRAWER', linkSection: 'BANNER', linkName: 'COLLAPSE' }); });
		$(document).on('ftdChannelsOpen.aaspLinkClick', function(event) { _this.captureSitePromoData(event.target, { linkType: 'event', linkCategory: 'MH', linkSection: 'CHANNELS', linkName: 'DISPLAY' }); });
		$(document).on('ftdChannelsClose.aaspLinkClick', function(event) { _this.captureSitePromoData(event.target, { linkType: 'event', linkCategory: 'MH', linkSection: 'CHANNELS', linkName: 'HIDE' });  });
		$(document).on('mouseup.aaspLinkClick', 'a.openMobileFilter', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'REFINEMENTS', linkSection: 'PRODUCT_LISTING_LIST', linkName: 'OPEN_FILTERS' }); });
		$(document).on('emailSignupSuccessful.aaspLinkClick', function(event) { _this.captureSitePromoData(event.target, { linkType: 'event', linkCategory: 'MANAGE', linkSection: 'EDIT', linkName: 'SIGN_UP' }); });

		$(document).on('mouseup.aaspLinkClick', '.brandListing a', function(event) { _this.captureSitePromoData(event.target, { linkType: 'event', linkCategory: 'BRANDS_A_TO_Z', linkSection: qString.condenseForTag($('.pageBreadcrumb a:eq(1)').text()), linkName: qString.condenseForTag($(event.target).text()) }); });

		$(document).on('click.aaspLinkClick', '.wishShare a', function(event) { _this.wishlistShareTags(event.target,'share'); });
		$(document).on('click.aaspLinkClick', '#shareWishListForm button.btnSend', function(event) { _this.wishlistShareTags(event.target,'send'); });

		// Add To Cart Confirmation Overlay Button clicks
		$(document).on('click.aaspLinkClick', '.overlayAdConfirm .btn-primary', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'PRODUCT_CONFIRMATION', linkSection: 'AUTO_DELIVERY', linkName: 'YES' }); });
		$(document).on('click.aaspLinkClick', '.overlayAdConfirm .btn-secondary', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'PRODUCT_CONFIRMATION', linkSection: 'AUTO_DELIVERY', linkName: 'NO_ADD_SINGLE_SHIPMENT' }); });
		$(document).on('click.aaspLinkClick', '.overlayAdvancedOrderConfirm .btn-primary', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'PRODUCT_CONFIRMATION', linkSection: 'ADVANCED_ORDER', linkName: 'YES' }); });
		$(document).on('click.aaspLinkClick', '.overlayAdvancedOrderConfirm .btn-secondary', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'PRODUCT_CONFIRMATION', linkSection: 'ADVANCED_ORDER', linkName: 'NO' }); });
		$(document).on('click.aaspLinkClick', '.overlayWaitlistConfirm .btn-primary', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'PRODUCT_CONFIRMATION', linkSection: 'WAITLIST', linkName: 'YES' }); });
		$(document).on('click.aaspLinkClick', '.overlayWaitlistConfirm .btn-secondary', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'PRODUCT_CONFIRMATION', linkSection: 'WAITLIST', linkName: 'NO' }); });
		$(document).on('click.aaspLinkClick', '.overlayProtectionPlans .btnAccept', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'PRODUCTDETAIL', linkSection: 'ALLSTATE', linkName: 'CONTINUE_TO_CART:ACCEPTED' }); });
		$(document).on('click.aaspLinkClick', '.overlayProtectionPlans .btnDecline', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'PRODUCTDETAIL', linkSection: 'ALLSTATE', linkName: 'CONTINUE_TO_CART:DECLINED' }); });
		$(document).on('click.aaspLinkClick', '.overlayProtectionPlans .st-link', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ALLSTATE', linkSection: 'MORE_INFO', linkName: 'OVERLAY' }); });

		// Newsletter edit page clicks
		$(document).on('click.aaspLinkClick', '#newsletterProfileEditSubmit', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE', linkSection: 'NEWSLETTER_REGISTRATION', linkName: 'SUBMIT' }); });
		$(document).on('click.aaspLinkClick', '#newsletterProfileEditCancel', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE', linkSection: 'NEWSLETTER_REGISTRATION', linkName: 'CANCEL' }); });

		// GDPR Tags
		$(document).on('click.aaspLinkClick', '#gdprOptOut button[type="submit"]', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE', linkSection: 'PERSONAL_DATA', linkName: _this.gdprTags(event.target) }); });
		$(document).on('gdpr.success.aaspLinkClick', 'form.gdprForm', function(event) { _this.captureSitePromoData(event.target, { linkType: 'event', linkCategory: 'MANAGE', linkSection: 'PERSONAL_DATA', linkName: _this.gdprTags(event.target) }); });

		/* Recipes */
		$(document).on('click.aaspLinkClick', '.recipeMediaStage .recipeThumbGroup .mediaThumb img, .recipeDetails .recipePrint a', function(event) { _this.recipeTags(event.target); });
		$(document).on('click.aaspLinkClick', '.errorBtn .text-right a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ERROR', linkSection: 'REDIRECT', linkName: 'HOME' }); });
		$(document).on('click.aaspLinkClick', '.errorBtn .text-left a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ERROR', linkSection: 'REDIRECT', linkName: 'CONTACT_US' }); });

		// MH Header Menu
		$(document).on('click.aaspLinkClick', '.shopByMobileSub .menuHeader', function(event) { if ($('[data-component-type="MASTHEAD_POP"]').filter(':visible').length <= 1 || $(event.target).hasClass('opened')) { return; } _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MH', linkSection: 'MENU', linkName: _this.headerTag(event.target) }); });
		$(document).on('click.aaspLinkClick', '#mobileMenu, .mainMenuMobile', function(event) { _this.openTag(event.target); });

		// MH Search
		$(document).on('focus.aaspLinkClick', '#txtMastheadSearch', function(event) { _this.searchTag(event.target,'focus'); });
		$(document).on('submit.aaspLinkClick', 'form.searchForm', function(event) { _this.searchTag(event.target,'submit'); });
		$(document).on('click.aaspLinkClick', '#btnMastheadSearch', function(event) { _this.searchTag(event.target,'toggle'); });

		//Footer
		$(document).on('click.aaspLinkClick', 'form.emailSignUp .btnSend', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'FOOTER', linkSection: 'EMAIL', linkName: 'SIGNUP' }); });
		$(document).on('click.aaspLinkClick', '.footerBackToTop a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'FOOTER', linkSection: 'NAVIGATION', linkName: 'PAGE_TOP' }); });
		$(document).on('click.aaspLinkClick', '.viewFullFooter a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'FOOTER', linkSection: 'FULL_FOOTER', linkName: ($('.viewFullFooter').hasClass('opened')) ? 'CLOSE' : 'EXPAND' }); });

		//Refinements
		$(document).on('click.aaspLinkClick', '.facetedMulti h3', function(event) { _this.openorcloseTag(event.target); });
		$(document).on('click.aaspLinkClick', '.facetedMulti .moreOptions a, .facetedMulti .moreOptions button', function(event) { _this.moreTag(event.target); });
		$(document).on('click.aaspLinkClick', '.extended a', function(event){ _this.valMoreTag(event.target); });

		// Sort products on PDP and Wishlist
		$(document).on('click.aaspLinkClick', '.plContent .sortLink li a, .wishSort .sortLink li a', function(event){ _this.sortTag(event.target); });

		// Image pagination
		$(document).on('click.aaspLinkClick', '.nxtPage, .prvsPage', function(event){ _this.imgTag(event.target); });

		/* IROA */
		if (aem_utag_data.page_type === 'iroa'){
			$(document).on('change.aaspLinkClick', '.iroaSelectMenu .iroaChannels select', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'TOOLS', linkSection: 'CHANNEL_SELECTOR', linkName: Helper.getChannelSc(event.target.value) }); });
			$(document).on('change.aaspLinkClick', '.iroaSelectMenu .iroaDates select',function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'TOOLS', linkSection: 'DATE_SELECTOR', linkName: _this.iroaDateTag(event.target) }); });
			$(document).on('change.aaspLinkClick', '.iroaSelectMenu .iroaShows select', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'TOOLS', linkSection: 'SHOW_SELECTOR', linkName: event.target.value }); });
			$(document).on('click.aaspLinkClick', '.watchLiveLink a, .remindMeLink', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'TOOLS', linkSection: 'OPTIONS', linkName: _this.iroaListLinkTag(event.target) }); });
			$(document).on('click.aaspLinkClick', '.iroaListItems .galleryItem a', function(event) { _this.iroaItemTag(event.target); });
			$(document).on('click.aaspLinkClick', '.iroaAddNavComp .qComponent .btn', function(event) { if ($(event.target).closest('.qComponent')) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'TOOLS', linkSection: 'BUTTONS', linkName: ($(event.target).closest('.qComponent').attr('data-feature-name') !== undefined ? $(event.target).closest('.qComponent').attr('data-feature-name') : '') }); } });
			$(document).on('click.aaspLinkClick', '.iroaTimeZoneSelect a', function(event) { if ($(event.target).parents('.iroaTimeZoneSelect')) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'TOOLS', linkSection: 'TIMEZONE', linkName: ($(event.target).attr('data-timezoneshort') !== undefined ? $(event.target).attr('data-timezoneshort') : '').replace('S','') }); } });
		}

		/* TV Program Guide */
		if (aem_utag_data.page_type === 'tv-program-guide'){
			$(document).on('change.aaspLinkClick', '.daySelection select', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'TOOLS', linkSection: 'DATE_SELECTOR', linkName: _this.tvpgDaySelectionTag(event.target) }); });
			$(document).on('click.aaspLinkClick', '.hourListWrapper a, .weekSelection a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'TOOLS', linkSection: 'TIME_SELECTOR', linkName: _this.tvpgNavArrowsShowsTag(event.target) }); });
			$(document).on('click.aaspLinkClick', '.modeLinks a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'TOOLS', linkSection: 'GUIDE', linkName: $('.modeLinks').children().not('.selected').text().toUpperCase() }); });
			$(document).on('click.aaspLinkClick', '.reminderLayer .btn', function(event) { if ($(event.target).parents('.reminderLayer')) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'TOOLS', linkSection: 'SET_REMINDERS', linkName: 'SAVE' }); } });
			$(document).on('click.aaspLinkClick', '.show .more', function(event){ _this.tvpgMoreInfoTag(event.target); });
			$(document).on('click.aaspLinkClick', '.show [data-uvid], .showList .reminderLink', function(event) { _this.tvpgWatchTag(event.target); });
			$(document).on('click.aaspLinkClick', '.resultsView .reminderLink', function(event) { _this.tvpgResultsRemindMeTag(event.target); });
			$(document).on('click.aaspLinkClick', '.watchShow [data-uvid], .showReminder .reminderLink', function() { _this.tvpgMoreWatchShowTag(event.target); });
			$(document).on('click.aaspLinkClick', '.weeklyView .printLink', function(event) { _this.tvpgPrintTag(event.target); });
			$(document).on('click.aaspLinkClick', '.programView .carouselItem a', function(event) { _this.tvpgItemTag(event.target); });
			$(document).on('keypress.aaspLinkClick', '.programGuideSearchForm .form-group input', function(e) { if (e.which === 13){ _this.tvpgSearchTag(e.target, 'keypress'); } });
			$(document).on('click.aaspLinkClick', '.programGuideSearchForm .form-group button', function(event) { _this.tvpgSearchTag(event.target); });
			$(document).on('tvpg-search-complete.aaspLinkClick', function(e, oData) { _this.tvpgSearchTag(e.target, oData);});
			$(document).on('click.aaspLinkClick', '.channelMenu a', function(event) { _this.tvpgChnSearchTag(event.target); });
			$(document).on('click.aaspLinkClick', '.showReminder .list', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: Helper.getChannelSc($('.activeOverlay').closest('ul').data('channelcode') || $('.channels .activeChannel').data('channelcode')), linkSection: 'MORE_INFO', linkName: ($(event.target).hasClass('open') ? 'MORE' : 'LESS') + '_TIMES' }); });
			$(document).on('click.aaspLinkClick', '.resultsView .popular a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'TOOLS', linkSection: 'POPULAR_SEARCH', linkName: qString.condenseForTag($(event.target).text()) }); });
			$(document).on('onDatePickerSelect.aaspLinkClick', function(e, oData){ if (typeof oData === 'object') { _this.captureSitePromoData(e.target, { linkType: e.target.localName, linkCategory: 'TOOLS', linkSection: 'DATE_SELECTOR', linkName: (oData.iIdx === 0 ? 'TODAY' : (oData.iIdx < 0 ? (oData.iIdx === -1 ? 'YESTERDAY' : Math.abs(oData.iIdx) + '_DAYS_AGO') : (oData.iIdx === 1 ? 'TOMORROW' : 'IN_' + Math.abs(oData.iIdx) + '_DAYS'))) }); } });
			$(document).on('click.aaspLinkClick', '.timeZoneSelection li:not(.selected) a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'TOOLS', linkSection: 'GUIDE', linkName: _this.tvpgTimeZoneTag(event.target) }); });
			$(document).on('change.aaspLinkClick', '.timeZoneSelection .dropDownSelect select', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'TOOLS', linkSection: 'GUIDE', linkName: _this.tvpgTimeZoneTag(event.target) }); });
		}

		/* Breadcrumbs */
		$(document).on('mouseup.aaspLinkClick', '[data-component-type="BREADCRUMBS"] a, [data-module-type="PAGE_HEADER"] a, [data-module-type="PRODUCT_LISTING_HEADER"] a, [data-module-type="RECIPE_LISTING_HEADER"] a, [data-component-type="CORE_BREADCRUMB"] a', function(e){ _this.breadcrumbTags(e.target,e); });
	},
	addMyAccountSPListener: function () {
		//Myaccount Landing Page
		if (aem_utag_data.page_type === 'myaccount-landing') {
			$(document).on('mouseup', '[data-module-feature-name="MYACCOUNT_LANDING_PAGE_LINKS"] a', function(e){ _this.landingLinks(e.target, e), _this.myAccountSignInConversion(e.target, false); }); //When a visitor clicks on an option on the MY ACCOUNT Landing Page
		}

		$(document).on('click', '.wishListCard .productEdit a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'WISHLIST', linkSection: 'TOOLS', linkName: 'EDIT' }); });
		$(document).on('click', '.wishListCard .btnAddToCart', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'WISHLIST', linkSection: 'ADDTO', linkName: 'CART' });  });

		$(document).on('click', '.contentLoginForm form.signInArea button.btnContinue', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE', linkSection: 'PERSONAL_DATA', linkName: 'CONTINUE:SELF' }); });
		$(document).on('contentLogin.without',function(event) { _this.captureSitePromoData(event.target, { linkType: 'event', linkCategory: 'MANAGE', linkSection: 'PERSONAL_DATA', linkName: 'CONTINUE:SELF_NO_PASSWORD' }); });
		$(document).on('click', 'form.dataBehalf button.btnContinue', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE', linkSection: 'PERSONAL_DATA', linkName: 'CONTINUE:OTHER_USER' }); });
		$(document).on('click', '.contactForm form.dataForm button.btnSubmit', function(event) {
			let dataWrap = $(event.target).closest('.dataRequest');
			if (dataWrap.hasClass('bMy')) {
				_this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE', linkSection: 'PERSONAL_DATA', linkName: 'SUBMIT:SELF' });
			} else if (dataWrap.hasClass('bSE')) {
				_this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE', linkSection: 'PERSONAL_DATA', linkName: 'SUBMIT:OTHER_USER' });
			}
		});

		$(document).on('click', '.wishListCard .removeItemWL', function(event) { if ($(event.target).hasClass('removeItemWL')) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'WISHLIST', linkSection: 'TOOLS', linkName: 'DELETE' }); } });

		//Open Orders
		$(document).on('change', '.openOrdersOverlay #openOrder-selectAll', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PAYMENT', linkSection: 'TOOLS', linkName: 'ALL_OPEN_ORDERS' }); });
		$(document).on('click', '.openOrdersOverlay .btnSave', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PAYMENT', linkSection: 'ORDER_DETAIL', linkName: 'UPDATE' }); });
		$(document).on('click', '.openOrdersOverlay .btnCancel', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PAYMENT', linkSection: 'ORDER_DETAIL', linkName: 'CANCEL' }); });
		$(document).on('openOrders.tagging', function() {
			$('.openOrdersOverlay a.fancybox-close').off('.aaspOverlay').on('click.aaspOverlay', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PAYMENT', linkSection: 'ORDER_DETAIL', linkName: 'CLOSE_UPDATE_ORDERS' }); });
		});

		//Create Security Questions
		if (qVars.page === 'create-security-questions') {
			$(document).on('click', '.btnCreateSecQues', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_ACCOUNT', linkSection: 'ACCOUNT_SETTINGS', linkName: 'SUBMIT' }); }); // When a visitor clicks submit
			$(document).on('click', '.btnRemMeLater', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_ACCOUNT', linkSection: 'ACCOUNT_SETTINGS', linkName: 'REMIND_ME_NEXT_TIME' }); }); // When a visitor clicks remind me next time
		}

		//Order Status
		if (qVars.page === 'order-status') {
			if (document.location.href.indexOf('writereview') < 0) {
				$(document).on('change', '#orderStatusListSort', function(event) { let $option = $(event.target).find('option:selected'); _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'VIEW_OPTIONS', linkSection: 'SELECT_PAGE', linkName: qString.condenseForTag($option.text()).replace('-','_') }); }); // navigation links (mobile)
				$(document).on('click', '.orderTypeFilter a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'VIEW_OPTIONS', linkSection: 'SELECT_PAGE', linkName: qString.condenseForTag($(event.target).text()).replace('-','_') }); }); // navigation links
				$(document).on('change', '#orderStatusListSortByDate select, #orderStatusListSortByDateMobile select', function(event) { let $option = $(event.target).find('option:selected');  _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'VIEW_OPTIONS', linkSection: 'DATE_FILTER', linkName: ($option.data('date-range-short-name').toString().search(/(All|Past)/gi) > -1 ? qString.condenseForTag($option.text()).replace('-','_') : 'YEAR') }); }); // date filter selection
				$(document).on('click', '.osSearchBtn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'VIEW_OPTIONS', linkSection: 'TOOLS', linkName: 'SEARCH_FOR_ORDER' }); }); // search button
				$(document).on('click', '.adPlans .viewEditLink a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PLANS', linkSection: 'ACCOUNT_MANAGEMENT', linkName: ($(event.target).hasClass('editable') ? 'VIEW/EDIT_PLAN' : 'VIEW_PLAN') }); }); // Auto Delivery 'View/Edit Plan' and 'View Plan'
				$(document).on('click', '.orderPanel .orderLineItem .trackingBtn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'TOOLS', linkName: 'TRACK_PACKAGE' }); });
				$(document).on('click', '.orderPanel .orderLineItem .reOrderBtn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'VIEW_OPTIONS', linkSection: 'TOOLS', linkName: 'REORDER' }); });
				$(document).on('click', '.orderPanel .orderLineItem .reviewBtn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'TOOLS', linkName: 'WRITE_REVIEW' }); });
				$(document).on('click', '.orderPanel .orderLineItem .writeReview', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'TOOLS', linkName: 'WRITE_REVIEW' }); });
				$(document).on('click', '.orderStatusPageNumbers button', function(event) { _this.osPagination(event.target); }); // pagination
				$(document).on('change', '[id^="hideCancelledOrders-"]', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'VIEW_OPTIONS', linkSection: 'HIDE_CANCELLED', linkName: $(event.target).prop('checked') ? 'CHECK' : 'UNCHECK' }); }); // hide canceled orders checkbox
				$(document).on('click', '.orderPanel a[href*="order-detail"]', function(event) { _this.viewOrder(event.target); });
				$(document).on('change', '.ezPayToggle [name="switchA"]', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_EASY_PAY', linkSection: 'ACCOUNT_MANAGEMENT', linkName: _this.viewOrderBy(event.target) }); });
			} else {
				$(document).on('click', '.orderPanel .orderLineItem .reviewBtn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_REVIEWS', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'WRITE_A_REVIEW' }); });
			}
		}

		//Create Account
		if (qVars.page === 'create-account') {
			$(document).on('click', '#check1', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_ACCOUNT', linkSection: 'NEW_CUSTOMER', linkName: 'SAME_AS_BILLING:' + ($(event.target).prop('checked') ? 'ON' : 'OFF') }); }); // 'Same as Shipping' selected / deselected
			$(document).on('click','.createAccount .btnContinue', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_ACCOUNT', linkSection: 'NEW_CUSTOMER', linkName: 'CONTINUE' }); }); // continue btn
			$(document).on('click', '.createAccount .termsAndCond a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_ACCOUNT', linkSection: 'TOOLS', linkName: ($(event.target).attr('href').indexOf('general-terms-conditions') > -1 ? 'TERMS_AND_CONDITIONS' : 'PRIVACY') }); }); // 'View Terms & Conditions' and 'Privacy Statement' links
		}

		//Forgot PIN  
		if (aem_utag_data.page_type === 'forgot-pin' || aem_utag_data.page_type === 'reset-pin') {
			$(document).on('click', '.securityQstnWithEmail button.btnSave', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PIN', linkSection: 'ANSWER_QUESTION', linkName: 'CONTINUE' }); });
			$(document).on('click', '.securityQstnWithEmail button.btnCancel', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PIN', linkSection: 'ANSWER_QUESTION', linkName: 'CANCEL' }); });
			$(document).on('click', '.securityQstnWithoutEmail button', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PIN', linkSection: 'EMAIL', linkName: ($(event.target).hasClass('btnCancel') ? 'CANCEL' : 'CONTINUE') }); });
			$(document).on('click', '.continueShopping .btn-primary', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PIN', linkSection: 'TOOLS', linkName: 'CONTINUE_SHOPPING' }); });
		}

		//Forgot Password
		if (aem_utag_data.page_type === 'reset-password' ) {
			$(document).on('click', '.createPasswordSuccess .btnContinue', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PASSWORD', linkSection: 'TOOLS', linkName: 'CONTINUE_SHOPPING' }); });
		}

		/* ACCOUNT-SETTINGS */
		if (qVars.page === 'account-settings') {
			$(document).on('click', '#emailWr .accordionTitle', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE', linkSection: 'EDIT', linkName: 'EMAIL' }); });
			$(document).on('click', '#emailOverlay .btn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'EDIT', linkSection: 'EMAIL', linkName: ($(event.target).hasClass('btnSubmit') ? 'SAVE' : 'CANCEL') }); });
			$(document).on('click', '#passwordWr .accordionTitle', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE', linkSection: 'EDIT', linkName: 'PASSWORD' }); });
			$(document).on('click', '#passwordOverlay .btn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'EDIT', linkSection: 'PASSWORD', linkName: ($(event.target).hasClass('btnSubmit') ? 'SAVE' : 'CANCEL') }); });
			$(document).on('click', '#pinWr .accordionTitle', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE', linkSection: 'EDIT', linkName: 'PIN' }); });
			$(document).on('click', '#pinOverlay .forgotPin', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'EDIT', linkSection: 'PIN', linkName: 'FORGOT_PIN' }); });
			$(document).on('click', '#pinOverlay .btn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'EDIT', linkSection: 'PIN', linkName: ($(event.target).hasClass('btnSubmit') ? 'SAVE' : 'CANCEL') }); });
			$(document).on('click', '#securityQuestionWr .accordionTitle', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE', linkSection: 'EDIT', linkName: 'SECURITY_QUESTION' }); });
			$(document).on('click', '#securityOverlay .btn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'EDIT', linkSection: 'SECURITY_QUESTION', linkName: ($(event.target).hasClass('btnSubmit') ? 'SAVE' : 'CANCEL') }); });
			$(document).on('click', '#billingAddressWr .accordionTitle', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE', linkSection: 'EDIT', linkName: 'BILLING_ADDRESS' }); });
			$(document).on('click', '#billingOverlay .btn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'EDIT', linkSection: 'BILLING_ADDRESS', linkName: ($(event.target).hasClass('btnSubmit') ? 'SAVE' : 'CANCEL') }); });
			$(document).on('click', '#primaryPhoneWr .accordionTitle', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE', linkSection: 'EDIT', linkName: 'PRIMARY_PHONE' }); });
			$(document).on('click', '#primaryPhoneOverlay .btn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'EDIT', linkSection: 'PRIMARY_PHONE', linkName: ($(event.target).hasClass('btnSubmit') ? 'SAVE' : 'CANCEL') }); });
			$(document).on('click', '#mobileNumberWr .accordionTitle', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE', linkSection: 'EDIT', linkName: 'MOBILE_PHONE' }); });
			$(document).on('click', '#mobilePhoneOverlay .btn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'EDIT', linkSection: 'MOBILE_PHONE', linkName: ($(event.target).hasClass('btnSubmit') ? 'SAVE' : 'CANCEL') }); });
			$(document).on('click', '#nicknameWr .accordionTitle', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE', linkSection: 'EDIT', linkName: 'NICKNAME' }); });
			$(document).on('click', '#nicknameOverlay .btn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'EDIT', linkSection: 'NICKNAME', linkName: ($(event.target).hasClass('btnSubmit') ? 'SAVE' : 'CANCEL') }); });
			$(document).on('click', '#tvReceptionWr .accordionTitle', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE', linkSection: 'EDIT', linkName: 'TV_RECEPTION' }); });
			$(document).on('click', '#tvReceptionOverlay .btn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'EDIT', linkSection: 'TV_RECEPTION', linkName: ($(event.target).hasClass('btnSubmit') ? 'SAVE' : 'CANCEL') }); });
		}

		// Write review
		if (qVars.page === 'write-review') {
			$(document).on('reviews.tagging', function(e, paramsObj){
				adobeDataLayerObject.pageInfo.pageCategory = paramsObj.categoryId;
			});
			$(document).on('click', '.BVMETermsLink', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'COMMUNITY', linkSection: 'BAZAARVOICE', linkName: 'TERMS_AND_CONDITIONS' }); });
			$(document).on('click', '.BVMEGuidelinesLink', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'COMMUNITY', linkSection: 'BAZAARVOICE', linkName: 'GUIDELINES' }); });
			$(document).on('click', '.BVMECommunityStandard .BVLink', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'COMMUNITY', linkSection: 'BAZAARVOICE', linkName: 'STANDARDS' }); });
			$(document).on('click', '.BVPLProductAction .BVSULinkAdd', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'COMMUNITY', linkSection: 'BAZAARVOICE', linkName: 'RECOMMENDATIONS_ADD' }); });
			$(document).on('click', '#BVButtonPreviewID .BVButton', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'COMMUNITY', linkSection: 'BAZAARVOICE', linkName: 'PREVIEW_REVIEW' }); });
			$(document).on('click', '#BVButtonEditID .BVButton', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'COMMUNITY', linkSection: 'BAZAARVOICE', linkName: 'EDIT_REVIEW' }); });
			$(document).on('click', '#BVButtonSubmitID .BVButton', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'COMMUNITY', linkSection: 'BAZAARVOICE', linkName: 'SUBMIT_REVIEW' }); });
			$(document).on('click', '#BVButtonCancelID .BVButton', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'COMMUNITY', linkSection: 'BAZAARVOICE', linkName: 'CANCEL_REVIEW' }); });
			$(document).on('click', '.BVSU_SearchToolbar .BVSUSearchButton .BVSUInput', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'COMMUNITY', linkSection: 'BAZAARVOICE', linkName: 'SEARCH_PRODUCT' }); });
			$(document).on('click', '.BVSU_SearchButtonContainer .BVSUInputButtonSearch', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'COMMUNITY', linkSection: 'BAZAARVOICE', linkName: 'RECOMMENDATIONS_DONE' }); });
			$(document).on('click', '.BVSU_SearchLinkClose .BVSULinkClose', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'COMMUNITY', linkSection: 'BAZAARVOICE', linkName: 'RECOMMENDATIONS_CLOSE' }); });
			$(document).on('click', '.BVSU_SearchResultsListProductActions .BVSUInputButtonAdd', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'COMMUNITY', linkSection: 'BAZAARVOICE', linkName: 'RECOMMENDATIONS_ADD_PRODUCT' }); });
			$(document).on('click', 'span.BVRRPageNumber a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'COMMUNITY', linkSection: 'BAZAARVOICE', linkName: 'SEARCH_RESULT_PAGE_' + $(event.target).text() }); });
			$(document).on('click', '.BVRRNextPage', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'COMMUNITY', linkSection: 'BAZAARVOICE', linkName: 'SEARCH_RESULT_PAGE_FORWARD' }); });
			$(document).on('click', '.BVRRPreviousPage', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'COMMUNITY', linkSection: 'BAZAARVOICE', linkName: 'SEARCH_RESULT_PAGE_BACK' }); });
		}

		if (qVars.page === 'plan-summary') {
			// Auto Delivery Plan Page - Plan level
			$(document).on('click', '.adDetails a.cancelPlan', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_AUTO-DELIVERY', linkSection: 'TOOLS', linkName: 'CANCEL_ALL_FUTURE_ORDERS' }); });
			$(document).on('click', '.adDetails a.reinstatePlan', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_AUTO-DELIVERY', linkSection: 'TOOLS', linkName: 'REINSTATE_PLAN' }); });
			$(document).on('click', '.adDetails .reorder', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_AUTO-DELIVERY', linkSection: 'TOOLS', linkName: 'REORDER' }); });
			$(document).on('click', '.adDetails .planFrequency .itemEdit', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_AUTO-DELIVERY', linkSection: 'TOOLS', linkName: 'EDIT_FREQUENCY' }); });
			$(document).on('click', '.adDetails .nextShip .itemEdit', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_AUTO-DELIVERY', linkSection: 'TOOLS', linkName: 'EDIT_NEXT_SHIPMENT_DATE' }); });
			$(document).on('click', '.adDetails .address .itemEdit', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_AUTO-DELIVERY', linkSection: 'TOOLS', linkName: 'EDIT_SHIPPING_ADDRESS' });  });
			$(document).on('click', '.adDetails .payment .itemEdit', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_AUTO-DELIVERY', linkSection: 'TOOLS', linkName: 'EDIT_PAYMENT_METHOD' }); });
			$(document).on('click', '.adDetails .itemDetails .itemEdit', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_AUTO-DELIVERY', linkSection: 'TOOLS', linkName: 'EDIT_ITEM_DETAILS' }); });
			$(document).on('click', '.confirmationOverlay.canplan button.btnSave', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'CANCEL_ORDER_PLUS_AUTO', linkName: 'CONFIRM' }); });
			$(document).on('click', '.confirmationOverlay.canplan button.btnCancel', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'CANCEL_ORDER_PLUS_AUTO', linkName: 'CANCEL' }); });


			// Auto Delivery Plan Page - Shipment level
			$(document).on('click', '.adShipItem .adShipAction .skipShipment', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTION', linkSection: 'TOOLS', linkName: 'SKIP_SHIPMENT' }); });
			$(document).on('click', '.adShipItem .adShipAction .canceled', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTION', linkSection: 'TOOLS', linkName: 'REINSTATE_SHIPMENT' }); });
			$(document).on('click', '.adShipItem .adShipMethod .edit a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTION', linkSection: 'TOOLS', linkName: 'EDIT_SHIPMENT' }); });
			$(document).on('click', '.adShipItem .adShipAddress .edit a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'TOOLS', linkName: 'EDIT_SHIPPING_ADDRESS' }); });
			$(document).on('click', '.adShipItem .adShipPay .edit a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'TOOLS', linkName: 'EDIT_PAYMENT_METHOD' }); });
			$(document).on('click', '.adShipItem .adShipName .edit a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'TOOLS', linkName: 'EDIT_ITEM_DETAILS' }); });
			$(document).on('click', '.confirmationOverlay.skip button.btnSave', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'SKIP_AUTO_ORDER', linkName: 'CONFIRM' }); });
			$(document).on('click', '.confirmationOverlay.skip button.btnCancel', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'SKIP_AUTO_ORDER', linkName: 'CANCEL' }); });
			$(document).on('click', '.confirmationOverlay.restore button.btnSave', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'REINSTATE_AUTO_ORDER', linkName: 'CONFIRM' }); });
			$(document).on('click', '.confirmationOverlay.restore button.btnCancel', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'REINSTATE_AUTO_ORDER', linkName: 'CANCEL' }); });

			$(document).on('click', 'a.p65display', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'PROP_65', linkSection: 'ORDER_DETAIL', linkName: 'OPEN_WARNING' }); });
		}

		//Order Detail page
		if (qVars.page === 'order-detail') {
			$(document).on('click', '.orderDetail .orderShipment .print', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'TOOLS', linkName: 'PRINT_INVOICE' }); }); // Print Icon (order in any status)
			$(document).on('click', '.orderDetail .trackingNumber a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'TOOLS', linkName: 'TRACK_PACKAGE' }); }); // Tracking Number - Once per page view per tracking number (order in shipped status only)
			$(document).on('click', '.orderDetail .createReturn a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'TOOLS', linkName: 'CREATE_LABEL_HERMES' }); }); // Hermes link DE only
			$(document).on('click', '.orderDetail .itemFeatureLinks .trackReturn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'TOOLS', linkName: 'TRACK_RETURN' }); }); // Track Return (order in shipped status only)
			$(document).on('click', '.orderDetail .itemFeatureLinks .returnLabel', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'TOOLS', linkName: 'START_RETURN' }); }); // Start a return (order in shipped status only)
			$(document).on('click', '.orderDetail .itemFeatureLinks .reorder', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'TOOLS', linkName: 'REORDER' }); }); // Reorder (order in shipped or cancelled status)
			$(document).on('click', '.orderDetail .cancelItem', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'TOOLS', linkName: 'CANCEL_ITEM' }); }); // Cancel Item (order in in-process state only)
			$(document).on('click', '.orderDetail .cancelOrder', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'TOOLS', linkName: 'CANCEL_ORDER' }); });
			$(document).on('click', '.orderDetail .productDesc .editItem', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'TOOLS', linkName: 'EDIT_ITEM_COLOR_SIZE' }); }); // Edit Color/Size (order in in-process status with item color or size options only)
			$(document).on('click', '.orderDetail .editShipping', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'TOOLS', linkName: 'EDIT_SHIPPING_METHOD' }); }); // Edit Shipping Method (order in in-process status only)
			$(document).on('click', '.orderDetail .payment .editContainer a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'TOOLS', linkName: 'EDIT_PAYMENT_OFFER' }); }); // Edit Billing Options (order in in-process status only)
			$(document).on('click', '.orderDetail .orderAddresses a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'TOOLS', linkName: 'EDIT_SHIPPING_ADDRESS' }); }); // Edit Shipping Address (order in in-process status only)
			$(document).on('click', '.orderDetail .orderPayment a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'TOOLS', linkName: 'EDIT_PAYMENT_METHOD' }); }); // Edit Payment Method (order in in-process status only)
			$(document).on('click', '.orderDetail .itemFeatureLinks .writeReview', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'TOOLS', linkName: 'WRITE_A_REVIEW' }); }); // Write a Review (order in shipped status only)
			$(document).on('click', '.orderDetail .itemFeatureLinks .updateAutoDel', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE', linkSection: 'ORDERS', linkName: 'AUTO-DELIVERY' }); }); // Manage Auto Delivery Plan (need an AD order)
			$(document).on('click', '.orderDetail .itemFeatureLinks .convertAutoDel', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'TOOLS', linkName: 'CONVERT_TO_AUTO_DELIVERY' }); }); // Convert To Auto-Delivery (need an order with single shot item that is available to change to Auto Delivery)
			$(document).on('click', '.confirmationOverlay.autoDeliveryConvert .btnSave', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'CONVERT_TO_AUTO-DELIVERY', linkName: 'CONFIRM' }); });
			$(document).on('click', '.confirmationOverlay.autoDeliveryConvert .btnCancel', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'CONVERT_TO_AUTO-DELIVERY', linkName: 'CANCEL' }); });
			$(document).on('click', '.confirmationOverlay.autoDeliveryConvert .editOverlayNote a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'TOOLS', linkName: 'AUTO-DELIVERY_FAQ' }); });

			let adPostfix = $('a.cancelOrder').data('cancel-order-context') === 'order-cancel-ad' ? '_PLUS_AUTO' : '';
			$(document).on('click', '.cancelOrderOverlay.confirmationOverlay .btnSave', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'CANCEL_ORDER' + adPostfix, linkName: 'CONFIRM' }); });
			$(document).on('click', '.cancelOrderOverlay.confirmationOverlay .btnCancel', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'CANCEL_ORDER' + adPostfix, linkName: 'CANCEL' }); });

			$(document).on('click', '.cancelItemOverlay.confirmationOverlay .btnSave', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'CANCEL_ITEM', linkName: 'CONFIRM' }); });
			$(document).on('click', '.cancelItemOverlay.confirmationOverlay .btnCancel', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'ORDER_OPTIONS', linkSection: 'CANCEL_ITEM', linkName: 'CANCEL' }); });
		}

		//Order Tracking page
		if (qVars.page === 'package-tracking') {
			$(document).on('click', '.backOrder .btn-primary', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'VIEW_OPTIONS', linkSection: 'TOOLS', linkName: 'RETURN_TO_ORDER' }); });
			$(document).on('click', '#trackingInfo a.accordionTitle, #trackingInfo button.accordionTitle', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'VIEW_OPTIONS', linkSection: 'TOOLS', linkName: 'TRACKING_DETAILS' }); });
		}

		//Return pages page
		if (qVars.page === 'return-options') {
			$(document).on('click', '.orderReturnOptions .btn-primary', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'TOOLS', linkSection: 'RETURN_LABEL', linkName: 'CONTINUE' }); });
			$(document).on('click', '.orderReturnOptions .btn-secondary', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'TOOLS', linkSection: 'RETURN_LABEL', linkName: 'CANCEL' }); });
			$(document).on('click', '#order_return_options_2 .fancybox', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'TOOLS', linkSection: 'RETURN_LABEL', linkName: 'MORE_DETAILS' });  });
		} else if (qVars.page === 'return-form') {
			$(document).on('click', '.orderReturnForm .btn-primary', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'TOOLS', linkSection: 'RETURN_LABEL', linkName: 'CONTINUE' }); });
			$(document).on('click', '.orderReturnForm .btn-secondary', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'TOOLS', linkSection: 'RETURN_LABEL', linkName: 'CANCEL' }); });
		} else if (qVars.page === 'return-label') {
			$(document).on('click', '.orderReturnLabelWrapper .btn-primary', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'TOOLS', linkSection: 'RETURN_LABEL', linkName: 'PRINT_LABEL' }); });
		}

		//Delinquent Easy Pay Order
		if (qVars.page === 'dq-notification') {
			$(document).on('click', '.dqNotification #pageContent .btn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'SIGN_IN', linkSection: 'ERROR', linkName: 'VIEW_EASY_PAY_ORDERS' }); });
		}

		//Account Locked
		if (aem_utag_data.page_type === 'myaccount-locked') {
			$(document).on('click', '[data-component-type="ACCOUNT_LOCK"] a, [data-component-type="ACCOUNT_LOCK"] button', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'SIGN_IN', linkSection: 'ERROR', linkName: qString.condenseForTag($(event.target).text()) }); });
		}

		//Reset Pin page
		if (aem_utag_data.page_type === 'reset-pin') {
			$(document).on('click', '.myAccountSettingsPinForm .divFormButtons .btn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PIN', linkSection: 'RESET', linkName: ($(event.target).hasClass('btnSubmit') ? 'SAVE' : 'CANCEL') }); });
		}

		//Order Mod / Payment Method
		if (aem_utag_data.page_type === 'paymentMethod') {
			$(document).on('click', '#editPayment .btnAddNewCard', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PAYMENT', linkSection: 'ORDER_DETAIL', linkName: 'ADD_NEW_CARD' }); });
			$(document).on('click', '#editPayment .btnSend', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PAYMENT', linkSection: 'ORDER_DETAIL', linkName: 'SAVE_PAYMENT_EDIT' }); });
			$(document).on('click', '#editPayment .btnCancel', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PAYMENT', linkSection: 'ORDER_DETAIL', linkName: 'CANCEL_PAYMENT_EDIT' }); });

			$(document).on('click', '.overlayNewCard .btnSave', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PAYMENT', linkSection: 'ORDER_DETAIL', linkName: 'SAVE_NEW_CARD' }); });
			$(document).on('click', '.overlayNewCard .btnCancel', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PAYMENT', linkSection: 'ORDER_DETAIL', linkName: 'CANCEL_NEW_CARD' }); });
		}

		//Order Mod / Payment Method + Payment Offers (Payment Conflict)
		if (aem_utag_data.page_type === 'paymentMethod' || aem_utag_data.page_type === 'paymentOffers') {
			$(document).on('click', '.overlayGenericError.paymentConflictOptions .btn, .overlayGenericError.paymentConflictOffers .btn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MISMATCH', linkSection: 'CHANGE_' + ($(event.target).parents().hasClass('paymentConflictOptions') ? 'PAYMENT' : 'OFFER'), linkName: ($(event.target).hasClass('btnSave') ? 'CONTINUE' : 'CANCEL') }); });
		}

		//Order Mod / Shipping Address
		if (qVars.page === 'shipping-address') {
			$(document).on('click', '#editShipping .btnAddNewAddress', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_ADDRESS', linkSection: 'ORDER_DETAIL', linkName: 'ADD_SHIPPING_ADDRESS' }); });
			$(document).on('click', '#editShipping .btnSend', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_ADDRESS', linkSection: 'ORDER_DETAIL', linkName: 'SAVE_ADDRESS_EDIT' }); });
			$(document).on('click', '#editShipping .btnCancel', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_ADDRESS', linkSection: 'ORDER_DETAIL', linkName: 'CANCEL_ADDRESS_EDIT' }); });

			$(document).on('click', '.addMyShippingAddress .btnSave', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_SHIPPING', linkSection: 'ORDER_DETAIL', linkName: 'SAVE_NEW_ADDRESS' }); });
			$(document).on('click', '.addMyShippingAddress .btnCancel', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_SHIPPING', linkSection: 'ORDER_DETAIL', linkName: 'CANCEL_NEW_ADDRESS' }); });

			$(document).on('click', 'a.warningOverlay', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'PROP_65', linkSection: 'ORDER_DETAIL', linkName: 'OPEN_WARNING' }); });
		}

		//Payment Method 
		if (aem_utag_data.page_type === 'myaccount-payment-methods') {
			$(document).on('click', '.cssBtnCross', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PAYMENT', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'REMOVE_CARD' }); });
			$(document).on('click', '.editExpDate', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PAYMENT', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'UPDATE_CARD' }); });
			$(document).on('click', '.btnAddNewCard', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PAYMENT', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'ADD_NEW_CARD' }); });
			//add new Card overlay
			$(document).on('click', '.overlayNewCard .btn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PAYMENT', linkSection: 'ACCOUNT_MANAGEMENT', linkName: ($(event.target).hasClass('btnSave') ? 'SAVE' : 'CANCEL') + '_NEW_CARD' }); });
			//update Card overlay
			$(document).on('click', '.overlayUpdateExpDate .btn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PAYMENT', linkSection: 'ACCOUNT_MANAGEMENT', linkName: ($(event.target).hasClass('btnSave') ? 'SAVE' : 'CANCEL') + '_CARD_UPDATE' }); });
		}

		//Show Reminders
		if (aem_utag_data.page_type === 'myaccount-show-reminders') {
			$(document).on('click', '.remindersEmail.mailtext a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_REMINDERS', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'UPDATE_EMAIL_ADDRESS' }); });
			$(document).on('click', '.remindersButton a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_REMINDERS', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'SEARCH_PROGRAM_GUIDE' }); });
			$(document).on('click', '.remindEdit a.remindEditLink', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_REMINDERS', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'EDIT_REMINDER' }); });
			$(document).on('click', '.removeReminder', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_REMINDERS', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'CLOSE_REMOVE_REMINDER' }); });
			$(document).on('click', '.editReminderOverlay .btnSave', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_REMINDERS', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'SAVE_EDITED_REMINDER' }); });
			$(document).on('click', '.editReminderOverlay .btnCancel', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_REMINDERS', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'CANCEL_EDIT_REMINDER' }); });
		}

		//Shipping Addresses
		if (aem_utag_data.page_type === 'myaccount-shipping-addresses') {
			$(document).on('click', '.visualCrossBtn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_ADDRESS', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'REMOVE_ADDRESS' }); });
			$(document).on('click', '.editExistingShipAddress', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_ADDRESS', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'EDIT_ADDRESS' }); });
			$(document).on('click', '.addAddress,.addAddressBtn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_ADDRESS', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'ADD_NEW_ADDRESS' }); });
			$(document).on('click', '.default-address', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_ADDRESS', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'SET_DEFAULT_ADDRESS' }); });
			//add new Shipping Addresses overlay
			$(document).on('click', '.addMyShippingAddress .btn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_ADDRESS', linkSection: 'ACCOUNT_MANAGEMENT', linkName: ($(event.target).hasClass('btnSave') ? 'SAVE' : 'CANCEL') + '_NEW_ADDRESS' }); });
			//edit Shipping Addresses overlay
			$(document).on('click', '.editMyShippingAddress .btn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_ADDRESS', linkSection: 'ACCOUNT_MANAGEMENT', linkName: ($(event.target).hasClass('btnSave') ? 'SAVE' : 'CANCEL') + '_EDIT_ADDRESS' }); });
		}

		//User Preferences
		if (aem_utag_data.page_type === 'myaccount-user-preferences') {
			$(document).on('click', '.userPreferencesForm button.btnSubmit', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PREFERENCES', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'SUBMIT_PREFERENCES' }); });
			$(document).on('click', '.userPreferencesForm button.btnCancel', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PREFERENCES', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'RESET_PREFERENCES' }); });
			$(document).on('click', '.userPreferencesForm input.preferenceUnsubscribe', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PREFERENCES', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'UNSUBSCRIBE_ALL_PREFERENCES' }); });
			$(document).on('click', '.userPreferencesForm a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PREFERENCES', linkSection: 'ACCOUNT_MANAGEMENT', linkName:  qString.condenseForTag($(event.target).text()) }); });
			$(document).on('click', '.emailPreferences .emailHeader a', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PREFERENCES', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'UPDATE_EMAIL_ADDRESS' }); });
			$(document).on('click', '.userPreferences .myAccountAccordion a.accordionTitle, .userPreferences .myAccountAccordion button.accordionTitle', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PREFERENCES', linkSection: 'ACCOUNT_MANAGEMENT', linkName: _this.userPrefAccordion(event.target) });  });
			$(document).on('click', '#emailPreferencesSubmit', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE', linkSection: 'EDIT', linkName: 'UPDATE' }); });
			$(document).on('click', '#emailPreferencesCancel', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE', linkSection: 'EDIT', linkName: 'RESET' }); });
		}

		//Overlay Close Button
		if (aem_utag_data.page_type === 'myaccount-payment-methods' || aem_utag_data.page_type === 'myaccount-shipping-addresses' || aem_utag_data.page_type === 'myaccount-show-reminders' || qVars.page === 'payment-method' || qVars.page === 'shipping-address') {
			$(window).on('fancybox.show', function () { _this.closeOverlay(); });
		}

		//Opt Out
		if (aem_utag_data.page_type === 'marketing-preference-opt-out') {
			$(document).on('click', '#preferenceOptOut .btnSubmit', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PREFERENCES', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'YES' }); });
			$(document).on('click', '#preferenceOptOut .btnCancel', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PREFERENCES', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'NO_THANKS' }); });
		}

		//Unsubscribe
		if (aem_utag_data.page_type === 'marketing-preference-unsubscribe-all') {
			$(document).on('click', '.preferenceUnsubscribeAll .btnSubmit', function (event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'MANAGE_PREFERENCES', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'UNSUBSCRIBE' }); });
		}

		//Order receipt
		if (qVars.page === 'order-receipt') { // otherwise tag below will be fired on "order-detail" page, too, because order-receipt page is an authored page based on the order-detail template
			$(document).on('click', '.orderShipment .print', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: _this.orderReceipt(), linkSection: 'ORDER', linkName: 'PRINT_RECEIPT' }); });
		}

		//Easy Pay Module
		$(document).on('click', '[data-component-type="EASY_PAY"] a, .ezPayPaymentOverlay .divFormButtons button', function(){ _this.ezPayTag(event.target); });
	},
	addNotifySPListener: function () {
		$(document).on('qError qSuccess qInfo', function(e, params){
			if (e.type === 'qError' && aem_utag_data.page_id === 'SIGN IN') {
				params.ecat = oTaggingDefaults.elemCat.errSignInForm;
			}
			if (params.linkClickParams && typeof params.linkClickParams.linkClickMsg !== 'undefined'){
				_this.captureSitePromoData(e.target, {
					linkType: e.type,
					linkCategory: params.linkClickParams.pageType,
					linkSection: params.linkClickParams.response,
					linkName: params.linkClickParams.linkClickMsg
				});
			}
		});
	},
	addOrdersSPListener: function () {
		if (typeof cartVars === 'object' && typeof cartVars.cart === 'object' && typeof cartVars.cart.shippingList === 'object') {
			for (let o in cartVars.cart.shippingList) {
				if (typeof cartVars.cart.shippingList[o] === 'object') {
					let order = cartVars.cart.shippingList[o];
					if (typeof order.orderNumber !== 'string') { //order number doesn't exist
						_this.captureSitePromoData(document.body, { linkType: document.body.localName, linkCategory: aem_utag_data.site_promo_path, linkSection: 'ORDER_CONFIRM', linkName: 'NO_ORDER_ID' });
					}
				} else {
					_this.captureSitePromoData(document.body, { linkType: document.body.localName, linkCategory: aem_utag_data.site_promo_path, linkSection: 'ORDER_CONFIRM', linkName: 'NO_ORDER_LIST' });
				}
			}
		} else {
			_this.captureSitePromoData(document.body, { linkType: document.body.localName, linkCategory: aem_utag_data.site_promo_path, linkSection: 'ORDER_CONFIRM', linkName: 'NO_CART' });
		}
	},
	addOTTDeviceSPListener: function () {
		/* login */
		if (aem_utag_data.page_type === 'ott-login') {
			$(document).on('click', '.btnContinue', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'OTT_SIGN_IN', linkSection: 'TOOLS', linkName: 'CONTINUE' }); });
		}
		/* login :: account locked */
		if (aem_utag_data.page_type === 'ott-account-lock') {
			$(document).on('click', '.btnContinue', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'SIGN_IN', linkSection: 'ERROR', linkName: 'RESET_PASSWORD' }); });
		}
		/* device link form */
		if (aem_utag_data.page_type === 'device-link') {
			$(document).on('click', '.addDeviceLink', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'OTT', linkSection: 'AUTHENTICATE', linkName: 'LINK_DEVICE' }); });
		}
	},
	addProductSPListener: function () {
		// Color and Size Tagging
		$(document).on('mouseup', '.btnAddToCart', function(event) { _this.checkForColorSize(event.target, (aem_utag_data.page_type !== 'wishlist' ? 'A' : 'AW')); });
		$(document).on('mouseup', '.btnSpeedBuy', function(event) { _this.checkForColorSize(event.target, 'S'); });
		$(document).on('mouseup', '.btnSaveToWishList', function(event) { _this.checkForColorSize(event.target, 'SV'); });
		$(document).on('mouseup', '.btnAddToWishList', function(event) { _this.checkForColorSize(event.target, 'W'); });
		if (aem_utag_data.page_type === 'wishlist') { $(document).on('mouseup', '.productEdit a,.productDesc a,.productImg a', function(event) { _this.checkForColorSize(event.target, 'EW'); }); }

		let oSelectors = {
				prod : {
					imgThumbs : '.thumbcell',
					imgEnlargeBtn : '.productPhoto .photoWrapper a.enlarge',
					imgMobileScrollIndicators : '.productStage .carousel-indicators li',
					socialButtons: '.btnEMailAFriend, span[data-pin-log="button_pinit"]',
					sizeGuide : '.buyBoxSelectSize a.selectLink',
					ezPayOffers : '.easyPayDetails a[href*="easypay"]',
					colorSizeOptions : '.swatch',
					qtySelector : '.buyBoxQuantity .stepDown, .buyBoxQuantity .stepUp',
					singleShipmentOrAutoDelivery : '.buyBoxSelectDelivery .deliveryOption',
					creditTerms : '.easyPayInfo input.QPayOpt',
					squareTradeAdd : '.pdSquareTrade .btn',
					squareTradeInfo : '.pdSquareTrade .infoIcon',
					channelSelector : '.channelSelector .btn',
					moreInfoAccordions : '.pdLongDesc .accordionTitle, .accordionFileMenu a, .togglePreview a',
					recommendSize : '#fitsme_launcher',
					special : {
						imgHover : '#s7Flyout',
					},
					saveCancelMYS : '.buyBoxButtons .btn-primary, .buyBoxButtons .btn-secondary',
					retailValue: '.rvPrice',
					reviewsMYS: '.bv_button_buttonMinimalist, .bv_stars_button_container',

				},
				bv : {
					reviewRead : '.pdShortDesc #BVRRSummaryContainer #BVRRRatingOverall_Rating_Summary_1',
					writeReview : '#BVRRRatingSummaryLinkWriteID a',
					writeReviewNew : '#BVRRRatingSummaryLinkWriteFirstID a',
					writeReviewTop : '.bv_cta_button .bv_ffs_hover',
					viewAllQandA : '#BVQASummaryBoxViewQuestionsID a, .BVQAPageTabLink',
					moreReviews : '.BVRRNextPage a',
					previousReviews : '.BVRRPreviousPage a',
					prevReviewsNumb : '.BVRRPageNumber a',
					bvQuestion : '.BVQAQuestionSummary a',
					bvAnswerQuestion : '.BVQAAnswerQuestion a',
					bvEnableSearch : '.BVQASearchFormTextInput',
					bvSearch : '.BVQASearchFormSubmitButton',
					sortReviews : '.BVRRDisplayContentSelect, .BVQAToolbarSortSelect',
					bvAskQuestion : '.BVQAAskQuestionTop a, .BVQANoQuestions a, #BVQASummaryBoxAskFirstQuestionID a',
					helpfulReviewY : '.BVRRContentReview .BVDI_FVPositive a',
					helpfulReviewN : '.BVRRContentReview .BVDI_FVNegative a',
					reportReview : '.BVRRContentReview .BVDI_FVReportLink a',
					helpfulAnswerY : '.BVAnswerData .BVDI_FVPositive a',
					helpfulAnswerN : '.BVAnswerData .BVDI_FVNegative a',
					reportAnswer : '.BVQAContentData .BVDI_FVReportLink a',
					bvPostedBy : '.BVRRNickname',
					communityAddLinks : '.BVQAGuidelines a'
				}
			},
			sSelector = _this.createSelector(oSelectors.prod),
			sSelectorBv = _this.createSelector(oSelectors.bv),
			imgHoverTimeout = 3000,
			pdpStageHoverTimeout = '';
		$(document).on('click', sSelector + ',' + sSelectorBv, function(event) { _this.generalProdClickHandler(event.target); });
		$(document).on('mouseenter', oSelectors.prod.special.imgHover, function(event) {
			let self = event.target;
			pdpStageHoverTimeout = setTimeout(function(){ _this.generalProdClickHandler(self); }, imgHoverTimeout);
		});
		$(document).on('mouseleave', oSelectors.prod.special.imgHover, function() {
			if (typeof pdpStageHoverTimeout !== 'undefined') {
				clearTimeout(pdpStageHoverTimeout);
			}
		});
		$('.tfc-fitrec-product').one('mouseenter touchstart', '[data-widget-rendered="true"]', function(event){
			if (typeof tfcapi !== 'undefined') {
				tfcapi('event', 'tfc-fitrec-register', 'open', (e) => {
					let ele = $(e);
					_this.captureSitePromoData(event.target, {
						linkType: ele.localname,
						linkCategory: 'PRODUCTDETAIL',
						linkSection: 'TOOLS',
						linkName: 'TRUEFIT'});
				});
			}
		});
		$(document).on('mouseup', '.btnCancel', function(event) {
			let tag = 'PRODUCTDETAIL-_-ADDTO-_-CANCEL';
			if (!_this.tagExists(tag)) {
				_this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: tag.split('-_-')[0], linkSection: tag.split('-_-')[1], linkName: tag.split('-_-')[2] });
			}
		});

		$(oSelectors.prod.twitterBtn).on('click', function(event) {
			let tag = 'PRODUCTDETAIL-_-SOCIAL-_-TWITTER';
			if (!_this.tagExists(tag)) {
				_this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: tag.split('-_-')[0], linkSection: tag.split('-_-')[1], linkName: tag.split('-_-')[2] });
			}
			return true;
		});

		$(oSelectors.prod.facebookBtn).on('click', function(event) {
			let tag = 'PRODUCTDETAIL-_-SOCIAL-_-FACEBOOK';
			if (!_this.tagExists(tag)) {
				_this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: tag.split('-_-')[0], linkSection: tag.split('-_-')[1], linkName: tag.split('-_-')[2] });
			}
			return true;
		});

		//listen to blur to snag iframe click
		window.addEventListener('blur', function(event) {
			if (document.activeElement === document.getElementById('facebookLikeBtn')) {
				let tag = 'PRODUCTDETAIL-_-SOCIAL-_-FACEBOOK_LIKE';
				if (!_this.tagExists(tag)) {
					_this.captureSitePromoData(event.target, { linkType: 'blur', linkCategory: tag.split('-_-')[0], linkSection: tag.split('-_-')[1], linkName: tag.split('-_-')[2] });
				}
			}
		});

		$(oSelectors.prod.squareTradeInfo).on( 'click', function(event) {
			let tag = 'ALLSTATE-_-WIDGET-_-MORE_INFO';
			if (!_this.tagExists(tag)) {
				_this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: tag.split('-_-')[0], linkSection: tag.split('-_-')[1], linkName: tag.split('-_-')[2] });
			}
			return false;
		});
	},
	addSignInSPListener: function () {
		//Forgot Password page
		if (aem_utag_data.page_type === 'forgot-password') {
			$(document).on('click', '.forgotPasswordInfo .forgotPassword .btn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'SIGN_IN', linkSection: 'TOOLS', linkName: ($(event.target).hasClass('btnSave') ? 'SUBMIT_RESET_EMAIL_REQUEST' : $(event.target).parents('.npoWr').length ? "CONTINUE_WITHOUT_PASSWORD" : 'CONTINUE_SHOPPING') }); });
			$(document).on('click', '.resetPasswordSuccess .createPasswordSuccess .btn', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: 'SIGN_IN', linkSection: 'TOOLS', linkName: 'CONTINUE_SHOPPING' }); });
		}

		//Reset Password Page
		if (aem_utag_data.page_type === 'reset-password' && window.location.pathname.search(/forced/i) >= 0) {
			$(document).on('submit', '.resetPassword form.qForm', function(event) { _this.captureSitePromoData(event.target, { linkType: event.target.localName, linkCategory: aem_utag_data.site_promo_path, linkSection: 'TOOLS', linkName: 'FORCED_PASSWORD_RESET' }); });
		}

		//Sign In Overlay
		$(document).on('click', '.loginOverlay .btnContinue', function(event){ _this.signInOverlay(event.target); });
		$(document).on('login.overlay.tagging', function(event) { $('.loginOverlay a.fancybox-close').off('.aaspTagging').on('click.aaspTagging', function(){ _this.signInOverlay(event.target); }); });

		//Sign In Page
		$(document).on('click', '.pgSignIn .signInCont .btnContinue, .pgSignIn .signInCont a.forgotPw, .pgSignIn .signInCont .newCust a', function(event) { _this.signInPage(event.target); });
		$(document).on('change', '.pgSignIn .signInCont input[name="showPw"]', function(event) { _this.signInPage(event.target); });
	},
	addVideoSPListener: function () {
		let oVideoSelectors = {
				vidThumbs : '.productThumbnails .videoThumb',
				vidLabel : '.productThumbnails .videoOnAirLabel',
				vidFullScreen : '.vjs-fullscreen-control, .fullScreenOff, .smallScreenOff',
				vidMute : '.vjs-mute-control',
				vidPlay : '.vjs-play-control, .playOff, .pauseOff, .vjs-big-play-button, .vjs-play-wrapper, .vjs-live-button, .vjs-live-controls',
				vidBg : 'video',
				vidAutoPlay : '.videoCheckAutoPlay label',
				vidReturnToVideo : '.videoBckToVideoBtn',
				vidBuyBox: '#toggleBuybox',
				sltvChannelSelector: '.btn-group-justified .btn',
				catchupTv: '.carousel-toggle, .programme'
			},
			sVideoSelector = _this.createSelector(oVideoSelectors);

		$(document).off('click.aaspVideoTagging').on('click.aaspVideoTagging', sVideoSelector, function(event) { _this.generalVideoClickHandler(event.target); });
		$(document).off('mouseup.aaspVideoTagging').on('mouseup.aaspVideoTagging', '.vjs-subs-caps-button', function(e) { _this.generalVideoClickHandler(e.target); });
		$(document).off('mousedown.aaspVideoTagging').on('mousedown.aaspVideoTagging', '.vjs-volume-bar', function(e) { _this.generalVideoClickHandler(e.currentTarget); });
	},
	createSelector: function(oSelectors) {
		let sSelector = '';
		$.each(oSelectors, function(n,v) {
			v = typeof v === 'string' ? v : '';
			sSelector += v.length > 0 ? (v + ',') : '';
		});
		return (sSelector = (sSelector.slice(-1) === ',' ? sSelector.substring(0,sSelector.length-1) : sSelector));
	},
	tagExists: function(tag){
		for (let i = 0; i < _this.tagArray.length; i++) {
			if (_this.tagArray[i] === tag) {
				return true;
			}
		}
		return false;
	},
	checkForColorSize : function(ele, btn){
		let tag = '';
		if (typeof productSelected === 'function' && !productSelected(false)) {
			tag = 'SELECTION_NOT_MADE';
			if (!_this.tagExists(tag)) {
				_this.tagArray.push(tag);
			}
		} else {
			if (btn === 'A') {
				tag = 'PRODUCTDETAIL-_-ADDTO-_-CART';
			} else if (btn === 'S') {
				tag = 'PRODUCTDETAIL-_-ADDTO-_-SPEEDBUY';
			} else if (btn === 'W') {
				tag = 'PRODUCTDETAIL-_-ADDTO-_-WISHLIST';
			} else if (btn === 'SV') {
				tag = 'PRODUCTDETAIL-_-ADDTO-_-SAVE';
			} else if (btn === 'AW') {
				tag = 'WISHLIST-_-ADDTO-_-CART';
			} else if (btn === 'EW') {
				tag = 'WISHLIST-_-TOOLS-_-EDIT';
			}
			if (tag !== '' && !_this.tagExists(tag) && tag.includes('-_-') && tag.split('-_-').length > 2) {
				_this.captureSitePromoData(ele, { linkType: ele.localName, linkCategory: tag.split('-_-')[0], linkSection: tag.split('-_-')[1], linkName: tag.split('-_-')[2] });
			}
		}
	},
	//Overlay Close Button
	closeOverlay: function() {
		let overlayTags = [
			{page: 'myaccount-payment-methods', selector:'.overlayNewCard .fancybox-close', sp: {linkCategory: 'MANAGE_PAYMENT', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'CLOSE_NEW_CARD'} },
			{page: 'myaccount-payment-methods', selector:'.overlayUpdateExpDate .fancybox-close', sp: {linkCategory: 'MANAGE_PAYMENT', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'CLOSE_CARD_UPDATE'} },
			{page: 'paymentMethod', selector:'.overlayNewCard .fancybox-close', sp: {linkCategory: 'MANAGE_PAYMENT', linkSection: 'ORDER_DETAIL', linkName: 'CLOSE_NEW_CARD'} },
			{page: 'order-shipping-address', selector:'.overlayAddShippingAddress .fancybox-close', sp: {linkCategory: 'MANAGE_SHIPPING', linkSection: 'ORDER_DETAIL', linkName: 'CLOSE_NEW_ADDRESS'} },
			{page: 'myaccount-shipping-addresses', selector:'.overlayAddShippingAddress .fancybox-close', sp:  {linkCategory: 'MANAGE_ADDRESS', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'CLOSE_NEW_ADDRESS'} },
			{page: 'myaccount-shipping-addresses', selector:'.overlayEditShippingAddress .fancybox-close', sp: {linkCategory: 'MANAGE_ADDRESS', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'CLOSE_EDIT_ADDRESS'} },
			{page: 'myaccount-show-reminders', selector:'.editReminderOverlay .fancybox-close', sp: {linkCategory: 'MANAGE_REMINDERS', linkSection: 'ACCOUNT_MANAGEMENT', linkName: 'CLOSE_EDIT_REMINDER'} }
		];
		overlayTags.forEach(function(t) {
			if (aem_utag_data.page_type === t.page)
			{
				$(t.selector).on('click', function (event) {
					_this.captureSitePromoData(event.target, {
						linkType: event.target.localName,
						linkCategory: t.sp.linkCategory,
						linkSection: t.sp.linkSection,
						linkName: t.sp.linkName
					});
				});
			}
		});
	},
	//cart
	cartCheckout: function (ele) {
		let el = $(ele),
			linkName = '';
		if (el.hasClass('btnCheckout')) {
			linkName = 'CHECKOUT';
		} else if (el.hasClass('btnSpeedBuy')) {
			linkName = 'SPEEDBUY';
		}
		if (window.localStorage.getItem('dqFlag') === 'true' || !qCookie.get(qCookie.name.globalUserId || cartVars.cart.cartType === 'NPO' || cartVars.cart.cartType === 'EXPRESS')) {
			window.localStorage.setItem('signInPath', 'CHECKOUT');
		}
		return linkName;
	},
	// cart carousels
	cartCarousel: function($el, sCarouselType) {
		let sItemUrl = $el.attr('href'),
			sItemNr = typeof sItemUrl === 'string' ? sItemUrl.match(/product\.(\w*)\./)[1] : '',
			header = $el.closest('[data-module-type]').find('.headerText h2'),
			sHeader = header.length > 0 ? header.text().toUpperCase().replace(/\s/g, '_') : 'NO_CAROUSEL_TYPE',
			position = $el.parents('.carouselList').children().index($el.parent()) + 1,
			linkType = $el.target.localName,
			linkCategory = 'RECOMMENDATIONS',
			linkSection = typeof sCarouselType === 'string' ? (sCarouselType === 'recentlyViewed' ? 'RECENTLY_VIEWED' : (sCarouselType === 'relatedItems' ? 'RELATED_ITEMS' : sHeader)) : sHeader,
			linkName = sItemNr.toUpperCase() + ':' + position;
		_this.captureSitePromoData($el.target, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	cartRemoveItem: function(ele) {
		let el = $(ele),
			linkSection = '';
		if (el.parents().hasClass('cartItem')) {
			linkSection = 'EDIT_ITEM';
		} else if (el.parents().hasClass('savedItem')) {
			linkSection = 'SAVED_ITEMS';
		}
		return linkSection;
	},
	removeSavedItemsGallery: function(ele) {
		let el = $(ele),
			removeVal = el.val(),
			linkName = 'REMOVE_ALL';
		if (removeVal === 'n') {
			linkName += '_SOLD_OUT';
		} else if (removeVal === 'w') {
			linkName += '_WAITLIST';
		}
		return linkName;
	},
	cartSavedItems: function(ele) {
		let el = $(ele),
			linkName = '';
		if (el.hasClass('btnAddToCart')) {
			linkName = 'ADD_TO_CART';
		} else if (el.hasClass('btnAddToWishlist')) {
			linkName = 'ADD_TO_WISHLIST';
		}
		return linkName;
	},
	cartQcard: function(ele) {
		let el = $(ele),
			qCardStr = el.prev('.qsfOffer strong').text(),
			qCardPromo = qCardStr.replace(/[^\d]+/, ''),
			linkName = 'DETAILS:' + qCardPromo + '_MONTH_FINANCING';
		return linkName;
	},
	// your information
	infoContTag: function() {
		let linkSection = '';
		if (location.href.indexOf('NPO=true') > -1) {
			linkSection = 'NPO';
		} else {
			linkSection =  'NEW_CUSTOMER';
		}
		return linkSection;
	},
	sameAddressTag: function(ele) {
		let el = $(ele),
			chkOnOff = el.prop('checked') ? 'ON' : 'OFF',
			linkType = ele.localName,
			linkCategory = 'MANAGE_ACCOUNT',
			linkSection = '',
			linkName = 'SAME_AS_SHIPPING:' + chkOnOff;
		if (location.href.indexOf('NPO=true') > -1) {
			linkSection = 'NPO';
		} else {
			linkSection =  'NEW_CUSTOMER';
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	yourInfoToolsTag: function(ele) {
		let el = $(ele),
			linkType = ele.localName,
			linkCategory = 'CHECKOUT',
			linkSection = 'TOOLS',
			linkName = '';
		if (el.hasClass('cookiePolicy')) {
			linkName = 'COOKIE_POLICY';
		} else if (el.hasClass('privacyPolicy')) {
			linkName = 'PRIVACY';
		} else if (el.hasClass('termsAndConditions')) {
			linkName = 'TERMS_AND_CONDITIONS';
		} else if (el.text().trim() === 'Edit') {
			linkName = 'EDIT_CART';
		} else if (el.hasClass('shipInfo')) {
			linkCategory = 'MANAGE_ACCOUNT';
			linkSection = 'NEW_CUSTOMER';
			linkName = 'VERSAND_IN_ANDERE_LANDER';
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	//payment options
	paymentMethodsTag: function(ele) {
		let el = $(ele),
			linkType = ele.localName,
			linkCategory = aem_utag_data.site_promo_path,
			linkSection = 'GIFT_CARD',
			linkName = '',
			sPayOption = $('.pgPayOptions .cards input[type="radio"]:checked').closest('label').find('.ccIcon').is('.ccQV, .ccHS, .ccZU') ? 'qCard' : $('.pgPayOptions .cards input[type="radio"]:checked').val(),
			selectedPaymentMethod = '';
		switch (sPayOption) {
		case 'checkMoneyOrder':
			selectedPaymentMethod = 'CHECK';
			break;
		case 'payPal':
			selectedPaymentMethod = 'PAYPAL';
			break;
		case 'qCard':
			selectedPaymentMethod = 'QCARD';
			break;
		default:
			selectedPaymentMethod = 'CREDIT_CARD';
			break;
		}
		if (el.hasClass('btnAddGiftCard')) {
			linkName = 'OPEN';
		} else if (el.hasClass('btnApplyPromoCode') && $('.overlayPromoCode form').valid()) {
			linkCategory = 'PROMO_CODE';
			linkSection = 'APPLY_CODE';
			linkName = $('.overlayPromoCode input[name="promoCode"]').val().toUpperCase();
		} else if (el.hasClass('btnPromoCode') && $('.promoCode form').valid()) {
			linkCategory = 'PROMO_CODE';
			linkSection = 'APPLY_CODE';
			linkName = $('.promoFormOuter input[name="inlinePromoCode"]').val().toUpperCase();
		} else if (el.hasClass('btnRemoveVoucher')) {
			linkCategory = 'PROMO_CODE';
			linkSection = 'REMOVE_CODE';
			linkName = $('div.voucherCode span').text().toUpperCase();
		} else if (el.hasClass('closeBtn') && el.parents().hasClass('promoFormOuter voucherAppliedOuter')) {
			linkCategory = 'PROMO_CODE';
			linkSection = 'REMOVE_CODE';
			linkName = $('div.voucherAppliedOuter').text().trim().split('\n')[0].toUpperCase();
		} else if (el.hasClass('btnSaveCard')) {
			linkName = 'SAVE';
		} else if (el.hasClass('btnAddAnotherCard')) {
			linkName = 'ADD_CARD';
		} else if (el.hasClass('btnSave')) {
			linkSection = 'ADD_CARD';
			linkName = 'SAVE';
		} else if (el.hasClass('btnCancel')) {
			linkSection = 'ADD_CARD';
			linkName = 'CANCEL';
		} else if (el.hasClass('btnContinue')) {
			linkSection = 'CONTINUE';
			linkName = selectedPaymentMethod;
		} else if (el.hasClass('btnAddPromoCode') || el.parents().hasClass('promoCode')) {
			linkSection = 'PROMO_CODE';
			linkName = 'ADD';
		} else if (el.hasClass('btnAddNewCard')) {
			linkSection = 'CREDIT_CARD';
			linkName = 'ADD_NEW_CARD';
		} else if (el.hasClass('viewAll')) {
			linkName = 'CHECKOUT-_-TOOLS-_-VIEW_ALL_PAYMENT_METHODS';
			linkCategory = 'CHECKOUT';
			linkSection = 'TOOLS';
			linkName = 'VIEW_ALL_PAYMENT_METHODS';
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	//cancel Gift options
	giftOptionsCancelTag: function(ele) {
		let wrappingDisabled = $('.giftOptionCBX').length !== 0;
		if (wrappingDisabled) {
			let alreadyProcessed = $('.giftOptions').data('gift-options-tagging-processed') === 'true';
			if (!alreadyProcessed) {
				let el = $('.overlayGiftOptions');
				_this.captureSitePromoData(ele, {
					linkType: ele.localName,
					linkCategory: 'GIFT_OPTIONS',
					linkSection: 'CANCEL',
					linkName: typeof el.attr('data-item-number') !== 'undefined' ? el.attr('data-item-number').split(' ')[0] : ''
				});
			}
		}
	},
	//order review, shipTo
	actAddress: function(ele, type){
		let el = $(ele),
			linkName = '';
		switch (type) {
		case 'CONTINUE':
			linkName = 'CONTINUE';
			break;
		case 'VIEWALL':
			linkName = 'VIEW_ALL';
			break;
		case 'ADDCLOSE':
			linkName = 'CLOSE_NEW_ADDRESS';
			break;
		case 'EDITCLOSE':
			linkName = 'CLOSE_ADDRESS_EDIT';
			break;
		case 'BILLCLOSE':
			linkName = 'CLOSE_EDIT_BILLING_ADDRESS';
			break;
		case 'ADD':
			if (el.hasClass('btnSave')) {
				linkName = 'SAVE_NEW_ADDRESS';
			} else if (el.hasClass('btnCancel')) {
				linkName = 'CANCEL_NEW_ADDRESS';
			} else {
				linkName = 'ADD_SHIPPING_ADDRESS';
			}
			break;
		case 'BILL':
			if (el.hasClass('btnSave')) {
				linkName = 'SAVE_EDITED_BILLING_ADDRESS';
			} else if (el.hasClass('btnCancel')) {
				linkName = 'CANCEL_EDIT_BILLING_ADDRESS';
			} else {
				linkName = 'EDIT_BILLING';
			}
			break;
		case 'EDIT':
			if (el.hasClass('btnSave')) {
				linkName = 'SAVE_ADDRESS_EDIT';
			} else if (el.hasClass('btnCancel')) {
				linkName = 'CANCEL_ADDRESS_EDIT';
			} else {
				linkName = 'EDIT_SHIPPING';
			}
			break;
		}
		return linkName;
	},
	//Gift options
	giftOptionsTag: function(ele) {
		let el = $(ele),
			linkType = ele.localName,
			linkCategory = '',
			linkSection = '',
			linkName = '',
			itemNumber = typeof el.attr('data-item-number') !== 'undefined' ? el.attr('data-item-number').split(' ')[0] : '',
			wrappingEnabled = $('.giftOptionCBX').length === 0; // if TRUE then US, otherwise JP and others
		if (wrappingEnabled) {
			let sgiftOption = $('.cart .overlayGiftOptions .giftOptions input[type="radio"]:checked').val();
			switch (sgiftOption) {
			case 'msgOnly':
				linkSection = 'GIFT_MESSAGE';
				break;
			case 'msgWithWrap':
				linkSection = 'GIFT_WRAP';
				break;
			default:
				linkSection = 'NO_GIFT_OPTION';
				break;
			}
			if (el.hasClass('btnGiftOption')) {
				linkCategory = 'CHECKOUT';
				linkSection = 'EDIT_ITEM';
				linkName = 'GIFT';
			} else if (el.hasClass('btnSave')) {
				linkCategory = 'GIFT_OPTIONS';
				linkName = itemNumber;
			} else if (el.hasClass('btnCancel')) {
				linkCategory = 'GIFT_OPTIONS';
				linkSection = 'CANCEL';
				linkName = itemNumber;
			}
		} else {
			$('.giftOptions').data('gift-options-tagging-processed', 'true');
			if (el.hasClass('giftOptionEditMessage')) {
				linkCategory = 'CHECKOUT';
				linkSection = 'EDIT_ITEM';
				linkName = 'GIFT';
			} else if (el.hasClass('btnSave')) {
				linkCategory = 'GIFT_OPTIONS';
				linkSection = 'GIFT_MESSAGE';
				linkName = itemNumber;
			} else if (el.hasClass('overlayGiftOptions')) {
				linkCategory = 'GIFT_OPTIONS';
				linkSection = 'NO_GIFT_OPTION';
				linkName = itemNumber;
			}
		}
		if (linkCategory && linkSection && linkName) {
			_this.captureSitePromoData(ele, {
				linkType,
				linkCategory,
				linkSection,
				linkName
			});
		}
	},
	// order review edit
	editOrderReview: function(ele) {
		let el = $(ele),
			linkName = '';
		if (el.parents().hasClass('address')) {
			linkName = 'EDIT_ADDRESS';
		} else if (el.parents().hasClass('payment')) {
			linkName = 'EDIT_PAYMENT';
		} else if (el.hasClass('editCart')) {
			linkName = 'EDIT_CART';
		} else if (el.hasClass('btnEditPaymentOffer') || el.parents().hasClass('billingOptions')) {
			linkName = 'EDIT_PAYMENT_OFFERS';
		}
		return linkName;
	},
	//order review eQuickScreen
	eQuickScreenTag: function(ele) {
		let el = $(ele),
			linkType = ele.localName,
			linkCategory = aem_utag_data.site_promo_path,
			linkSection = 'QCARD_QUICKSCREEN',
			linkName = '';
		if (el.hasClass('qCardBenefitsInfo')) {
			linkCategory = 'CHECKOUT';
			linkSection = 'QCARD_OVERLAY';
			linkName = 'LEARNMORE';
		} else if (el.hasClass('btnEQuickScreenAccept')) {
			linkName = 'ACCEPT_OFFER';
		} else if (el.hasClass('btnEQuickScreenNotNow')) {
			linkName = 'NO_THANKS';
		} else if (el.hasClass('linkEQuickScreenDontShowAgain')) {
			linkName = 'DONT_SHOW_OFFER';
		} else if (el.text().trim() === 'QVC.com/QCard') {
			linkCategory = 'CHECKOUT';
			linkName = 'LEARNMORE';
		} else if (el.text().trim() === 'See Offer Details') {
			linkName = 'OFFER_DETAILS';
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	//EquickScreen Application
	eQuickScreenAppTag: function(ele) {
		let el = $(ele),
			linkType = ele.localName,
			linkCategory = 'QCARD',
			linkSection = 'INFORMATION_LINKS',
			linkName = '';
		if (el.hasClass('btnContinue')) {
			linkSection = 'APPLICATION';
			linkName = 'ACCEPT_AND_SUBMIT';
		} else if (el.hasClass('btnCancel')) {
			linkSection = 'APPLICATION';
			linkName = 'CANCEL';
		} else if (el.hasClass('accountOpening')) {
			linkName = 'ACCOUNT_OPENING_DISCLOSURES';
		} else if (el.hasClass('ccAgreement')) {
			linkName = 'CREDIT_CARD_AGREEMENT';
		} else if (el.hasClass('electronics')) {
			linkName = 'CONSENT_TO_ELECTRONIC_COMMUNICATIONS';
		} else if (el.hasClass('privacyPolicy')) {
			linkName = 'PRIVACY_POLICY';
		} else if (el.hasClass('disclosures')) {
			linkName = 'PRINT_DOWNLOAD_DISCLOSURES';
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	editItems: function(ele) {
		let el = $(ele),
			linkName = '';
		if (el.parents().hasClass('shipping')) {
			linkName = 'EDIT_SHIPPING_OPTION';
		} else if (el.parents().hasClass('editOption')) {
			linkName = 'EDIT_PAYMENT_OFFER';
		}
		return linkName;
	},
	//checkout shipping options
	shippingOptions: function(ele, type) {
		let linkType = ele.localName,
			linkCategory = aem_utag_data.site_promo_path,
			linkSection = type,
			linkName = $('input[name=shipOption-1]:checked').val();
		switch (linkName) {
		case 'GR':
			linkName = 'STANDARD';
			break;
		case '2D':
			linkName = 'EXPRESS';
			break;
		case '1D':
			linkName = 'PREMIUM';
			break;
		}
		if (type === 'INFO') {
			linkCategory = 'CHECKOUT';
			linkSection = 'TOOLS';
			linkName = 'SHIPPING_INFORMATION';
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	paymentOffersTag: function(ele, type) {
		let linkType = ele.localName,
			linkCategory = '',
			linkSection = '',
			linkName = '';
		if (type === 'CONTINUE') {
			linkCategory = aem_utag_data.site_promo_path;
			linkSection = 'CONTINUE';
			linkName = 'PAYMENT_OFFERS_SELECTED';
		} else if (type === 'EZPAYDETAILS') {
			linkCategory = 'CHECKOUT';
			linkSection = 'TOOLS';
			linkName = 'OFFER_DETAILS';
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	qCardAcceptDecline: function(ele) {
		let el = $(ele),
			linkName = '';
		if (el.hasClass('btnAccept')) {
			linkName = 'ACCEPT';
		} else if (el.hasClass('btnDecline')) {
			linkName = 'DECLINE';
		}
		return linkName;
	},
	osPagination: function(ele) {
		let el = $(ele),
			linkType = ele.localName,
			linkCategory = '',
			linkSection = '',
			linkName = '',
			sListName = orderStatus.osGetCurrentListName();
		if (sListName) {
			switch (sListName) {
			case 'all':
				linkCategory = 'PAGINATION';
				linkSection = 'ORDER_STATUS';
				linkName = (el.hasClass('next') ? 'NEXT' : 'PREVIOUS');
				break;
			case 'easypay':
			case 'easypaymonthly':
				linkCategory = 'PAGINATION';
				linkSection = 'ORDER_STATUS';
				linkName = (el.hasClass('next') ? 'NEXT' : 'PREVIOUS');
				if ($('input#viewByMonth').filter(':checked').length > 0) {
					linkCategory = 'MANAGE_EASY_PAY';
					linkSection = 'ACCOUNT_MANAGEMENT';
					linkName = (el.hasClass('next') ? 'EASY_PAY_NEXT' : 'EASY_PAY_PREVIOUS');
				}
				break;
			case 'autodelivery':
				linkCategory = 'MANAGE_EASY_PAY';
				linkSection = 'ACCOUNT_MANAGEMENT';
				linkName = 'AUTO_DELIVERY_' + (el.hasClass('next') ? 'NEXT' : 'PREVIOUS');
				break;
			case 'waitList':
				// placeholder
				break;
			case 'returned':
				// placeholder
				break;
			}
			_this.captureSitePromoData(ele, {
				linkType,
				linkCategory,
				linkSection,
				linkName
			});
		}
	},
	//User Preferences
	userPrefAccordion : function(ele) {
		let el = $(ele),
			linkName = '',
			accParent = el.closest('.accordion');
		if (accParent.hasClass('emailPreferences')) {
			linkName = 'EDIT_EMAIL_MESSAGES';
		} else if (accParent.hasClass('facebookPreferences')) {
			linkName = 'EDIT_FACEBOOK_MESSENGER';
		}
		return linkName;
	},
	/* Order Status */
	viewOrder: function(ele) {
		let el = $(ele),
			linkType = ele.localName,
			linkCategory = '',
			linkSection = '',
			linkName = '',
			orderType = orderStatus.osGetCurrentListName();
		if (orderType === 'easypay' && $('input#viewByMonth').filter(':checked').length > 0) {
			el.attr('href',urlManager.addParam(el.attr('href'),'qq', 'ez'));
			if (el.hasClass('btnPayNow')) {
				linkCategory = 'MANAGE_EASY_PAY';
				linkSection = 'ACCOUNT_MANAGEMENT';
				linkName = 'PAY_NOW';
			} else {
				linkCategory = 'MANAGE_EASY_PAY';
				linkSection = 'ACCOUNT_MANAGEMENT';
				linkName = 'VIEW/EDIT_EASY_PAY';
			}
		} else if (el.hasClass('btnPayNow')) {
			linkCategory = 'VIEW_OPTIONS';
			linkSection = 'TOOLS';
			linkName = 'PAY_NOW';
		} else {
			let orderLine = el.closest('.orderPanel').find('.orderLineItem');
			linkCategory = 'VIEW_OPTIONS';
			linkSection = 'VIEW_ORDER';
			linkName = typeof orderLine.data('order-status') !== 'undefined' ? qString.condenseForTag(orderLine.data('order-status')) : 'NO_STATUS_PROVIDED';
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	/* Order Status */
	viewOrderBy: function() {
		let linkName = 'VIEW_BY_MONTH';
		if ($('input#viewByOrder').filter(':checked').length > 0) {
			linkName = 'VIEW_BY_ORDER';
		}
		return linkName;
	},
	generalProdClickHandler: function(ele) {
		if (!$(".addToCartOffCanvasSidebar").hasClass("sidebarVisible")) {
			let $el = $(ele),
				$elParent = $el.parent(),
				tag = '';
			if ($el.hasClass('thumbcell')) {
				let img = $el.attr('href'),
					sImgName = typeof img === 'string' ? img.split('/')[img.split('/').length-1].split('?')[0] : 'NONAME',
					sPosition = '',
					bEnlarge = $el.closest('.zoomOverlay').length > 0; //if contained in overlay
				sImgName = sImgName.indexOf('.') > -1 ? sImgName.split('.')[1]: sImgName; //slice out '00X' extension if it exists.
				if ($el.hasClass('skuThumb')) {
					sPosition = '1';
				} else {
					$el.closest('.imageThumbnails').find('.thumbcell').not('.skuThumb').each(function(k,v){
						if (v === ele) {
							sPosition = (k + 1).toString();
							sImgName = $(ele).data('img-name') ? $(ele).data('img-name') : sImgName;
						}
					});
				}
				tag = 'PRODUCTDETAIL-_-MOREVIEWS-_-' + sImgName + ':' + sPosition + (bEnlarge === true ? '-ENLARGE' : '');
			} else if ($el.is('#s7Flyout')) {
				tag = 'PRODUCTDETAIL-_-MOREVIEWS-_-HOVERZOOM';
			} else if ($el.hasClass('enlarge')) {
				tag = 'PRODUCTDETAIL-_-MOREVIEWS-_-ENLARGE';
			} else if ($elParent.hasClass('carousel-indicators')) {
				tag = 'PRODUCTDETAIL-_-MOREVIEWS-_-SCROLL_IMAGES';
			} else if ($elParent.hasClass('channelSelector')) {
				let sChannelCode = $el.attr('data-channel-code'),
					chnlName = '';
				if (sChannelCode === 'ONQ') {
					chnlName = 'NOW';
				} else if (sChannelCode === 'STA') {
					chnlName = 'QVC3';
				} else if (sChannelCode === '2CH') {
					chnlName = 'QVC2';
				} else {
					chnlName = 'LIVE';
				}
				tag = 'PRODUCTDETAIL-_-TOOLS-_-CHANNEL_SELECTOR:' + (chnlName !== 'undefined' ? chnlName : '');
			} else if ($el.hasClass('QPayOpt')) {
				let sSelectedCreditTerm = $el.attr('value');
				tag = 'PRODUCTDETAIL-_-CREDIT_TERMS-_-' + (sSelectedCreditTerm !== 'undefined' ? sSelectedCreditTerm : '');
			} else if ($el.hasClass('swatch')) {
				let sColorOrSize = ($elParent.hasClass('selectColor') || $elParent.parent('.buyBoxColorList').length > 0) ? 'COLOR' : (($elParent.hasClass('selectSize') || $elParent.parent('.buyBoxSizeList').length > 0) ? 'SIZE' : ''),
					sSelection = $elParent.parents('li').data('original-title');
				tag = 'PRODUCTDETAIL-_-' + sColorOrSize + '-_-' + (typeof sSelection !== 'undefined' ? sSelection : '');
			} else if ($elParent.hasClass('stepWrapper')) {
				let sStepDirection = $el.hasClass('stepUp') ? 'QTY_UP' : ($el.hasClass('stepDown') ? 'QTY_DOWN' : '');
				tag = 'PRODUCTDETAIL-_-TOOLS-_-' + sStepDirection;
			} else if ($elParent.closest('.pdSquareTrade').length) {
				let sStItemNr = $elParent.data('prodId');
				tag = 'PRODUCTDETAIL-_-ALLSTATE-_-ADD:' + (sStItemNr !== null ? sStItemNr : '');
			} else if ($el.hasClass('accordionTitle')) {
				let sSectionName = $el.text();
				let sState = $elParent.hasClass('accordion_collapsed') ? 'COLLAPSE' : $elParent.is('.accordion_expanded, .accordion_preview') ? 'EXPAND' : '';
				tag = 'PRODUCTDETAIL-_-MOREINFO-_-' + sSectionName + ':' + sState;
			} else if ($elParent.hasClass('togglePreview')) {
				let sSectionName = $elParent.closest('.accordion').find('.accordionTitle').text();
				let sState = $el.hasClass('less') ? 'READ_MORE' : 'READ_LESS';
				tag = 'PRODUCTDETAIL-_-MOREINFO-_-' + sSectionName + ':' + sState;
			} else if ($elParent.hasClass('accordionFileMenu')) {
				let sSectionName = $elParent.closest('.accordion').find('.accordionTitle').text();
				let sState = $el.hasClass('less') ? 'SHOW_LESS' : 'SHOW_MORE';
				tag = 'PRODUCTDETAIL-_-MOREINFO-_-' + sSectionName + ':' + sState;
			} else if ($el.hasClass('selectLink') && $el.closest('.buyBoxSelectSize').length > 0) {
				tag = 'PRODUCTDETAIL-_-TOOLS-_-SIZEGUIDE';
			} else if ($el.is('a[href*="easypay"]')) {
				tag = 'PRODUCTDETAIL-_-TOOLS-_-OFFER_DETAILS';
			} else if ($el.hasClass('deliveryOption')) {
				let sDeliveryType = $el.hasClass('individualPurchase') ? 'SINGLE_SHIPMENT' : ($el.hasClass('adPurchase') ? 'AUTO_DELIVERY' : '');
				tag = 'PRODUCTDETAIL-_-FREQUENCY-_-' + sDeliveryType;
			} else if ($el.is('#fitsme_launcher')) {
				tag = 'PRODUCTDETAIL-_-TOOLS-_-RECOMMENDSIZE';
			} else if ($el.hasClass('btnEMailAFriend')) {
				tag = 'PRODUCTDETAIL-_-SOCIAL-_-EMAIL_PAGE';
			} else if ($el.data('pin-log') === 'button_pinit') {
				tag = 'PRODUCTDETAIL-_-SOCIAL-_-PINTEREST';
			} else if ($elParent.is('#BVRRRatingSummaryLinkWriteID')) {
				tag = 'PRODUCTDETAIL-_-BAZAARVOICE-_-WRITE_REVIEW';
			} else if ($elParent.is('.bv_cta_button')) {
				let elText = $('.bv_numReviews_text').text(),
					firstRev = elText === '(0)' ? ':FIRST_REVIEW' : '';
				tag = 'PRODUCTDETAIL-_-BAZAARVOICE-_-WRITE_REVIEW' + (firstRev !== 'undefined' ? firstRev : '');
			} else if ($elParent.is('#BVRRRatingSummaryLinkWriteFirstID')) {
				tag = 'PRODUCTDETAIL-_-BAZAARVOICE-_-WRITE_REVIEW:FIRST_REVIEW';
			} else if ($elParent.is('#BVQASummaryBoxViewQuestionsID') || $el.hasClass('BVQAPageTabLink')) {
				tag = 'COMMUNITY-_-BAZAARVOICE-_-VIEWALL_QA';
			} else if ($elParent.is('.BVRRNextPage')) {
				tag = 'PRODUCTDETAIL-_-BAZAARVOICE-_-MORE_REVIEWS';
			} else if ($elParent.is('.BVRRPreviousPage')) {
				tag = 'PRODUCTDETAIL-_-BAZAARVOICE-_-PREVIOUS_REVIEWS';
			} else if ($elParent.is('.BVRRPageNumber')) {
				tag = 'PRODUCTDETAIL-_-BAZAARVOICE-_-GOTO_PAGE';
			} else if ($elParent.is('.BVQAQuestionSummary')) {
				tag = 'COMMUNITY-_-BAZAARVOICE-_-QUESTION';
			} else if ($elParent.is('.BVQAAnswerQuestion')) {
				tag = 'COMMUNITY-_-BAZAARVOICE-_-ANSWER_QUESTION';
			} else if ($el.hasClass('BVQASearchFormTextInput')) {
				tag = 'COMMUNITY-_-BAZAARVOICE-_-ENABLE_SEARCH';
			} else if ($el.hasClass('BVQASearchFormSubmitButton')) {
				tag = 'COMMUNITY-_-BAZAARVOICE-_-SEARCH';
			} else if ($el.hasClass('BVRRDisplayContentSelect') || $el.hasClass('BVQAToolbarSortSelect')) {
				tag = 'PRODUCTDETAIL-_-BAZAARVOICE-_-SORT';
			} else if ($elParent.is('.BVQAAskQuestionTop') || $elParent.is('.BVQANoQuestions') || $elParent.is('#BVQASummaryBoxAskFirstQuestionID')) {
				tag = 'COMMUNITY-_-BAZAARVOICE-_-ASK_QUESTION';
			} else if ($elParent.is('.BVDI_FVPositive') && $el.closest('.BVRRContentReview').length > 0) {
				tag = 'PRODUCTDETAIL-_-BAZAARVOICE-_-HELPFUL_REVIEW_YES';
			} else if ($elParent.is('.BVDI_FVNegative') && $el.closest('.BVRRContentReview').length > 0) {
				tag = 'PRODUCTDETAIL-_-BAZAARVOICE-_-HELPFUL_REVIEW_NO';
			} else if ($elParent.is('.BVDI_FVReportLink') && $el.closest('.BVRRContentReview').length > 0) {
				tag = 'PRODUCTDETAIL-_-BAZAARVOICE-_-INAPPROPRIATE';
			} else if ($elParent.is('.BVDI_FVPositive') && $el.closest('.BVAnswerData').length > 0) {
				tag = 'COMMUNITY-_-BAZAARVOICE-_-HELPFUL_YES';
			} else if ($elParent.is('.BVDI_FVNegative') && $el.closest('.BVAnswerData').length > 0) {
				tag = 'COMMUNITY-_-BAZAARVOICE-_-HELPFUL_NO';
			} else if ($elParent.is('.BVDI_FVReportLink') && $el.closest('.BVQAContentData').length > 0) {
				tag = 'COMMUNITY-_-BAZAARVOICE-_-INAPPROPRIATE';
			}  else if ($elParent.is('.BVDI_FVReportLink') && $el.closest('.BVAnswerData').length > 0) {
				tag = 'COMMUNITY-_-BAZAARVOICE-_-INAPPROPRIATE';
			} else if ($el.hasClass('BVRRNickname')) {
				tag = 'PRODUCTDETAIL-_-BAZAARVOICE-_-POSTED_BY';
			} else if ($el.is('#BVRRRatingOverall_Rating_Summary_1')) {
				tag = 'PRODUCTDETAIL-_-TOOLS-_-READ_REVIEW';
			} else if ($elParent.hasClass('BVQAGuidelines')) {
				let bvAddLinks = $el.attr('name'),
					guideLines = '';
				if (bvAddLinks === 'BV_TrackingTag_QA_Display_QAndAWhatIs') {
					guideLines = 'QA';
				} else {
					guideLines = 'GUIDELINES';
				}
				tag = 'COMMUNITY-_-BAZAARVOICE-_-' + (guideLines !== 'undefined' ? guideLines : '');
			} else if ($el.hasClass('rvPrice')) {
				tag = 'PRODUCTDETAIL-_-TOOLS-_-RETAIL_VALUE';
			} else if ($el.hasClass('bv_button_buttonMinimalist')) {
				tag = 'PRODUCTDETAIL-_-TOOLS-_-WRITE_REVIEW';
			} else if ($el.hasClass('btnSaveToOrderDetail')) {
				let sSaveOrCancel = ($el.hasClass('btn-primary')) ? 'SAVE' : (($el.hasClass('btn-secondary')) ? 'CANCEL' : '');
				tag = 'PRODUCTDETAIL-_-UPDATE-_-' + sSaveOrCancel;
			} else if ($el.hasClass('bv_stars_button_container')) {
				tag = 'PRODUCTDETAIL-_-TOOLS-_-READ_REVIEWS: STARS';
			}
			if (typeof tag !== 'undefined' && tag !== '' && window.location.search.indexOf('tealiumNoUtagLink=1') === -1 && !_this.tagExists(tag) && tag.includes('-_-') && tag.split('-_-').length > 2) {
				_this.tagArray.push(tag);
				_this.captureSitePromoData(ele, { linkType: ele.localName, linkCategory: tag.split('-_-')[0], linkSection: tag.split('-_-')[1], linkName: tag.split('-_-')[2] });
			}
		}
	},
	// Sign In Overlay
	signInOverlay: function(ele) {
		let el = $(ele),
			linkType = ele.localName,
			linkCategory = (typeof loginOverlay !== 'undefined' && typeof loginOverlay.tagPromo === 'string') ? loginOverlay.tagPromo : 'GENERAL_SIGN_IN',
			linkSection = 'OVERLAY_SIGN_IN',
			linkName = '';
		if ($('#emailWr .sign-in-to-edit').hasClass('active')) {
			linkCategory = 'REAUTH_EMAIL_SIGN_IN';
		} else if ($('#securityQuestionWr .sign-in-to-edit').hasClass('active')) {
			linkCategory = 'REAUTH_QUESTION_SIGN_IN';
		}
		if (el.hasClass('btnContinue')) {
			linkName = 'SIGN_IN';
		} else if (el.hasClass('fancybox-close')) {
			linkName = 'CLOSE_SIGN_IN';
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	// Sign In Page
	signInPage : function(ele) {
		let el = $(ele),
			linkType = ele.localName,
			linkCategory = aem_utag_data.site_promo_path,
			linkSection = '',
			linkName = '';
		if (el.closest('.speedBuy').length > 0) {
			linkCategory = 'SPEEDBUY_SIGN_IN';
		}
		if (el.hasClass('btnContinue') && el.parents('.npoWr').length){ //Continue without Password button
			linkCategory = 'SIGN_IN';
			linkSection = 'TOOLS';
			linkName = 'CONTINUE_WITHOUT_PASSWORD';
		} else if (el.hasClass('btnContinue')) {
			linkName = location.href.indexOf('/checkout/login.speedbuy') > -1 ? 'SUBMIT_ORDER' : 'CONTINUE';
			if (location.href.indexOf('/checkout/login.speedbuy') > -1){
				linkSection = 'SPEEDBUY';
			} else {
				if ($('input[type="password"][name="pw"]').filter(':visible').length > 0) {
					linkSection = 'PASSWORD_SIGN_IN';
				} else {
					linkSection = 'EMAIL_SIGN_IN';
				}
			}
		} else if (el.prop('name') === 'showPw') {
			linkSection = 'TOOLS';
			if (el.prop('checked')) {
				linkName = 'SHOW';
			} else {
				linkName = 'HIDE';
			}
		} else if (el.hasClass('forgotPw')) {
			linkSection = 'TOOLS';
			linkName = 'PASSWORD_MAINTENANCE';
		} else if (el.closest('.newCust').length > 0) {
			linkSection = 'TOOLS';
			linkName = 'CONTINUE_NEW_CUSTOMER';
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	generalVideoClickHandler: function(ele) {
		let $el = $(ele),
			$elParent = $el.parent(),
			tag = '',
			sPosition = '';
		if ($el.hasClass('vjs-fullscreen-control')) {
			if ($el.closest('.video-js').hasClass('vjs-fullscreen')) {
				tag = 'VIDEO-_-CONTROLS-_-FULLSCREEN-CLOSE';
			} else {
				tag = 'VIDEO-_-CONTROLS-_-FULLSCREEN-OPEN';
			}
		} else if ($el.hasClass('fullScreenOff')) {
			tag = 'VIDEO-_-CONTROLS-_-FULLSCREEN-OPEN';
		} else if ($el.hasClass('smallScreenOff')) {
			tag = 'VIDEO-_-CONTROLS-_-FULLSCREEN-CLOSE';
		} else if ($el.hasClass('videoBckToVideoBtn')) {
			tag = 'PRODUCTDETAIL-_-TOOLS-_-RETURN_TO_VIDEO';
		} else if ($el.hasClass('videoThumb') || $el.hasClass('videoOnAirLabel')) {
			let $elVideo = $el.closest('.video'),
				videoData = ssVideoPlayer.getVideoData($elVideo.data('video-id'), ssVideoPlayer.dataKeys.product),
				sVideoName = typeof videoData !== 'undefined' ? qString.condense(videoData.title, 40, 'upper') : '';
			if ($el.hasClass('videoThumb')) {
				let isOnAir = $elVideo.hasClass('videoOnAir') || $elVideo.hasClass('videoOnAir-tn');
				if (sVideoName === '') {
					let bonusTitleAttr = $(ele).attr('title');
					let bonusVideoTitle = typeof bonusTitleAttr !== typeof undefined && bonusTitleAttr !== false ? qString.condense($(ele).attr('title'), 40, 'upper') : 'BONUS';
					sVideoName = isOnAir ? 'ONAIRPRESENTATION' : bonusVideoTitle;
				}
				if (isOnAir) {
					sPosition = 1;
				} else {
					//bonus video
					sPosition = 1;
					$('.productThumbnails :not(.videoOnAir) .videoThumb').not('.videoOnAir-tn .videoThumb').each(function(index, videoElement) {
						if (videoElement === ele) {
							sPosition = index + 1;
						}
					});
				}
				tag = 'PRODUCTDETAIL-_-VIDEO_ICONS-_-' + sVideoName + '-' + sPosition;
			} else if ($el.hasClass('videoOnAirLabel')) {
				sPosition = 1;
				sVideoName = sVideoName === '' ? 'ONAIRPRESENTATION' : sVideoName;
				tag = 'PRODUCTDETAIL-_-VIDEO_TEXT-_-' + sVideoName + '-' + sPosition;
			}
		} else if ($el.hasClass('vjs-menu-item-text') && $el.closest('.vjs-subs-caps-button').length > 0) {
			tag = 'VIDEO-_-CONTROLS-_-CAPTIONS-';
			if ($elParent.hasClass('vjs-texttrack-settings')) {
				tag += 'SETTINGS';
			} else if ($elParent.is('.vjs-captions-menu-item, .vjs-subtitles-menu-item')) {
				tag += 'ON';
			} else {
				tag += 'OFF';
			}
		} else if ($el.attr('id') === 'toggleBuybox') {
			tag = 'VIDEO-_-BUYBOX-_-'+ ($elParent.hasClass('hiddenBuyBox') ? 'MAXIMIZE' : 'MINIMIZE');
		} else if ($el.hasClass('vjs-mute-control')) {
			tag = 'VIDEO-_-CONTROLS-_-'+ ($el.hasClass('vjs-vol-0') ? 'UNMUTE' : 'MUTE');
		} else if ($el.hasClass('vjs-volume-bar')) {
			let isMuted = $el.closest('.video-js').find('.vjs-mute-control').hasClass('vjs-vol-0');
			if (isMuted) {
				tag = 'VIDEO-_-CONTROLS-_-UNMUTE';
			}
		} else if ($el.hasClass('vjs-big-play-button') || $el.hasClass('vjs-play-wrapper')) {
			tag = 'VIDEO-_-CONTROLS-_-PLAY';
		} else if ($el.hasClass('vjs-play-control')) {
			tag = 'VIDEO-_-CONTROLS-_-' + ($el.hasClass('vjs-paused') ? 'PLAY' : 'PAUSE');
		} else if ($el.is('video')) {
			// Only valid if .vjs-play-wrapper covers entire video element when paused, else could be a PLAY too
			tag = 'VIDEO-_-CONTROLS-_-PAUSE';
		} else if ($el.hasClass('vjs-live-button') || $el.hasClass('vjs-live-controls')) {
			tag = 'VIDEO-_-CONTROLS-_-RESUME_LIVE';
		} else if ($el.hasClass('carousel-toggle')) {
			tag = 'VIDEO-_-CONTROLS-_-DISPLAY_PREVIOUS_SHOWS';
		} else if ($el.hasClass('programme')) {
			if ($el.hasClass('currently-live') || $el.data('vod')) {
				tag = 'OTHER_VIDEOS-_-' + qString.condenseForTag($el.data('cm-title')) + '-_-' +
					$el.data('cm-date') + '_' + $el.data('cm-time') + '_' + ($el.data('cm-duration') < 100 ? '0' : '') +
					$el.data('cm-duration') + ($el.hasClass('currently-live') ? ':LIVE' : '');
			}
		} else if ($elParent.hasClass('videoCheckAutoPlay')) {
			tag = 'PRODUCTDETAIL-_-TOOLS-_-AUTOPLAY:' + ($el.hasClass('active') ? 'ON' : 'OFF');
		} else if ($el.parents('.channelSelector').length > 0 && $el.data('channelcode') && $el.hasClass('activeChannel')) {
			let sc = aem_utag_data.aemchannels[shopLiveTV.player.curChannel].sc;
			tag = 'LIVETV-_-STREAM-_-' + sc;
		}
		if (typeof tag !== 'undefined' && tag !== '' && window.location.search.indexOf('tealiumNoUtagLink=1') === -1 && tag.includes('-_-') && tag.split('-_-').length > 2) {
			_this.captureSitePromoData(ele, { linkType: ele.localName, linkCategory: tag.split('-_-')[0], linkSection: tag.split('-_-')[1], linkName: tag.split('-_-')[2] });
		}
	},
	//Order Receipt
	orderReceipt: function() {
		let linkCategory = '',
			pCat = window.localStorage.getItem('promoCat');
		if (pCat === null && document.referrer.indexOf('/checkout/reg/') > -1) {
			linkCategory = 'STREAMLINE_CHECKOUT';
		} else if (document.referrer.indexOf('/checkout/npo/') > -1) {
			linkCategory = 'NPO_CHECKOUT';
		} else if (document.referrer.indexOf('/checkout/new/') > -1) {
			linkCategory = 'NEW_CUSTOMER_CHECKOUT';
		} else if (pCat === 'SPEEDBUY_CHECKOUT' && document.referrer.indexOf('/checkout/reg/') > -1) {
			linkCategory =  pCat;
		} else if (pCat === 'PAYPAL_CHECKOUT' && document.referrer.indexOf('/checkout/reg/') > -1) {
			linkCategory =  pCat;
		}
		return linkCategory;
	},
	/* Easy Pay Module */
	ezPayTag : function(ele) {
		let el = $(ele),
			tag = '';
		if (typeof el.data('pb') === 'boolean') {
			if (el.data('pb')) {
				tag = 'EASY_PAY-_-TOOLS-_-PAY_BALANCE';
			} else {
				let payType = el.closest('.ezPayDue').length > 0 ? 'PAST_DUE' : 'TOOLS';
				tag = 'EASY_PAY-_-' + payType + '-_-PAY_INSTALLMENT';
			}
		} else {
			if (el.closest('.edit').length > 0) {
				tag = 'EASY_PAY-_-TOOLS-_-EDIT_PAYMENT_METHOD';
			} else if (el.hasClass('accordionTitle')) {
				let accState = el.closest('.accordion').hasClass('accordion_collapsed') ? 'COLLAPSE' : 'OPEN';
				tag = 'EASY_PAY-_-PAYMENT_HISTORY-_-' + accState;
			} else if (el.hasClass('btnSave') || el.hasClass('btnCancel')) {
				let btnClick = el.hasClass('btnSave') ? 'PAY' : 'CANCEL';
				let payType = orderUpdate.easyPay.oOptions.obj.data('pb') === true ? 'PAY_BALANCE' : 'MAKE_PAYMENT';
				tag = 'EASY_PAY-_-' + payType + '-_-' + btnClick;
			} else {
				return;
			}
		}
		if (typeof tag !== 'undefined' && tag !== '' && tag.includes('-_-') && tag.split('-_-').length > 2) {
			_this.captureSitePromoData(ele, { linkType: ele.localName, linkCategory: tag.split('-_-')[0], linkSection: tag.split('-_-')[1], linkName: tag.split('-_-')[2] });
		}
	},
	landingLinks: function(ele) {
		let el = $(ele),
			tag = '';
		if (typeof(el.attr('href')) === 'undefined' || el.attr('href').indexOf('#') === 0) {
			return;
		}
		let linkTxt = qString.condenseForTag(el.text());
		switch (linkTxt) {
		case 'ORDER_STATUS':
		case 'BESTELLSTATUS':
			tag = 'MANAGE-_-ORDERS-_-ORDER_STATUS';
			break;
		case 'MANAGE_EASY_PAY_ORDERS':
			tag = 'MANAGE-_-ORDERS-_-EASYPAY';
			break;
		case 'MANAGE_AUTO-DELIVERY_ORDERS':
			tag = 'MANAGE-_-ORDERS-_-AUTO-DELIVERY';
			break;
		case 'WRITE_A_REVIEW':
			tag = 'ORDER_OPTIONS-_-TOOLS-_-WRITE_A_REVIEW';
			break;
		case 'ACCOUNT_SETTINGS':
		case 'ACCOUNT_DETAILS':
		case 'KONTODATEN':
			tag = 'MANAGE-_-ACCOUNT-_-ACCOUNT_SETTINGS';
			break;
		case 'SHIPPING_ADDRESSES':
		case 'DELIVERY_ADDRESSES':
		case 'LIEFERADRESSE':
			tag = 'MANAGE-_-ACCOUNT-_-SHIPPING_ADDRESS';
			break;
		case 'MOBILE_EMAIL_PREFERENCES':
			tag = 'MANAGE-_-ACCOUNT-_-MOBILE_AND_EMAIL';
			break;
		case 'MANAGE_PAYMENT_METHODS':
		case 'YOUR_CREDIT_CARDS':
		case 'DEINE_ZAHLUNGSARTEN':
			tag = 'MANAGE-_-ACCOUNT-_-PAYMENT_METHODS';
			break;
		case 'MANAGE_PAY_QCARD':
			tag = 'MANAGE-_-ACCOUNT-_-QCARD';
			break;
		case 'LEARN_MORE_APPLY_FOR_QCARD':
			tag = 'TOOLS-_-LEARN_MORE-_-QCARD';
			break;
		case 'WISH_LIST':
		case 'WISHLIST':
		case 'WUNSCHLISTE':
			tag = 'MANAGE-_-ACCOUNT-_-WISHLIST';
			break;
		case 'RETURNS_EXCHANGES':
		case 'RETURNS_DISPOSAL':
		case 'RÜCKSENDUNG_ENTSORGUNG':
			tag = 'MANAGE-_-ORDERS-_-RETURNS_AND_EXCHANGES';
			break;
		case 'MY_RECOMMENDATIONS':
			tag = 'TOOLS-_-RESOURCES-_-MY_RECOMMENDATIONS';
			break;
		case 'CONTACT_US':
		case 'Q_CONTACT':
		case 'Q_KONTAKT':
			tag = 'TOOLS-_-CUSTOMER_SERVICE-_-CONTACT_US';
			break;
		case 'PRIVACY_POLICY':
		case 'DATA_PROTECTION_CENTER':
		case 'DATENSCHUTZBESTIMMUNGEN':
			tag = 'MANAGE-_-ACCOUNT-_-DATA_PROTECTION';
			break;
		default:
			tag = '';
		}
		if (typeof tag !== 'undefined' && tag !== '' && tag.includes('-_-') && tag.split('-_-').length > 2) {
			_this.captureSitePromoData(ele, { linkType: ele.localName, linkCategory: tag.split('-_-')[0], linkSection: tag.split('-_-')[1], linkName: tag.split('-_-')[2] });
		}
	},
	myAccountSignInConversion: function(ele, isLinkTxt) {
		let linkTxt = '',
			pathIndicator = '';
		if (!isLinkTxt) {
			linkTxt = qString.condenseForTag($(ele).text());
		} else {
			linkTxt = ele;
		}
		switch (linkTxt) {
		case 'ORDER_STATUS':
		case 'ORDERSTATUS':
			pathIndicator = 'ORDER STATUS';
			break;
		case 'MANAGE_EASY_PAY_ORDERS':
			pathIndicator = 'MANAGE EASYPAY';
			break;
		case 'MANAGE_AUTO-DELIVERY_ORDERS':
		case 'MANAGE_AUTO_DELIVERY':
			pathIndicator = 'MANAGE AUTO-DELIVERY';
			break;
		case 'WRITE_A_REVIEW':
			pathIndicator = 'WRITE REVIEW';
			break;
		case 'ACCOUNT_SETTINGS':
			pathIndicator = 'ACCOUNT SETTINGS';
			break;
		case 'SHIPPING_ADDRESSES':
			pathIndicator = 'MANAGE SHIPPING ADDRESS';
			break;
		case 'MOBILE_EMAIL_PREFERENCES':
			pathIndicator = 'EMAIL PREFERENCES';
			break;
		case 'MANAGE_PAYMENT_METHODS':
			pathIndicator = 'MANAGE PAY METHOD';
			break;
		case 'WISH_LIST':
		case 'WISHLIST':
			pathIndicator = 'WISHLIST';
			break;
		case 'SHOW_TUNE-IN_REMINDERS':
			pathIndicator = 'TUNE IN REMINDER';
			break;
		}
		if (pathIndicator) {
			window.localStorage.setItem('signInPath', pathIndicator);
		}
	},
	gdprTags: function(ele) {
		let el = $(ele),
			form = el.closest('form'),
			id = form.attr('id'),
			btnType = el.attr('class'),
			linkName = '';
		switch (id) {
		case 'gdprOptOut': {
			let opts = '';
			form.find('input').each(function(i,el) {
				let cat = '';
				switch (el.id) {
				case 'c2':
					cat = 'ADS';
					break;
				case 'c3':
					cat = 'AN-PERS';
					break;
				case 'c4':
					cat = 'REW';
					break;
				}
				let state = 'NA';
				if (!$(el).closest('fieldset').hasClass('hidden')) {
					state = $(el).prop('checked') ? 'ON' : 'OFF';
				}
				opts += (i>0?'|':'') + cat + ':' + state;
			});
			linkName = opts;
		}
			break;
		case 'gdprDataPortabilityForm':
		case 'gdprRightToBeForgottenForm':
		case 'gdprRestrictDataForm':
		case 'gdprObjectDataProcessForm':
		case 'gdprRequestDataCopyForm':
			if (Helper.aaGDPR('c3')){  //disable these tags if user is opt'd out
				return;
			}
			linkName = 'SUBMIT';
			if (btnType.indexOf('btnCancel') > 1) {
				linkName = 'CANCEL';
			}
			break;
		}
		return linkName;
	},
	recipeTags: function(ele) {
		let el = $(ele),
			thumb = el.parent(),
			linkType = ele.localName,
			linkCategory = 'RECIPES',
			linkSection = 'MORE_VIEWS',
			linkName = (thumb.index() + 1);
		if (thumb.hasClass('videoThumb')) {
			linkSection = 'VIDEO_ICONS';
		}
		if (thumb.hasClass('recipePrint')) {
			linkSection = 'PRINT';
			linkName = 'BOTTOM';
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	headerTag: function(ele) {
		let el = $(ele),
			flyout = el.closest('.flyoutSection'),
			flyoutCat = flyout.find('.mobFlyoutCat').text(),
			linkTxt = el.text();
		linkTxt = qString.condenseForTag(linkTxt);
		flyoutCat = qString.condenseForTag(flyoutCat);
		return flyoutCat + ':' + linkTxt;
	},
	// MH Search
	searchTag: function(ele, type) {
		let tag = '';
		if (type === 'focus') {
			tag = 'MH-_-ENGAGE-_-SEARCH';
		} else if (type === 'toggle' && $('.searchInputBox').css('opacity') === '0') {
			tag = 'MH-_-DISPLAY-_-SEARCH_BAR';
		} else if (type === 'submit') {
			let srchTxt = $('form.searchForm [name="keyword"]').val();
			if (srchTxt.length < 1) {
				return;
			}
			srchTxt = qString.condenseForTag(srchTxt);
			tag = 'MH-_-SEARCH-_-' + srchTxt;
		} else {
			return;
		}
		if (typeof tag !== 'undefined' && tag !== '' && tag.includes('-_-') && tag.split('-_-').length > 2) {
			_this.captureSitePromoData(ele, { linkType: ele.localName, linkCategory: tag.split('-_-')[0], linkSection: tag.split('-_-')[1], linkName: tag.split('-_-')[2] });
		}
	},
	openTag: function(ele) {
		if ($('#mobileMenu').filter(':visible').length < 1) {
			return;
		}
		if ($(ele).attr('id') === 'mobileMenu') {
			if ($('[data-component-type="MASTHEAD_POP"]').filter(':visible').length > 0) {
				return;
			}
			_this.captureSitePromoData(ele, { linkType: ele.localName, linkCategory: 'MH', linkSection: 'MENU', linkName: 'OPEN' });
		} else {
			if ($('[data-component-type="MASTHEAD_POP"]').filter(':visible').length < 1) {
				return;
			}
			_this.captureSitePromoData(ele, { linkType: ele.localName, linkCategory: 'MH', linkSection: 'MENU', linkName: 'MAIN_MENU' });
		}
	},
	openorcloseTag: function(ele) {
		let el = $(ele),
			linkType = ele.localName,
			linkCategory = 'REFINEMENTS',
			linkSection = el.text(),
			linkName = '';
		linkSection = qString.condenseForTag(linkSection);
		if (el.parents('.facetedMulti').find('h3').hasClass('expanded')) {
			linkName = 'OPEN';
		} else {
			linkName = 'CLOSE';
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	sortTag: function(ele) {
		let el = $(ele),
			linkType = ele.localName,
			linkCategory = 'PRODUCT_LISTING',
			linkSection = 'SORT',
			linkName = el.text(),
			module = el.parents('[data-module-type]');
		linkName = qString.condenseForTag(linkTxt);
		if (module.data('module-type') === 'WISH_LIST') {
			linkCategory = 'WISHLIST';
			linkName = linkName.replace(/PRICE_-_/,'PRICE_');
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	imgTag: function(ele) {
		let el = $(ele),
			component = el.parents('[data-component-type]'),
			linkType = ele.localName,
			linkCategory = 'PAGINATION',
			linkSection = component.last().data('component-type'),
			linkName = '';
		if (el.hasClass('prvsPage')) {
			linkName = 'DOWN';
		} else if (el.hasClass('nxtPage')) {
			linkName = 'UP';
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	moreTag: function(ele) {
		let el = $(ele),
			multiHead = el.parents('.facetedMulti').find('h3').text(),
			linkType = ele.localName,
			linkCategory = 'REFINEMENTS',
			linkSection = 'MORE_OPTIONS',
			linkName = '';
		if (el.hasClass('btnDone')) {
			linkName = 'DONE';
		} else if (el.hasClass('btnClear')) {
			linkName = 'CLEAR_ALL_SELECTIONS';
		} else if (el.hasClass('btnClose')) {
			linkName = 'CANCEL';
		} else {
			linkSection = qString.condenseForTag(multiHead);
			linkName = 'MORE_OPTIONS';
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	valMoreTag: function(ele) {
		let el = $(ele),
			tfcondition = el.attr('data-selected'),
			linkType = ele.localName,
			linkCategory = 'REFINEMENTS',
			linkSection = el.parents('.facetedMulti').find('h3').text(),
			linkName = el.find('.refinementTxt').text();
		linkSection = qString.condenseForTag(linkSection);
		linkName = qString.condenseForTag(linkName);
		if (tfcondition === 'false') {
			linkName += ':' + 'REMOVE';
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	iroaListLinkTag: function(ele) {
		let el = $(ele);
		linkName = '';
		if (el.closest('.watchLiveLink').length) {
			linkName = 'WATCH_LIVE';
		} else if (el.hasClass('remindMeLink')) {
			linkName = 'REMIND_ME';
		} else {
			linkName = el.text().toUpperCase();
		}
		return linkName;
	},
	iroaDateTag: function(ele) {
		let el = $(ele),
			linkName = '',
			iIdx = el.prop('nodeName').toLowerCase() === 'select' ? el.find(':selected').index() : el.index();
		if (iIdx > 1){
			linkName = iIdx + '_DAYS_AGO';
		} else {
			linkName = iIdx ? 'YESTERDAY' : 'TODAY';
		}
		return linkName;
	},
	iroaItemTag: function(ele) {
		let el = $(ele),
			href = '',
			linkType = ele.localName,
			linkCategory = 'PRODUCT_LIST',
			linkSection = aem_utag_data.source_code,
			linkName = '';
		if (el.attr('href') === undefined || (el.attr('href').indexOf('#') === 0 && el.parent().attr('id') !== 'mobileMenuIcon')) {
			return;
		} else {
			href = el.attr('href');
		}
		if (el.parents().hasClass('galleryItem')) {
			let prodNum = href.match(new RegExp(/[/.]product\.(.*)\.html/));
			if (prodNum === null) {
				prodNum = [0, qGet.queryParam(href,'partNumber')];
			}
			if (prodNum !== null && prodNum.length > 1) {
				prodNum = prodNum[1];
			}
			linkName = prodNum;
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	/* TV Program Guide */
	tvpgDaySelectionTag: function(ele) {
		let el = $(ele),
			iIdx = el.find('option:selected').index() - $('.daySelection [data-today="true"]').index();
		return (iIdx === 0 ? 'TODAY' : (iIdx < 0 ? (iIdx === -1 ? 'YESTERDAY' : Math.abs(iIdx) + '_DAYS_AGO') : (iIdx === 1 ? 'TOMORROW' : 'IN_' + Math.abs(iIdx) + '_DAYS')));
	},
	tvpgNavArrowsShowsTag: function(ele) {
		let el = $(ele),
			linkName = '';
		if (el.hasClass('prev')) {
			linkName= 'PREVIOUS_SHOWS';
		} else if (el.hasClass('next')) {
			linkName= 'FUTURE_SHOWS';
		}
		return linkName;
	},
	tvpgMoreInfoTag: function(ele) {
		let el = $(ele),
			linkType = ele.localName,
			linkCategory = '',
			linkSection = '',
			linkName = '';
		if (el.parents('.show')) {
			linkCategory = Helper.getChannelSc(el.closest('.showList ul').data('channelcode') || $('.channels .activeChannel').data('channelcode'));
			linkSection = 'MORE_INFO';
			linkName = qString.condenseForTag(el.closest('li').data('showcode')) + ':' + qString.condenseForTag(el.closest('li').data('showname'));
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	tvpgWatchTag: function(ele) {
		let el = $(ele),
			linkType = ele.localName,
			linkCategory = '',
			linkSection = '',
			linkName = '',
			selBtn = '',
			parentElem = el.closest('li');
		if (el.data('uvid')) {
			selBtn = 'PLAY_VIDEO';
		} else if (el.hasClass('reminderLink')) {
			selBtn = 'SET_REMINDER';
			$('.reminderLayer').removeClass('search');
		}
		if (el.parents('.show')) {
			linkCategory = Helper.getChannelSc(el.closest('.showList ul').data('channelcode') || $('.channels .activeChannel').data('channelcode'));
			linkSection = selBtn;
			linkName = parentElem.data('sourcecode') + '&#124;' + qString.condenseForTag(parentElem.data('showcode')) + ':' + qString.condenseForTag(parentElem.data('showname'));
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	tvpgResultsRemindMeTag: function(ele) {
		let el = $(ele),
			ch = Helper.getChannelSc(el.closest('li').data('channelcode')),
			sourceCode = el.data('sourcecode') || '',
			showCode = qString.condenseForTag(el.data('showcode')) || '',
			showName = qString.condenseForTag(el.parent().find('.ellipsis').text()),
			pos = el.parent().index() + 1,
			linkType = ele.localName,
			linkCategory = 'SEARCH:' + ch,
			linkSection = 'SET_REMINDER',
			linkName = sourceCode + '&#124;' + showCode + ':' + showName + '-' + pos;
		$('.reminderLayer').addClass('search');
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	tvpgMoreWatchShowTag: function(ele) {
		let el = $(ele),
			parentElem = el.closest('.showDataContainer'),
			ch = Helper.getChannelSc(parentElem.data('channelcode') || $('.channels .activeChannel').data('channelcode')),
			linkType = ele.localName,
			linkCategory = '',
			linkSection = '',
			linkName = '';
		if (el.data('uvid')) {
			selBtn = 'PLAY_VIDEO';
		} else if (el.hasClass('reminderLink')) {
			selBtn = 'SET_REMINDER';
		}
		if (el.parents('.show')) {
			linkCategory = ch;
			linkSection = 'MORE_INFO:' + selBtn;
			linkName = parentElem.data('sourcecode') + '&#124;' + qString.condenseForTag(parentElem.data('showcode')) + ':' + qString.condenseForTag(parentElem.data('showname'));
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	tvpgPrintTag: function(ele) {
		let el = $(ele),
			wShowTime = 'FUTURE',
			linkType = ele.localName,
			linkCategory = '',
			linkSection = '',
			linkName = '';
		if ($('.showList .currentShow').length > 0) {
			wShowTime = 'CURRENT';
		}
		if ($('.weekSelection .prev').hasClass('disabled')) {
			wShowTime = 'PAST';
		}
		if (el.parents('.weeklyView')) {
			linkCategory = 'TOOLS',
			linkSection = 'PRINT',
			linkName = wShowTime;
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	tvpgItemTag: function(ele) {
		let el = $(ele),
			href = '',
			linkType = ele.localName,
			linkCategory = '',
			linkSection = '',
			linkName = '';
		if (el.attr('href') === undefined || (el.attr('href').indexOf('#') === 0 && el.parent().attr('id') !== 'mobileMenuIcon')) {
			return;
		} else {
			href = el.attr('href');
		}
		if (el.parents().hasClass('carouselItem')) {
			let prodNum = href.match(new RegExp(/[/.]product\.(.*)\.html/));
			if (prodNum === null) {
				prodNum = [0,qGet.queryParam(href,'partNumber')];
			}
			if (prodNum !== null && prodNum.length > 1) {
				prodNum = prodNum[1];
			}
			linkCategory = 'ON_THE_SHOW',
			linkSection = prodNum,
			linkName = el.closest('.showDataContainer').attr('data-sourcecode') + '&#124;' + qString.condenseForTag(el.closest('.showDataContainer').data('showcode')) + ':' + qString.condenseForTag(el.closest('.showDataContainer').data('showname'));
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	tvpgSearchTag: function(ele, oData){
		let srchTxt = $('.programGuideSearchForm .form-group input').val() || (typeof oData === 'object' && oData.searchTerm),
			linkType = ele.localName,
			linkCategory = 'TOOLS',
			linkSection = 'SEARCH',
			linkName = qString.condenseForTag(srchTxt);
		if (srchTxt.length < 1) {
			return;
		}
		if (typeof oData !== 'object') {
			_this.captureSitePromoData(ele, {
				linkType,
				linkCategory,
				linkSection,
				linkName
			});
		}
	},
	tvpgChnSearchTag: function(ele) {
		let el = $(ele),
			ch = el.parent().data('sc'),
			srchTxt = $('.programGuideSearchForm .form-group input').val(),
			linkType = ele.localName,
			linkCategory = 'SEARCH:' + ch,
			linkSection = qString.condenseForTag(srchTxt),
			linkName = el.closest('li').data('count');
		if (srchTxt.length < 1) {
			return;
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	tvpgTimeZoneTag: function(ele) {
		let el = $(ele),
			sTz = ele.tagName.toLowerCase() === 'a' ? el.parent().data('shortname') : el.children(':selected').data('shortname');
		return sTz ? sTz.replace(/s/i,'').toUpperCase() : 'ET';
	},
	wishlistShareTags: function(ele, type){
		let linkType = ele.localName,
			linkCategory = 'WISHLIST',
			linkSection = '',
			linkName = '';
		if (type === 'share') {
			linkSection = 'SOCIAL';
			linkName = 'EMAIL_PAGE';
		}
		if (type === 'send') {
			dataSP += 'EMAIL-_-SEND';
			linkSection = 'EMAIL';
			linkName = 'SEND';
		}
		_this.captureSitePromoData(ele, {
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	breadcrumbTags: function(ele, e) {
		if (e.which === 3) {
			return;
		}
		let el = $(ele),
			linkType = ele.localName,
			linkCategory = 'BREADCRUMB',
			linkSection = qString.condenseForTag(el.text()),
			linkName = el.parents('.breadcrumbs li').index() + 1;
		if (el.attr('href') === undefined || el.attr('href').indexOf('#') === 0) {
			return;
		}
		el.attr('href',urlManager.addParam(el.attr('href'),'qq','bc'));
		_this.captureSitePromoData(el,{
			linkType,
			linkCategory,
			linkSection,
			linkName
		});
	},
	generalLinkSPClick: function(ele,e) {
		const clickedAnchorTag = $(ele).closest('a')[0],
			isAnchorClick = clickedAnchorTag !== undefined;
		if (e.which === 3) {
			return;
		}
		let el = isAnchorClick ? $(ele).closest('a') : $(ele),
			href = '';
		if (el.prop('tagName').toLowerCase() === 'li' || el.prop('tagName').toLowerCase() === 'form' || el.prop('tagName').toLowerCase() === 'button') {
			href = '';
		} else if (el.parent('.togglePreview').length >= 1) {
			href = '';
		} else if ((typeof el.attr('href') === 'undefined' || el.attr('href').indexOf('#') === 0) && el.text() !== 'Feedback' && el.text() !== 'Chat Live' && typeof el.attr('data-href') === 'undefined') {
			return;
		} else {
			href = el.attr('data-href') || el.attr('href');
		}
		let elTag = el;
		if (typeof elTag.data('feature-name') === 'undefined') {
			elTag = el.parents('[data-feature-name]');
		}
		let component = el.parents('[data-component-type]'),
			module = el.parents('[data-module-type]'),
			anchors = component.find('a:not([href*="#scroll"])'),
			position = anchors.index(el) + 1,
			breakpoint = GlobalPage.getBreakpoint(),
			dataSP = '',
			linkTxt = '',
			multiHead = '',
			prodNum = '',
			pposition = '',
			recipeName = '',
			shopCat = '',
			tag = '',
			ch = '',
			isItemLink = (href.indexOf('.product.') !== -1) || (href.indexOf('/product.') !== -1) || (qGet.queryParam(href, 'partNumber') !== null),
			isCore = el.closest('[data-component-tag-name]').length > 0;
		if (breakpoint >= 1200) {
			breakpoint = 1200;
		} else if (breakpoint < 1200 && breakpoint >= 960) {
			breakpoint = 960;
		} else if (breakpoint < 960 && breakpoint >= 800) {
			breakpoint = 800;
		} else if (breakpoint < 800 && breakpoint >= 640) {
			breakpoint = 640;
		} else if (breakpoint < 640) {
			breakpoint = 320;
		}
		if (module.data('module-type') === 'SEARCH_MESSAGING') {
			linkTxt = el.text();
			linkTxt = qString.condenseForTag(linkTxt);
			if (el.hasClass('dymWord')) {
				dataSP = 'SEARCH' + '-_-' + 'DID_YOU_MEAN' + '-_-' + linkTxt;
			} else {
				return;
			}
		} else if (module.data('module-type') === 'PRODUCT_LISTING' || module.data('module-type') === 'RECIPE_LISTING') {
			multiHead = el.closest('.facetedMulti').find('h3').text();
			multiHead = qString.condenseForTag(multiHead);
			linkTxt = el.text();
			if (module.data('module-type') === 'RECIPE_LISTING') {
				linkTxt = el.find('h4').text();
			}
			linkTxt = qString.condenseForTag(linkTxt);
			let refinetxt = el.find('.refinementTxt').text();
			refinetxt = qString.condenseForTag(refinetxt);
			if (component.last().data('component-type') === 'PRODUCT_LISTING_NAV' || component.last().data('component-type') === 'RECIPE_LISTING_NAV') {
				if (el.parents('.refinementImg').find('.refinementLnk').hasClass('selected')) {
					dataSP = 'REFINEMENTS' + '-_-' + multiHead + '-_-' + refinetxt + ':' + 'REMOVE';
				} else if (el.parents().hasClass('facetedSingle')) {
					dataSP = 'REFINEMENTS' + '-_-' + 'CATEGORY' + '-_-' + refinetxt;
				} else {
					dataSP = 'REFINEMENTS' + '-_-' + multiHead + '-_-' + refinetxt;
				}
			}
			if (component.last().data('component-type') === 'PRODUCT_LISTING_LIST' || component.last().data('component-type') === 'WISH_LIST_ELEMENTS' || component.last().data('component-type') === 'RECIPE_LISTING_LIST') {
				if (el.parents().hasClass('productResultFacets')) {
					dataSP = 'REFINEMENTS' + '-_-' + component.last().data('component-type') + '-_-' + 'REMOVE_ALL';
				} else if (el.parents().hasClass('galleryItem')) {
					prodNum = href.match(new RegExp(/[/.]product\.(.*)\.html/));
					pposition = el.parents('.galleryItem').index();
					if (prodNum === null) {
						prodNum = [0,qGet.queryParam(href,'partNumber')];
					}
					if (prodNum.length > 1) {
						prodNum = prodNum[1];
					}
					dataSP = 'VIEWPOSITION' + '-_-' + prodNum + '-_-' + pposition;
				} else if (el.parents().hasClass('recipeItem')) {
					recipeName = linkTxt;
					pposition = el.parents('.recipeItem').index();
					dataSP = 'VIEWPOSITION' + '-_-' + pposition + '-_-' + recipeName;
				} else if (el.parents().hasClass('pagination')) {
					dataSP = 'PAGINATION' + '-_-' + component.last().data('component-type') + '-_-' + linkTxt;
				} else if (el.parents().hasClass('selectedResultFacets')) {
					multiHead = el.data('grouping');
					multiHead = qString.condenseForTag(multiHead);
					dataSP = 'REFINEMENTS' + '-_-' + multiHead + '-_-' + linkTxt + ':' + 'REMOVE';
				}
			}
		} else if (module.data('module-type') === 'MH') {
			if (component.last().data('component-type') === 'MASTHEAD_TOP_DRAWER') {
				dataSP = 'MH-_-TOPDRAWER-_-TOPDRAWER';
			} else if (component.last().data('component-type') === 'MASTHEAD_LINKS' || (component.last().data('component-type') === 'MASTHEAD_MENU' && el.closest('[data-component-type="MASTHEAD_LINKS"]').length > 0)) {
				if (el.closest('.shopByCat').length > 0) {
					if (el.closest('.shopByDDOpen').length < 1) {
						linkTxt = Helper.getLinkText(el);
						dataSP = 'MH-_-' + linkTxt + '-_-' + linkTxt;
					} else {
						shopCat = el.closest('.flyoutSection').find('.mobFlyoutCat').text();
						linkTxt = el.text();
						shopCat = qString.condenseForTag(shopCat);
						linkTxt = qString.condenseForTag(linkTxt);
						dataSP = 'MH-_-' + shopCat + '-_-' + linkTxt;
					}
				} else {
					linkTxt = Helper.getLinkText(el);
					dataSP = 'MH-_-' + linkTxt + '-_-VISIBLE';
				}
			} else if (component.last().data('component-type') === 'MASTHEAD_MENU') {
				if (el.closest('.menuLogo').length > 0) {
					dataSP = 'MH-_-LOGO-_-LOGO';
				} else if (el.closest('.menuCart').length > 0) {
					dataSP = 'MH-_-CART-_-OPEN_CART';
				} else if (el.closest('.myAccountMenu').length > 0) {
					if (el.closest('.myAccountDropDown').length < 1) {
						dataSP = 'MH-_-ACCOUNT_MENU-_-GREETING';
					} else {
						linkTxt = Helper.getLinkText(el);
						dataSP = 'MH-_-ACCOUNT_MENU-_-' + linkTxt;
					}
				} if (el.closest('.shopQVCTV').length > 0) {
					if (el.closest('.shopTVDropDown').length < 1) {
						dataSP = 'MH-_-SHOP_QVC_LIVE-_-SHOPQVCTV';
					} else {
						linkTxt = Helper.getLinkText(el);
						dataSP = 'MH-_-SHOP_QVC_LIVE-_-' + linkTxt;
					}
				} else if (el.closest('.menuIROA').length > 0) {
					dataSP = 'MH-_-RECENTLY_ON_AIR-_-VISIBLE';
				} else if (el.find('.mhIconText').text()) {
					linkTxt = el.find('.mhIconText').text();
					linkTxt = qString.condenseForTag(linkTxt);
					dataSP = 'MH-_-' + linkTxt + '-_-VISIBLE';
				} else if (ele && $(ele).closest('[manual_cm_sp]').length && $(ele).closest('[manual_cm_sp]').attr('manual_cm_sp')) {
					linkTxt = $(ele).closest('[manual_cm_sp]').attr('manual_cm_sp');
					linkTxt = qString.condenseForTag(linkTxt);
					if (linkTxt !== '') {
						linkTxt = linkTxt.replaceAll('__', '_');
					}
					dataSP = linkTxt;
				} else if (ele && $(ele).closest('[onclick]').length && $(ele).closest('[onclick]').attr('onclick') && $(ele).closest('[onclick]').attr('onclick').includes('cm_sp=')) {
					let aaSpData = $(ele).closest('[onclick]').attr('onclick').split('cm_sp=')[1];
					if (aaSpData.includes('&')) {
						aaSpData = aaSpData.split('&')[0];
					} else if (aaSpData.includes(')')) {
						aaSpData = aaSpData.split(')')[0];
					}
					dataSP = aaSpData;
				} else if (qGet.urlParam('cm_sp')) {
					dataSP = qGet.urlParam('cm_sp');
				}
			} else if (component.last().data('component-type') === 'MASTHEAD_POP') {
				if (el.closest('.myAcctMobile').length > 0) {
					if (el.closest('.myAccountDropDown').length < 1) {
						dataSP = 'MH-_-ACCOUNT_MENU-_-OPEN';
					} else {
						linkTxt = Helper.getLinkText(el);
						dataSP = 'MH-_-ACCOUNT_MENU-_-' + linkTxt;
					}
				} else if (el.closest('.shopByMobile').length > 0) {
					if (el.closest('.shopQVCTV').length > 0) {
						if (el.closest('.shopTVDropDown').length < 1) {
							dataSP = 'MH-_-SHOP_QVC_LIVE-_-OPEN';
						} else {
							linkTxt = Helper.getLinkText(el);
							dataSP = 'MH-_-SHOP_QVC_LIVE-_-' + linkTxt;
						}
					} else if (el.closest('.mobileShopByCat').length > 0) {
						if (el.closest('.flyoutMenuLink').length > 0) {
							linkTxt = Helper.getLinkText(el);
							dataSP = 'MH-_-MENU-_-' + linkTxt;
						} else {
							linkTxt = Helper.getLinkText(el);
							dataSP = 'MH-_-' + linkTxt + '-_-' + linkTxt;
						}
					} else {
						linkTxt = Helper.getLinkText(el);
						dataSP = 'MH-_-' + linkTxt + '-_-HIDDEN';
					}
				} else if (el.closest('.shopByMobileSub').length > 0) {
					shopCat = el.closest('.flyoutSection').find('.mobFlyoutCat').text();
					shopCat = qString.condenseForTag(shopCat);
					if (el.closest('.dropdown').length < 1) {
						dataSP = 'MH-_-' + shopCat + '-_-' + shopCat;
					} else {
						linkTxt = el.text();
						linkTxt = qString.condenseForTag(linkTxt);
						dataSP = 'MH-_-' + shopCat + '-_-' + linkTxt;
					}
				}
			} else if (component.last().data('component-type') === 'MASTHEAD_FEATURED_CHANNELS') {
				if ($('html').hasClass('mh-mobile-display') && !$('.ftdChannelsCont').hasClass('ftdChannelsOverlay') && !el.closest('.ftdChannelsItem').hasClass('current')) {
					return;
				}
				if (el.hasClass('browseBtn')) {
					dataSP = 'MH-_-CHANNELS-_-BROWSE_ALL';
				} else {
					let caption = el.find('.caption .head'),
						channelIdx = el.closest('.ftdChannelsItem').data('list-index'),
						channelPos = (parseInt(channelIdx) + 1),
						persChannel = el.hasClass('personalizedChannel');
					if (persChannel) {
						let persChannelText = Helper.getLinkText(caption);
						linkTxt = persChannelText === 'MY_CHANNEL' ? 'JUST_FOR_YOU' : 'PERSONAL_CHANNEL';
					} else {
						linkTxt = Helper.getLinkText(caption);
					}
					dataSP = 'CHANNEL_SWITCHER-_-' + linkTxt + '-_-' + channelPos;
				}
			}
			el.attr('href',urlManager.addParam(el.attr('href'),'qq','mh'));
			if (typeof linkTxt !== 'undefined') {
				_this.myAccountSignInConversion(linkTxt, true);
			}
		} else if (module.data('module-type') === 'FOOTER_TOP_MODULE' || module.data('module-type') === 'FOOTER_MID_MODULE' || module.data('module-type') === 'FOOTER_BTM_MODULE') {
			if (component.last().data('component-type') === 'FOOTER_EMAIL_SIGN_UP') {
				if (el.prop('tagName').toLowerCase() === 'form') {
					tag = 'FOOTER-_-EMAIL-_-SIGN_UP';
				} else {
					tag = 'FOOTER-_-EMAIL-_-' + qString.condenseForTag(el.text());
				}
				dataSP = tag;
			} else if (component.last().data('component-type') === 'FOOTER_BTN_LINK' || component.last().data('component-type') === 'FOOTER_BUTTON_LINK') {
				tag = 'FOOTER-_-' + component.last().data('feature-name') + '-_-' + qString.condenseForTag(el.text());
				dataSP = tag;
			} else if (component.last().data('component-type') === 'QVC_INTERNATIONAL') {
				linkTxt = qString.condenseForTag(el.text());
				if (linkTxt.length < 1) {
					linkTxt = 'LOGO';
				}
				tag = 'FOOTER-_-QVC_INTL-_-QVC:' + linkTxt;
				dataSP = tag;
			} else if (component.last().data('component-type') === 'SECURITY_BADGES' || component.last().data('component-type') === 'SOCIAL_ICONS' || component.last().data('component-type') === 'APP_BUTTONS' || component.last().data('component-type') === 'MINI_BANNER_IMAGE') {
				tag = 'FOOTER-_-' + component.last().data('feature-name') + '-_-' + qString.condenseForTag(el.children().attr('alt'));
				dataSP = tag;
			} else if (component.last().data('component-type') === 'LINK_LIST') {
				tag = 'FOOTER-_-' + component.last().data('feature-name') + '-_-' + qString.condenseForTag(el.text());
				dataSP = tag;
			} else if (component.last().data('component-type') === 'TRADEMARK_NOTICE') {
				tag = 'FOOTER-_-' + component.last().data('feature-name') + '-_-' + qString.condenseForTag(el.text());
				dataSP = tag;
			}
			el.attr('href',urlManager.addParam(el.attr('href'),'qq','ft'));
		} else if (module.data('module-type') === 'BOTTOM_DRAWER') {
			dataSP = 'BOTTOMDRAWER-_-' + component.data('feature-name') + '-_-' + position;
		} else if (el.closest('#pageFooter').length > 0 && typeof module.data('module-type') !== 'undefined' && (module.data('module-type') === 'LARGE_STATIC_IMAGE' || module.data('module-type').indexOf('FLEX_') === 0)) {
			linkTxt = el.find('img').attr('alt');
			if (typeof linkTxt === 'undefined') {
				linkTxt = el.text();
			}
			linkTxt = qString.condenseForTag(linkTxt);
			dataSP = 'FOOTER:ADDITIONAL_CONTENT-_-' + module.data('module-feature-name') + '-_-' + linkTxt;
			el.attr('href',urlManager.addParam(el.attr('href'),'qq','ft'));
		} else if (module.data('module-type') === 'EMAIL_SUBSCRIBE' && el.prop('tagName').toLowerCase() === 'form') {
			dataSP = module.data('module-type') + ':' + module.data('module-feature-name') + '-_-EMAIL-_-SIGNUP';
		} else if (module.data('module-type') === 'IROA_PRODUCT_LISTING') {
			if (component.data('component-type') === 'IROA_PRODUCT_LISTING_NAVIGATION') {
				if (el.parents('.iroaShows').find('li').hasClass('selected')) {
					dataSP = 'TOOLS-_-SHOW_SELECTOR-_-' + el.data('source');
				}
			}
		} else if (module.data('module-type') === 'SHOP_LIVE' && component.last().data('component-type') === 'SHOP_LIVE_TV_IROA' && isItemLink){
			dataSP = module.data('module-type') + ':' + module.data('module-feature-name') + '-_-' + component.last().data('component-type') + ':' + position + '-_-' + (elTag.data('feature-show-code') ? elTag.data('feature-show-code') : 'LIVE-STREAM') + ':' + (elTag.data('feature-name') ? elTag.data('feature-name') : Helper.getChannelSc(shopLiveTV.player.curChannel));
		} else if (module.data('module-type') === 'PRODUCT_CAROUSEL' && module.find('.productCarousel.ioCarousel').length > 0) {
			let recoHead = module.find('[data-component-type="HEADER_TEXT"] h2, [data-component-type="HEADER_TEXT"] h1').text(),
				recoName = qString.condenseForTag(recoHead);
			if (component.last().data('component-type') === 'PRODUCT_CAROUSEL') {
				let prodId = el.data('prod-id');
				dataSP = 'RECOMMENDATIONS-_-' + recoName + '-_-' + prodId;
			} else if (component.data('component-type') === 'BUTTON') {
				let btnName = el.text();
				if (el.hasClass('galleryButton')) {
					// If this is a gallery button, strip the "(x)" number of items to be revealed portion of the text
					btnName = btnName.replace(/\(.*\)/g,'');
				}
				btnName = qString.condenseForTag(btnName);
				dataSP = 'RECOMMENDATIONS-_-' + recoName + '-_-' + btnName;
			}
		} else if (module.data('module-type') === 'VIDEO_CLIP') {
			let requestType = module.find('.moduleData').data('requestType'),
				buttonText = qString.condenseForTag(el.text()),
				featureName = qString.condenseForTag(module.data('module-feature-name') ? module.data('module-feature-name') : 'FEATURENAME');
			if (requestType === 'videoClip' || requestType === 'videoClipText') {
				let dataKey = module.find('.video-js').attr('id'),
					vid = module.find('.videoPlayer').data('vid'),
					data = ssVideoPlayer.getVideoData(vid, dataKey);
				vidTitle = qString.condense((data.title ? data.title : module.data('module-type')), 40, 'upper');
				dataSP = module.data('module-type') + ':' + featureName + '-_-' + vidTitle + ':' + 'BUTTON' + '-_-' + buttonText;
			} else if (requestType === 'videoGallery') {
				dataSP = module.data('module-type') + ':' + featureName + '-_-' + module.data('module-type') + ':' + 'BUTTON' + '-_-' + buttonText;
			}
		} else if (module.data('module-type') === 'PRODUCT_CAROUSEL' && module.find('.wishlistCarousel, .savedItemsCarousel').length > 0) {
			let isWishlist = (module.find('.wishlistCarousel').length > 0),
				isSavedItems = (module.find('.savedItemsCarousel').length > 0),
				recoHead = module.find('[data-component-type="HEADER_TEXT"] h2, [data-component-type="HEADER_TEXT"] h1').text(),
				recoName = qString.condenseForTag(recoHead),
				isHeader = (component.data('component-type') === 'HEADER_TEXT'),
				isButton = (component.data('component-type') === 'BUTTON');
			if (isHeader) {
				dataSP = 'USER_HISTORY-_-' + recoName + '-_-MODULE_HEADER';
			} else if (isButton) {
				let buttonText = el.text();
				if (el.hasClass('galleryButton')) {
					// If this is a gallery button, strip the "(x)" number of items to be revealed portion of the text
					buttonText = buttonText.replace(/\(.*\)/g,'');
				}
				buttonText = qString.condenseForTag(buttonText);
				if (isWishlist) {
					dataSP = 'WISHLIST-_-' + recoName + '-_-' + buttonText;
				}
				if (isSavedItems) {
					dataSP = 'SAVED_ITEMS-_-' + recoName + '-_-' + buttonText;
				}
			} else {
				prodNum = Helper.getProdNum(href);
				if (isWishlist) {
					dataSP = 'USER_HISTORY-_-' + recoName + '-_-' + prodNum;
				} else if (isSavedItems) {
					dataSP = 'USER_HISTORY-_-' + recoName + '-_-' + prodNum;
				}
				if (component.data('shopping-category') !== undefined && component.data('shopping-category').length > 0) {
					hasCustomShopCat = true;
				}
			}
			isItemLink = false;
		} else if (module.data('module-type') === 'PRODUCT_CAROUSEL' && module.find('.productCarouselGallery').length > 0 && el.hasClass('galleryButton')) {
			let recoHead = module.find('[data-component-type="HEADER_TEXT"] h2, [data-component-type="HEADER_TEXT"] h1').text(),
				recoName = qString.condenseForTag(recoHead),
				buttonText = qString.condenseForTag(el.text().replace(/\(.*\)/g,''));
			if (module.find('.ioCarousel').length) {
				dataSP = 'RECOMMENDATIONS-_-' + recoName + '-_-' + buttonText;
			}
			else {
				dataSP = 'CAROUSEL-_-' + recoName + '-_-' + buttonText;
			}
		} else if (isCore) {
			let moduleFeature = module.data('module-feature-name'),
				compTag = component.last().data('component-tag-name'),
				compType,
				compCont,
				compSource = 'CONTENT',
				compStyle = '',
				compFeature = typeof elTag.data('feature-name') !== 'undefined' ? elTag.data('feature-name') : compTag,
				compDesc = compFeature;
			switch (compTag) {
			case 'CORE_TITLE':
				compType = 'HEADER';
				compDesc = qString.condenseForTag(el.text());
				break;
			case 'CORE_STYLED_BUTTON':
			case 'CORE_BUTTON':
				compType = 'BUTTON';
				compDesc = qString.condenseForTag(el.text());
				break;
			case 'CORE_CONTAINER_STRIP':
			case 'CORE_PRODUCT_STRIP': {
				compCont = component.last().find('.containerStrip');
				compStyle = compCont.hasClass('carousel') ? 'CAROUSEL:' : 'STACKED:';
				let indexList = component.first().siblings().addBack().not('script');
				position = indexList.index(component.first()) + 1;
				component = component.last();
				if (el.closest('.carArrow').length > 0) {
					compType = 'SCROLL_BUTTON';
					if (el.closest('.carArrow.left').length > 0) {
						position = 'LEFT';
					} else {
						position = 'RIGHT';
					}
				} else if (compTag === 'CORE_PRODUCT_STRIP') {
					compType = 'PRODUCT';
					compSource = el.closest('[data-product-type]').data('product-type');
					compDesc = el.data('prod-id');
				} else {
					compType = 'IMAGE';
					let txt = el.closest('[data-component-tag-name="CORE_TEASER"]').find('.cmp-teaser__title').text();
					compDesc = txt !== '' ? qString.condenseForTag(txt) : compDesc;
				}
			}
				break;
			default:
				compType = 'IMAGE';
			}
			dataSP = moduleFeature + ':' + compSource + '-_-' + compStyle + compType + ':' + position + '-_-' + compDesc;
		} else {
			if (module.data('module-type') && component.last().data('component-type')) {
				dataSP = module.data('module-type') + ':' + module.data('module-feature-name') + '-_-' + component.last().data('component-type') + ':' + position + '-_-' + elTag.data('feature-show-code') + ':' + elTag.data('feature-name');
			}
		}
		if (el.hasClass('qBtn') && component.data('component-type') === undefined && position < 1 && !isCore) {
			dataSP = module.data('module-type') + ':' + module.data('module-feature-name') + '-_-' + 'BUTTON_COMPONENT' + ':' + '1' + '-_-' + elTag.data('feature-show-code') + ':' + elTag.data('feature-name');
		} else if (el.prop('tagName').toLowerCase() === 'li' && component.last().data('component-type') === 'ON_AIR_HEADER') {
			ch = Helper.getChannelSc(el.data('channel-code'));
			dataSP = module.data('module-type') + ':' + module.data('module-feature-name') + '-_-' + component.last().data('component-type') + ':' + '2' + '-_-' + 'CHANNEL_SELECTOR' + ':' + ch;
		} else if (el.parent('.togglePreview').length >= 1 && component.last().data('component-type') === 'SEO') {
			dataSP = module.data('module-type') + ':' + module.data('module-feature-name') + '-_-' + component.last().data('component-type') + ':' + qString.condenseForTag(el.text()) + '-_-' + elTag.data('feature-show-code') + ':' + elTag.data('feature-name');
		}
		if (isItemLink) {
			prodNum = href.match(new RegExp(/[/.]product\.(.*)\.html/));
			if (prodNum === null) {
				prodNum = [0,qGet.queryParam(href,'partNumber')];
			}
			if (prodNum !== null && prodNum.length > 1 && !isCore) {
				prodNum = prodNum[1];
				if (component.last().data('component-type') !== 'PRODUCT_LISTING_LIST' && component.last().find('.productCarousel.ioCarousel').length < 1) {
					dataSP += ':' + prodNum;
				}
			}
			if (module.data('module-type') === 'SHOP_LIVE') {
				if (el.parents('#qProdOverlay').length > 0) {
					dataSP = 'VIDEO-_-PRODUCTLINK-_-' + prodNum;
				}
			}
		}
		if (typeof dataSP !== 'undefined' && dataSP !== '' && dataSP.includes('-_-') && dataSP.split('-_-').length > 2) {
			_this.captureSitePromoData(ele, {
				linkType: ele.localName,
				linkCategory: dataSP.split('-_-')[0],
				linkSection: dataSP.split('-_-')[1],
				linkName: dataSP.split('-_-')[2]
			});
		}
	},
	captureSitePromoData: function (element, sitePromoData) {
		return new Promise((resolve) => {
			element = element instanceof Document ? element.activeElement : $(element);
			const modules = SitePromoFactory.buildModuleRelatedData(element),
				utils = SitePromoFactory.buildUtilsRelatedData(element),
				pageInfo = SitePromoFactory.buildPageInfoRelatedData(element),
				aaSp = SitePromoFactory.buildAaspRelatedData(sitePromoData),
				links = AaSitePromoHelper.objAttributesToLowercase(sitePromoData);
			let event = {
				event: 'sitePromo',
				aaSp: aaSp,
				links: links,
				modules: AaSitePromoHelper.objAttributesToLowercase(modules),
				utils: utils,
				pageInfo: pageInfo
			};
			if (_this.count > 0) {
				/* when two sitePromos fire at same time, AA may treat them as same tag causing you to get two clicks for same sp tag value
					ex: clicking product swatch on video pdp will trigger a swatch color click and a more views image #1 click at same time:
					{ event: "sitePromo", aaSp: "PRODUCTDETAIL-_-MOREVIEWS-_-102:1" }
					{ event: "sitePromo", aaSp: "PRODUCTDETAIL-_-COLOR-_-Gold Blossoms" }
				*/
				setTimeout(() => {
					window.adobeDataLayer.push(event);
					_this.count = 0; //reset so that next click has no delay
					resolve(event);
				}, 500);
				_this.count++;
			} else {
				//first site promo fired. no need to delay.
				window.adobeDataLayer.push(event);
				_this.count++;
				resolve(event);
			}
		});
	},
	getSitePromoData: function (ele) {
		if (ele && $(ele).closest('[manual_cm_sp]').length && $(ele).closest('[manual_cm_sp]').attr('manual_cm_sp')) {
			return $(ele).closest('[manual_cm_sp]').attr('manual_cm_sp');
		}
		if (ele && $(ele).closest('[onclick]').length && $(ele).closest('[onclick]').attr('onclick') && $(ele).closest('[onclick]').attr('onclick').includes('cm_sp=')) {
			let aaSpData = $(ele).closest('[onclick]').attr('onclick').split('cm_sp=')[1];
			if (aaSpData.includes('&')) {
				aaSpData = aaSpData.split('&')[0];
			} else if (aaSpData.includes('\')')) {
				aaSpData = aaSpData.split('\')')[0];
			}
			return aaSpData;
		}
		return qGet.urlParam('cm_sp') || '';
	}
};
module.exports = _this;
