const HelperTag = require('../aa-helper');
const _this = {
	setSearchCompletePageAttr: function () {
		//Captures the kind of Search functionality being used.
		adobeDataLayerObject.search.category = 'internal search';
		//Captures the type of Search done by the customer.
		let searchWord = '';
		if (typeof aem_utag_data.search_term !== 'undefined' && aem_utag_data.search_term.length) {
			searchWord = aem_utag_data.search_term;
		}
		if (typeof aem_utag_data.onsite_search_word !== 'undefined' && aem_utag_data.onsite_search_word.length) {
			searchWord = aem_utag_data.onsite_search_word;
		}
		if (searchWord) {
			if (typeof aem_utag_data.searchType !== 'undefined' && aem_utag_data.searchType.length) {//constructor search
				adobeDataLayerObject.search.term = searchWord.toLowerCase();
				adobeDataLayerObject.search.type = aem_utag_data.searchType.toLowerCase();
			} else if (searchWord.includes(' to ') && searchWord.split(' to ').length
				&& searchWord.split(' to ')[1]) { //legacy endeca search. can be retired later.
				adobeDataLayerObject.search.term = searchWord.split(' to ')[1].toLowerCase();
				if (searchWord.includes('AUTOFIX')) {
					adobeDataLayerObject.search.type = 'auto fix';
				}
			} else {
				adobeDataLayerObject.search.term = searchWord.toLowerCase();
				adobeDataLayerObject.search.type = 'normal';
			}
			//Captures the total number of search results
			adobeDataLayerObject.search.resultCount = aem_utag_data.search_results;
			//Captures the Search term entered by the user.
			adobeDataLayerObject.search.originalTerm = aem_utag_data.search_term.toLowerCase();
			//TODO search Type for recent searches and predictive
			if (aem_utag_data.search_results === '0') {
				adobeDataLayerObject.search.type = 'zero search';
			}
		}
	},
	getfilterAppliedClickData: function (ele) {
		let $el = $(ele),
			$elParent = $el.parents(),
			productRefinement = '',
			refinetxt = $(ele).parents('.refinementImg').find('.refinementTxt').text(),
			multiHead = $(ele).closest('.facetedMulti').find('h3').text();
		if (multiHead && refinetxt) {
			productRefinement = multiHead + ':' + refinetxt;
		} else if ($elParent.is('.facetedSingle') && refinetxt) {
			productRefinement = 'CATEGORY:' + refinetxt;
		} else if ($el.is('.expanded')) {
			productRefinement = multiHead + ':colapsed';
		}
		_this.captureSearchData({
			event: 'filterApplied',
			links: {
				linkType: 'select',
				linkCategory: adobeDataLayerObject.pageInfo.siteSection,
				linkSection: ele.closest('[data-component-type]').getAttribute('data-component-type') || '',
				linkName: productRefinement.toLowerCase().replace(/\\n/g, '').trim()
			}
		});
	},
	getSearchSubmitData: function (searchData) {
		searchData.event = 'searchInitiates';
		searchData.search.category = 'internal search';
		searchData.search.originalTerm = _this.getSearchText();
		searchData.search.type = 'normal';
		searchData.search.resultClicked = _this.getSearchText();
		if (searchData.search.originalTerm.length) {
			_this.captureSearchData(searchData);
			sessionStorage.setItem('AAsearchData', JSON.stringify(searchData.search));
			sessionStorage.setItem('AAfinding', JSON.stringify(searchData.search));
		}
	},
	getPGSearchSubmitData: function (oData) {
		let category = 'program guide',
			originalTerm = _this.getPGSearchText(oData),
			term = _this.getPGSearchText(),
			type = 'normal',
			resultCount = '',
			event = 'searchInitiates';
		if (typeof oData === 'object' && oData.searchTerm && oData.searchTerm.length) {
			event = 'searchComplete';
			term = _this.getPGSearchText();
			let count =  oData.results && $(oData.results).length ? $(oData.results).find('li').length : 0;
			if (count > 0) {
				resultCount = count;
			} else {
				type = 'zero search';
				resultCount = '0';
			}
		}
		if (originalTerm.length) {
			_this.captureSearchData({
				event,
				search: {
					category,
					originalTerm,
					type,
					term,
					resultCount
				}
			});
		}
	},
	getOSSearchSubmitData: function (searchData) {
		searchData.search.category = 'order status';
		searchData.event = 'searchInitiates';
		searchData.search.originalTerm = _this.getOSSearchText();
		if (searchData.search.originalTerm.length) {
			_this.captureSearchData(searchData);
		}
	},
	getSearchResultClickData: function (ele) {
		_this.captureSearchData({
			event: 'searchResultClick',
			search: {
				category: 'internal search',
				originalTerm: adobeDataLayerObject.search.originalTerm,
				type: adobeDataLayerObject.search.type,
				term: adobeDataLayerObject.search.term,
				resultClicked: _this.getResultClicked(ele),
				resultCount: adobeDataLayerObject.search.resultCount,
				index: $(ele).closest('.galleryItem').index().toString()
			}
		});
	},
	getGuidedSearchData: function (ele, type) {
		let $elParent = $(ele).parents();

		sessionStorage.removeItem('AAsearchData');
		sessionStorage.removeItem('AAfinding');
		sessionStorage.removeItem('typedKeyword');

		let guidedSearchData = {
			event: 'guidedSearchClick',
			search: {
				category: 'internal search',
				originalTerm: _this.getSearchText(),
				type: `${type} search`,
				resultClicked: _this.getResultClicked(ele),
				resultCount: ''
			}
		};

		const updateGuidedSearchData = (update) => {
			$.extend(true, guidedSearchData, update);
		};

		const popularSearchMap = {
			popularSearchTsv: 'Today\'s Special Value',
			popularSearchIoa: 'Item on Air',
			popularSearchLts: 'Lunchtime Specials'
		};

		if ($elParent.is('#predictiveSearch')) {
			if ($elParent.is('.pSearch-featuredProducts')) {
				updateGuidedSearchData({
					search: {
						type: 'featured products',
						term: $('a[data-cnstrc-item-id]').data('cnstrc-item-id') || '',
						resultCount: 1
					},
					links: {
						linkName: 'featured products'
					}
				});
			} else if ($elParent.is('[class*="pSearch-"][class*=" refinement-constructor"]')) {
				updateGuidedSearchData({
					links: {
						linkName: 'suggested search',
						linkType: 'suggested search'
					}
				});
			}
		} else if ($elParent.is('#popularSearches')) {
			let term = popularSearchMap[ele.id];
			if (term) {
				updateGuidedSearchData({
					search: {
						type: 'popular',
						originalTerm: term,
						term: term,
						resultClicked: term
					},
					links: {
						linkName: term
					}
				});
			}
		} else if ($elParent.is('#recentSearches')) {
			updateGuidedSearchData({
				search: {
					type: 'recent search',
					originalTerm: $(ele).text(),
					term: $(ele).text()
				}
			});
		}

		sessionStorage.setItem('AAsearchData', JSON.stringify(guidedSearchData.search));
		sessionStorage.setItem('AAfinding', JSON.stringify(guidedSearchData.search));
		sessionStorage.setItem('typedKeyword', _this.getSearchText());
		_this.captureSearchData(guidedSearchData);
	},
	getResultClicked: function (ele) {
		let $el = $(ele), $elParent = $el.parents(), resultClicked = '';
		if ($elParent.is('[id=predictiveSearch]')) {
			if ($elParent.is('.pSearch-featuredProducts')) {
				resultClicked = 'featured product: ';
			} else if ($elParent.is('.pSearch-brands')) {
				resultClicked = 'brands: ';
			} else if ($elParent.is('.pSearch-categories')) {
				resultClicked = 'categories: ';
			}
			if ($el.is('a')) {
				resultClicked += ele.innerText;
			} else if ($elParent.is('a')) {
				resultClicked += $el.parents('a').text();
			}
		} else if ($elParent.is('[id=recentSearches]')) {
			resultClicked= 'recent search: ';
			if ($el.is('a')) {
				resultClicked += ele.innerText;
			} else if ($elParent.is('a')) {
				resultClicked += $el.parents('a').text();
			}
		}

		if ($elParent.is('.galleryItem')) {
			resultClicked = $el.parents('.galleryItem').find('.productDesc').text();
		} else if (HelperTag.additionalCostsEnabled()) {
			resultClicked = $el.text();
		}
		return resultClicked.toLowerCase();
	},
	getSearchText: function () {
		let searchFormTxt = $('form.searchForm [name="keyword"]').val(),
			mastHeadTxt = $('#txtMastheadSearch').val(),
			searchTerm = '',
			srchTxt = '';

		if (typeof aem_utag_data.search_term !== 'undefined' && aem_utag_data.search_term.length) {
			searchTerm = aem_utag_data.search_term.toLowerCase();
		}
		if (typeof searchFormTxt !== 'undefined' && searchFormTxt.length) {
			srchTxt = searchFormTxt;
		} else if (typeof mastHeadTxt !== 'undefined' && mastHeadTxt.length) {
			srchTxt = mastHeadTxt;
		} else if (typeof searchTerm !== 'undefined' && searchTerm.length) {
			srchTxt = searchTerm;
		}
		return (typeof srchTxt !== 'undefined' && srchTxt.length ? srchTxt.toLowerCase() : '');
	},
	getPGSearchText: function (oData) {
		let srchTxt = $('.programGuideSearchForm .form-group input').val();
		if (typeof oData === 'object' && oData.searchTerm && oData.searchTerm.length) {
			srchTxt = oData.searchTerm;
		}
		return (typeof srchTxt !== 'undefined' && srchTxt.length ? srchTxt.toLowerCase() : '');
	},
	getOSSearchText: function () {
		let srchTxt = $('form.orderStatusSearch [name="searchKeywords"]').val();
		if (typeof srchTxt !== 'undefined' && !srchTxt.length) {
			srchTxt = $('#osSearchTxt-small').val();
		}
		return (typeof srchTxt !== 'undefined' && srchTxt.length ? srchTxt.toLowerCase() : '');
	},
	getPageSearchData: function (srchTxt) {
		adobeDataLayerObject.search.category = 'internal search';
		if (!(typeof adobeDataLayerObject.search.type !== 'undefined' && adobeDataLayerObject.search.type.length)) {
			adobeDataLayerObject.search.type = 'normal';
		}
		if (!(typeof adobeDataLayerObject.search.term !== 'undefined' && adobeDataLayerObject.search.term.length)) {
			adobeDataLayerObject.search.term = srchTxt.toLowerCase();
		}

		if (adobeDataLayerObject.search.type === 'suggested search') {
			adobeDataLayerObject.search.originalTerm = sessionStorage.getItem('typedKeyword');
		} else {
			adobeDataLayerObject.search.originalTerm = srchTxt.toLowerCase();
		}
	},
	captureSearchData: function (seachData) {
		window.adobeDataLayer.push(seachData);
	},
	addSearchTagListener: function () {
		let searchData = {
			event: '',
			search: {
				category: '',
				originalTerm: '',
				type: '',
				term: '',
				resultClicked: '',
				resultCount: '',
				index: ''
			}
		};
		$(document).on('submit', 'form.searchForm', function () {
			_this.getSearchSubmitData(searchData);
		});
		$(document).on('keypress', '.programGuideSearchForm .form-group input', function(e) {
			if (e.which === 13){
				_this.getPGSearchSubmitData();
			}
		});
		$(document).on('click', '.programGuideSearchForm .form-group button', function() {
			_this.getPGSearchSubmitData();
		});
		$(document).off('tvpg-search-complete.aa').on('tvpg-search-complete.aa', function(e, oData) {
			_this.getPGSearchSubmitData(oData);
		});
		$(document).on('click', '.osSearchBtn', function() {
			_this.getOSSearchSubmitData(searchData);
		});
		let srchTxtAvail = _this.getSearchText(),
			aaSearchData = sessionStorage.getItem('AAsearchData');
		if (aaSearchData && aaSearchData.length) {
			let searchStorageData = JSON.parse(aaSearchData);
			adobeDataLayerObject.search = typeof searchStorageData !== 'undefined' ? searchStorageData : {};
			if (!(typeof adobeDataLayerObject.search.type !== 'undefined' && adobeDataLayerObject.search.type.length)) {
				adobeDataLayerObject.search.type = 'normal';
			}
			if (typeof aem_utag_data.search_results !== 'undefined' && aem_utag_data.search_results === '0') {
				adobeDataLayerObject.search.type = 'zero search';
			}
			if (adobeDataLayerObject.search.type === 'popular') {
				if (adobeDataLayerObject.search.term === 'Today\'s Special Value') {
					adobeDataLayerObject.search.resultCount = 1;
				} else {
					adobeDataLayerObject.search.resultCount = aem_utag_data.search_results || '0';
				}
			}
			sessionStorage.removeItem('AAsearchData');
		}
		if (typeof aem_utag_data.onsite_search_word !== 'undefined' && aem_utag_data.onsite_search_word.length) {
			_this.setSearchCompletePageAttr();
		}
		if (typeof aem_utag_data.search_term !== 'undefined' && aem_utag_data.search_term.length) {
			_this.setSearchCompletePageAttr();
		}
		if (typeof srchTxtAvail !== 'undefined' && srchTxtAvail.length) {
			_this.getPageSearchData(srchTxtAvail);
		}
		if (adobeDataLayerObject.search.originalTerm && adobeDataLayerObject.search.originalTerm.length) {
			sessionStorage.setItem('AAfinding', JSON.stringify(adobeDataLayerObject.search));
		}
	},
};

module.exports = _this;
