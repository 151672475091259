const GlobalPage = require('../aa-global-page');
const HelperTag = require('../aa-helper');
const _this = {
	getLoginData: function (reason) {
		let registrationReason = '';
		switch (reason) {
		case 'createAccount':
			registrationReason = 'NEW ACCOUNT SETUP';
			break;
		case 'profileUpdate':
			registrationReason = 'ACCOUNT PROFILE UPDATE';
			break;
		case 'myAccLogin':
			registrationReason = 'ACCOUNT LOGIN';
			break;
		case 'orderConfirm':
			registrationReason = 'ORDER COMPLETION';
			break;
		case 'linkDevice':
			registrationReason = 'LINK DEVICE';
			break;
		case 'previousAuth':
			//Note: we still don't have a viable solution for determining when a Acoustic session starts inorder to fire this.
			registrationReason = 'PREVIOUS AUTHORIZATION';
			break;
		}
		let custId = qCookie.get('qvcmn') || '', // check if member number changed
			regAttr = qCookie.get('uattr') || '',
			customerTypeCode = '',
			multiBuyStrataCode = '',
			firstOrderDate = '',
			lastOrderDate = '',
			merchandisingPreferenceCode = '',
			cardIndicatorCode = '';
		if (regAttr && regAttr.includes('|')) {
			regAttr = regAttr.toUpperCase().split('|');
			customerTypeCode = regAttr.length > 0 ? regAttr[0] : '';
			multiBuyStrataCode = regAttr.length > 1 ? regAttr[1] : '';
			firstOrderDate = regAttr.length > 3 ? regAttr[3] : '';
			lastOrderDate = regAttr.length > 4 ? regAttr[4] : '';
			merchandisingPreferenceCode = regAttr.length > 5 ? regAttr[5] : '';
			cardIndicatorCode = regAttr.length > 6 ? regAttr[6] : '';
		}
		firstOrderDate = firstOrderDate && firstOrderDate !== '0'? firstOrderDate.slice(4, 6) + '/' + firstOrderDate.slice(6, 8) + '/' + firstOrderDate.slice(0, 4) : '';
		lastOrderDate = lastOrderDate && lastOrderDate !== '0' ?  lastOrderDate.slice(4, 6) + '/' + lastOrderDate.slice(6, 8) + '/' + lastOrderDate.slice(0, 4) : '';
		if (typeof custId !== 'undefined') {
			GlobalPage.captureEventData({
				event: registrationReason === 'NEW ACCOUNT SETUP' ? 'signupComplete' : 'loginComplete',
				user: {
					registrationReason,
					memberNumber: custId,
					authStatus: HelperTag.getUserAuthStatus(),
					accountType: customerTypeCode ? customerTypeCode : aem_utag_data.newCustomer ? 'NV' : 'EC',
					multiBuyStrata: multiBuyStrataCode,
					firstOrder: firstOrderDate,
					lastOrder: lastOrderDate,
					merchandisePreference: merchandisingPreferenceCode,
					cardIndicator: cardIndicatorCode
				}
			});
		}
	},
	addListeners: function () {
		$(document).on('click', '.overlayEditBillingAddress .btnSave', function() {
			$(document).trigger('triggerRegTag', {reason: 'profileUpdate'});
		});
		$(document).on('login.success', function () {
			/*User Successfully logged in(via overlay, checkout login, or my account login), fire registration tag*/
			let reasonCode = aem_utag_data.category_id === 'OTTSIGN' ? 'linkDevice' : 'myAccLogin';
			$(document).trigger('triggerRegTag', { reason: reasonCode });
		});
		$(document).on('triggerRegTag', function (e, params) {
			if (typeof params === 'object' && params.reason) {
				_this.getLoginData(params.reason);
			}
		});
		$(document).on('fireRegTag', function (e, params) {
			if (typeof params === 'object' && params.reason) {
				switch (params.reason) {
				case 'profileUpdate':
					if (aem_utag_data.page_type === 'myaccount-account-settings' && params.reason === 'profileUpdate') {
						GlobalPage.captureEventData({event:	'editAccountSuccess'});
					}
					break;
				case 'linkDevice':
				case 'myAccLogin':
				case 'orderConfirm':
					break;
				default:
					_this.getLoginData(params.reason);
					break;
				}
			}
		});
		$(document).on('click', '.pgSignIn .signInCont .btnContinue, .pgYourInfo .signInArea .btnContinue, form.loginOverlay.signInArea .btnContinue', function(event){
			let $el = $(event.target),
				pwdAttempt = ($('input[type="password"][name="pw"]').filter(':visible').length > 0),
				eventData = pwdAttempt || ($el.closest('form.loginOverlay.signInArea').length > 0) ? 'loginAttempt' : 'linkClick',
				linkCategory = $el.closest('.pgYourInfo').length ? 'create account' : 'login attempt';
			GlobalPage.captureEventData({
				event: eventData,
				links: {
					linkCategory,
					linkSection: aem_utag_data.page_type || '',
					linkName: pwdAttempt ? 'login attempt' : 'continue',
					linkType: 'button'
				},
			});
		});
	},
};

module.exports = _this;